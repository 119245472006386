import toast from "react-hot-toast";
import apiConfig from "../../config/apiConfig";
import { authActionType, commonURL, localStorageConstant } from "../../constants";

export const loginAction = (loginCredentials, navigate, setLoading) => async dispatch => {
  await apiConfig
    .post("login", loginCredentials)
    .then(response => {
      localStorage.setItem(localStorageConstant.TOKEN, response.data.data.token.token);
      if (response.data.data.user.user_type === "1") {
        toast.success("Logged in successfully");
        localStorage.setItem("isAdmin", true);
        navigate("/admin/dashboard");
      } else if (response.data.data.user.user_type === "2") {
        localStorage.setItem("isAdmin", false);
        toast.success("Logged in successfully");
        navigate("/partner/dashboard");
      } else if (response.data.data.user.user_type === "3") {
        localStorage.setItem("isAdmin", false);
        toast.success("Logged in successfully");
        navigate("/vendor/dashboard");
      } else {
        setLoading(false);
        toast.error("You are not allowed to login.");
      }
      dispatch({
        type: authActionType.LOGIN_USER,
        payload: response.data.data.user,
      });
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const logoutAction = navigate => async dispatch => {
  await apiConfig
    .post("logout")
    .then(response => {
      localStorage.clear();
      navigate("/login");
      dispatch({ type: authActionType.RESET_STATE });
      toast.success("Logged out successfully");
    })
    .catch(({ response }) => {
      toast.error(response);
    });
};

export const changePassword = (payload, handleClose, setLoading) => async () => {
  await apiConfig
    .post(commonURL.CHANGE_PASSWORD, payload)
    .then(response => {
      toast.success("Password updated successfully");
      handleClose();
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const forgotPassword = (payload, navigate, setLoading) => async () => {
  await apiConfig
    .post(commonURL.FORGOT_PASSWORD, payload)
    .then(response => {
      toast.success(response.data.message);
      navigate("/login");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const resetPassword = (payload, navigate, setLoading) => async () => {
  await apiConfig
    .post(commonURL.RESET_PASSWORD, payload)
    .then(response => {
      toast.success(response.data.message);
      navigate("/login");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};
