import { adminDashboardAction } from "../../../constants";

const initVal = {
  tableData: [],
  statisticsData: [],
  isTableDataLoaded: false,
};

const dashboardReducer = (state = initVal, action) => {
  switch (action.type) {
    case adminDashboardAction.GET_DASHBOARD_WIDGET_DATA:
      return { ...state, tableData: action.payload, isTableDataLoaded: true };
    case adminDashboardAction.GET_DASHBOARD_STATISTICS_DATA:
      return { ...state, statisticsData: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
