import React, { useState, useEffect } from "react";
import { useSortable, SortableContext } from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { arrayMove } from "@dnd-kit/sortable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AddPartnerModal from "./AddPartnerModal";
import Trash from "../../assets/images/trash-icon.svg";
import { editPriority } from "../../store/action/admin/partnerAction";
import dragIcon from "../../assets/images/dragIcon.svg";

const SortableItem = ({ children, id, value, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: "4px",
    width: "100%",
  };

  return (
    <div className="d-flex">
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div className="kanban-item">
          {children}
          <div className="list-item flex-1 d-flex gap-3">
            <span>
              <img src={dragIcon} alt="dragIcon" />
            </span>
            <span className="kanban-item-name">{value}</span>
          </div>
        </div>
      </div>
      <button className="kanban-item-remove" onClick={onRemove}>
        <img src={Trash} alt="trash" className="pe-none" />
      </button>
    </div>
  );
};

const PriorityListing = ({ vendors, dataType, id, priorities }) => {
  let filteredDataType = dataType.filter(item => item === "1" || item === "2" || item === "3");
  filteredDataType = filteredDataType.sort();
  if (dataType.includes("4")) {
    if (!filteredDataType.includes("1")) {
      filteredDataType.push("1");
    }
    if (!filteredDataType.includes("2")) {
      filteredDataType.push("2");
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [liveItems, setLiveItems] = useState([]);
  const [cachedItems, setCachedItems] = useState([]);
  const [liteItems, setLiteItems] = useState([]);

  const [addVendorModalType, setAddVendorModalType] = useState(null);
  const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);

  useEffect(() => {
    if (priorities && vendors) {
      const updatedLiveItems = priorities
        .find(item => item.data_type === "1")
        ?.priority.map(item => {
          const vendor = vendors.find(vendor => vendor.id === item.vendor_id);
          return {
            id: item.vendor_id,
            label: vendor ? vendor.full_name : "",
          };
        });
      const updatedCachedItems = priorities
        .find(item => item.data_type === "2")
        ?.priority.map(item => {
          const vendor = vendors.find(vendor => vendor.id === item.vendor_id);
          return {
            id: item.vendor_id,
            label: vendor ? vendor.full_name : "",
          };
        });

      const updatedLiteItems = priorities
        .find(item => item.data_type === "3")
        ?.priority.map(item => {
          const vendor = vendors.find(vendor => vendor.id === item.vendor_id);
          return {
            id: item.vendor_id,
            label: vendor ? vendor.full_name : "",
          };
        });

      setLiveItems(updatedLiveItems || []);
      setCachedItems(updatedCachedItems || []);
      setLiteItems(updatedLiteItems || []);
    }
  }, [vendors, priorities]);

  const handleDragEnd = (event, listType) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      if (listType === "live") {
        setLiveItems(items => {
          const oldIndex = items.findIndex(item => item.id === active?.id);
          const newIndex = items.findIndex(item => item.id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      } else if (listType === "cached") {
        setCachedItems(items => {
          const oldIndex = items.findIndex(item => item.id === active?.id);
          const newIndex = items.findIndex(item => item.id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      } else if (listType === "lite") {
        setLiteItems(items => {
          const oldIndex = items.findIndex(item => item.id === active?.id);
          const newIndex = items.findIndex(item => item.id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  };

  const handleAddVendor = type => {
    setAddVendorModalType(type);
    setAddVendorModalOpen(true);
  };

  const handleSaveVendor = selectedVendors => {
    const vendors = selectedVendors.map(vendor => ({
      id: vendor.value,
      label: vendor.label,
    }));

    if (addVendorModalType === "live") {
      setLiveItems(prevItems => [
        ...prevItems,
        ...vendors.filter(vendor => !prevItems.some(item => item.id === vendor.id)),
      ]);
    } else if (addVendorModalType === "cached") {
      setCachedItems(prevItems => [
        ...prevItems,
        ...vendors.filter(vendor => !prevItems.some(item => item.id === vendor.id)),
      ]);
    } else if (addVendorModalType === "lite") {
      setLiteItems(prevItems => [
        ...prevItems,
        ...vendors.filter(vendor => !prevItems.some(item => item.id === vendor.id)),
      ]);
    }

    setAddVendorModalOpen(false);
  };

  const handleRemoveVendor = (index, type) => {
    if (type === "live") {
      setLiveItems(items => items.filter((_, i) => i !== index));
    } else if (type === "cached") {
      setCachedItems(items => items.filter((_, i) => i !== index));
    } else if (type === "lite") {
      setLiteItems(items => items.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = () => {
    const priorityData = [];

    if (liveItems.length > 0) {
      const livePriority = liveItems.map((item, index) => ({
        vendor_id: item.id,
        priority: index + 1,
      }));

      priorityData.push({
        data_type: "1",
        priority: livePriority,
      });
    }

    if (cachedItems.length > 0) {
      const cachedPriority = cachedItems.map((item, index) => ({
        vendor_id: item.id,
        priority: index + 1,
      }));

      priorityData.push({
        data_type: "2",
        priority: cachedPriority,
      });
    }

    if (liteItems.length > 0) {
      const litePriority = liteItems.map((item, index) => ({
        vendor_id: item.id,
        priority: index + 1,
      }));

      priorityData.push({
        data_type: "4",
        priority: litePriority,
      });
    }

    dispatch(editPriority(id, { data: priorityData }, navigate));
  };

  return (
    <>
      <div className="row pt-2">
        {filteredDataType.map((type, idx) => (
          <div
            key={idx}
            className={`${filteredDataType?.length === 2 ? "col-xl-6 col-md-6" : "col-xl-4 col-md-4"} mb-30px`}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="fw-semibold mb-0">
                {type === "1" ? "Live" : type === "2" ? "Cached" : "Lite"}
              </h6>
              <button
                className="btn btn-primary-light add-list-btn"
                onClick={() =>
                  handleAddVendor(type === "1" ? "live" : type === "2" ? "cached" : "lite")
                }
              >
                + Add
              </button>
            </div>
            <DndContext
              onDragEnd={event =>
                handleDragEnd(event, type === "1" ? "live" : type === "2" ? "cached" : "lite")
              }
            >
              <SortableContext
                items={type === "1" ? liveItems : type === "2" ? cachedItems : liteItems}
              >
                {(type === "1" ? liveItems : type === "2" ? cachedItems : liteItems).map(
                  (item, index) => (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      value={item.label}
                      onRemove={() =>
                        handleRemoveVendor(
                          index,
                          type === "1" ? "live" : type === "2" ? "cached" : "lite",
                        )
                      }
                      children={
                        <span className="kanban-item-index fw-bold">{parseInt(index, 10) + 1}</span>
                      }
                    />
                  ),
                )}
              </SortableContext>
            </DndContext>
            {((type === "1" && liveItems.length === 0) ||
              (type === "2" && cachedItems.length === 0) ||
              (type === "3" && liteItems.length === 0)) && (
              <div className="text-center">No vendor selected</div>
            )}
          </div>
        ))}
        <div className="col-12">
          <div className="d-flex gap-2 pt-2">
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
            <button onClick={() => navigate("/admin/partners")} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
      {addVendorModalOpen && (
        <AddPartnerModal
          isOpen={addVendorModalOpen}
          onCancel={() => setAddVendorModalOpen(false)}
          onSave={handleSaveVendor}
          options={
            addVendorModalType === "live"
              ? vendors.filter(
                  vendor =>
                    !liveItems.find(item => item.id === vendor.id) &&
                    ((vendor.accepted_data_type && Number(vendor.accepted_data_type) === 1) ||
                      Number(vendor.accepted_data_type) === 3),
                )
              : addVendorModalType === "cached"
                ? vendors.filter(
                    vendor =>
                      !cachedItems.find(item => item.id === vendor.id) &&
                      ((vendor.accepted_data_type && Number(vendor.accepted_data_type) === 2) ||
                        Number(vendor.accepted_data_type) === 3),
                  )
                : vendors.filter(
                    vendor =>
                      !liteItems.find(item => item.id === vendor.id) &&
                      vendor.accepted_data_type?.split(",")?.includes("4"),
                  )
          }
        />
      )}
    </>
  );
};

export default PriorityListing;
