import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = ({ data, labels, id }) => {
  const chartData = {
    labels: labels,
    datasets: data?.map(dataset => ({
      ...dataset,
      borderRadius: 5,
    })),
  };

  const options = {
    interaction: {
      mode: "index",
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        fullSize: true,
        labels: {
          boxWidth: 12,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
          footer: function (tooltipItems) {
            let total = 0;
            tooltipItems.forEach(function (tooltipItem) {
              total += tooltipItem.raw;
            });
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    barThickness: 25,
  };

  const plugins = [
    {
      id: "totalValuePlugin",
      afterDatasetsDraw: chart => {
        const ctx = chart.ctx;
        ctx.save();

        const totals = chart.data.labels?.map((label, labelIndex) => {
          return chart.data.datasets.reduce((sum, dataset, datasetIndex) => {
            if (chart.isDatasetVisible(datasetIndex)) {
              return sum + (dataset.data[labelIndex] || 0);
            }
            return sum;
          }, 0);
        });

        chart.data.labels.forEach((label, labelIndex) => {
          const total = totals[labelIndex];

          let xPosition;
          let yPosition = Infinity;

          chart.data.datasets.forEach((dataset, datasetIndex) => {
            if (chart.isDatasetVisible(datasetIndex)) {
              const meta = chart.getDatasetMeta(datasetIndex);
              const bar = meta.data[labelIndex];
              if (bar) {
                const barPosition = bar.tooltipPosition();
                xPosition = barPosition.x;
                if (barPosition.y < yPosition) {
                  yPosition = barPosition.y;
                }
              }
            }
          });

          yPosition -= 10; // Adjust this value as needed to position the total above the bar

          ctx.fillStyle = "#000";
          ctx.textAlign = "center";
          ctx.fillText(total, xPosition, yPosition);
        });

        ctx.restore();
      },
    },
  ];

  return (
    <Bar style={{ maxHeight: "330px" }} data={chartData} options={options} plugins={plugins} />
  );
};

export default StackedBarChart;
