import apiConfig from "../../../config/apiConfig";
import { adminActionType, commonURL } from "../../../constants";
import requestParam from "../../../components/shared/requestParam";
import { setTotalRecord } from "../totalRecordAction";
import _ from "lodash";
import toast from "react-hot-toast";

export const fetchInvoices =
  (filter = {}, userType) =>
  async dispatch => {
    let url = `${userType}${commonURL.INVOICE}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_INVOICES,
          payload: response.data.data,
        });
        dispatch(
          setTotalRecord(
            response.data.total !== undefined && response.data.total >= 0
              ? response.data.total
              : response.data.total,
          ),
        );
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const getInvoice = (userType, public_id) => async dispatch => {
  apiConfig
    .get(`${userType}${commonURL.INVOICE}/${public_id}`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_INVOICE,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const updateInvoice =
  (userType, public_id, payload, setLoading, navigate) => async dispatch => {
    apiConfig
      .post(`${userType}${commonURL.INVOICE}/${public_id}`, payload)
      .then(response => {
        dispatch({
          type: adminActionType.EDIT_INVOICE,
          payload: response.data.data,
        });
        setLoading(false);
        toast.success("Invoice updated successfully");
        navigate("/admin/invoices");
      })
      .catch(({ response }) => {
        setLoading(false);
        toast.error(response.data.message);
      });
  };

export const generateInvoice =
  (item, setLoading, isViewPDF = false) =>
  async dispatch => {
    try {
      setLoading(true);
      let url = `/generate-invoice/${item.public_id}`;

      const response = await apiConfig.get(url, { responseType: "blob" });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "invoice.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      if (isViewPDF) {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, "_blank");
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute("target", "_blank");
        downloadLink.setAttribute("rel", "noopener noreferrer");
        downloadLink.setAttribute("download", fileName);

        downloadLink.click();
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to generate invoice.");
      setLoading(false);
    }
  };
