import React, { useEffect } from "react";
import SimpleModal from "../../components/modals/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIHistory } from "../../store/action/admin/partnerAction";
import { timeAgo } from "../../components/shared/sharedMethod";

const ApiHistoryModal = ({ id, show, handleClose }) => {
  const dispatch = useDispatch();
  const apiHistory = useSelector(state => state?.partners?.apiHistory);

  useEffect(() => {
    dispatch(fetchAPIHistory(id));
  }, [id, dispatch]);

  return (
    <SimpleModal
      title={"API History"}
      show={show}
      handleClose={handleClose}
      isCancel={false}
      isSubmit={false}
      isFooter={false}
    >
      <div>
        <div className="billing-modal">
          <table className="table w-100 mb-0">
            <thead>
              <tr>
                <th className="ps-0 fs-14px text-gray-600 fw-medium">API Key</th>
                <th className="fs-14px text-gray-600 fw-medium">Created At</th>
                <th className="fs-14px text-gray-600 fw-medium">Created By</th>
              </tr>
            </thead>
            <tbody className="text-dark fw-medium">
              {apiHistory?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>{item.api_key}</div>
                    <div
                      className={item.is_active ? "text-success" : "text-danger"}
                      style={{ fontSize: "12px" }}
                    >
                      {item.is_active ? "Active" : "Inactive"}
                    </div>
                  </td>
                  <td>
                    {timeAgo(item.createdAt).formattedDate}
                    {timeAgo(item.createdAt).time}
                  </td>
                  <td>{item.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </SimpleModal>
  );
};

export default ApiHistoryModal;
