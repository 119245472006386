import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import TabTitle from "../shared/tabTitle/TabTitle";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/action/authAction";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../common/Footer";

const ResetPassword = () => {
  const { token } = useParams();
  const [changePassword, setChangePassword] = useState(true);
  const [changeConfirmPassword, setChangeConfirmPassword] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setChangePassword(!changePassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setChangeConfirmPassword(!changeConfirmPassword);
  };

  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    password_confirmation: "",
    token,
  });

  const [errors, setErrors] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = e => {
    setPasswordInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (passwordInputs["password"].trim() === "") {
      errorss["password"] = "Please enter new password";
    } else if (!passwordInputs["password_confirmation"].trim() === "") {
      errorss["password_confirmation"] = "Please enter confirm password";
    } else if (passwordInputs["password_confirmation"] !== passwordInputs["password"]) {
      errorss["password_confirmation"] = "Password and confirm password should be same";
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const onSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      dispatch(resetPassword(passwordInputs, navigate, setLoading));
    }
  };

  return (
    <>
      <TabTitle title={"Reset Password"} />
      <div className="resetPassword-page h-100 d-flex flex-column justify-content-center">
        <div className="container">
          <div className="company-logo text-center mb-80px">
            <img src={logo} alt="Logo" className="h-100" />
          </div>
          <div className="login-form bg-white  mx-auto">
            <div className="text-center mb-60px">
              <h3 className="fw-bold mb-1">Reset Password</h3>
              <p className="text-gray-600 fs-16px">Enter your new password</p>
            </div>
            <form>
              <div>
                <div className="mb-3">
                  <label className="form-label fw-medium mb-1 fs-16px">New Password:</label>
                  <span className="text-danger fs-16px">*</span>
                  <div className="mb-2 position-relative">
                    <input
                      type={changePassword ? "password" : "text"}
                      name="password"
                      className="form-control fw-medium fs-16px"
                      onChange={e => handleInputChange(e)}
                      autoComplete="off"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                    >
                      {changePassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <span className="text-danger d-block fw-400 fs-small mt-2">
                    {errors["password"] ? errors["password"] : null}
                  </span>
                </div>
                <div className="mb-3">
                  <label className="form-label fw-medium mb-1 fs-16px">Confirm Password:</label>
                  <span className="text-danger fs-16px">*</span>
                  <div className="mb-2 position-relative">
                    <input
                      type={changeConfirmPassword ? "password" : "text"}
                      name="password_confirmation"
                      className="form-control fw-medium fs-16px"
                      onChange={e => handleInputChange(e)}
                      autoComplete="off"
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                    >
                      {changeConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <span className="text-danger d-block fw-400 fs-small mt-2">
                    {errors["password_confirmation"] ? errors["password_confirmation"] : null}
                  </span>
                </div>
                <button type="submit" onClick={e => onSubmit(e)} className="btn btn-primary w-100">
                  {loading ? "Loading..." : "Save"}
                </button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
