import apiConfig from "../../../config/apiConfig";
import { adminBaseURL, adminActionType } from "../../../constants";
import requestParam from "../../../components/shared/requestParam";
import { setTotalRecord } from "../totalRecordAction";
import _ from "lodash";
import toast from "react-hot-toast";

export const fetchVendors =
  (filter = {}) =>
  async dispatch => {
    let url = adminBaseURL.VENDORS;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_VENDORS,
          payload: response.data.data,
        });
        dispatch(
          setTotalRecord(
            response.data.total !== undefined && response.data.total >= 0
              ? response.data.total
              : response.data.total,
          ),
        );
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchVendor = id => dispatch => {
  apiConfig
    .get(`${adminBaseURL.VENDORS}/${id}`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_VENDOR,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const createVendor = (vendor, navigate, setLoading) => async dispatch => {
  await apiConfig
    .post(`${adminBaseURL.VENDORS}`, vendor)
    .then(response => {
      dispatch({
        type: adminActionType.ADD_VENDOR,
        payload: response.data,
      });
      toast.success("Vendor created successfully");
      navigate("/admin/vendors");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const editVendor = (id, vendor, navigate, setLoading) => async dispatch => {
  await apiConfig
    .post(`${adminBaseURL.VENDORS}/${id}`, vendor)
    .then(response => {
      dispatch({
        type: adminActionType.EDIT_VENDOR,
        payload: response.data,
      });
      toast.success("Vendor updated successfully");
      dispatch(fetchVendors());
      navigate("/admin/vendors");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const fetchVendorDashboardWidget =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.VENDORS}/statatics/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_VENDOR_DASHBOARD_WIDGET,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchVendorDashboardSource =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.VENDORS}/source/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_VENDOR_DASHBOARD_SOURCE,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchVendorDashboardChart =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.VENDORS}/number-of-calls/chart/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_VENDOR_DASHBOARD_CHART,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchAllVendorListing = () => dispatch => {
  apiConfig
    .get(`${adminBaseURL.VENDOR}/listing`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_VENDOR_LISTING,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const fetchAllPartnerVendor =
  (id, isPartner = false) =>
  dispatch => {
    let url = `${adminBaseURL.VENDOR_PARTNER}/${id}`;

    if (isPartner) {
      url += "?isPartner=true";
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_VENDOR_PARTNER,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };
