import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "./shared/DatePicker/DatePicker";
import { statusOptions, dataTypeOptions, customStyles } from "../constants";
import DatePickerLatest from "./shared/DatePicker/DateRangePicker";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartnersListing,
  fetchVendorsListing,
} from "../store/action/admin/filterListingAction";

const PageHeader = ({
  title,
  link,
  linkName,
  isLink = false,
  isSelect,
  isDatePicker,
  backLink,
  isSelectionPart = false,
  isBackButton,
  isBackButtonLast = false,
  onDateSelect,
  onStatusSelect,
  isDataTypeSelect,
  onDataTypeSelect,
  options,
  isInvoice,
  dataTypeCustomOptions,
  isVendorFilter = false,
  isPartnerFilter = false,
  partnerOptions,
  vendorOptions,
  onPartnerSelect,
  onVendorSelect,
  handleBack,
  paginationLimit = 50,
  onLimitChange,
  isTopPagination = true,
  isTitleSelectable = false,
  handleTitleChange,
  isNavigationButton = false,
  navigationHandle,
  navigationButtonText,
  selectedPartnerOption,
  isPartnerOptions = false,
  selectedDates,
  isDashboard,
}) => {
  const selectRef = useRef(null);

  const { vendors, partners } = useSelector(state => state.listing);

  const vendorFilterOptions = isDashboard
    ? vendors.map(vendor => {
        return { value: vendor.public_id, label: vendor.user_name };
      })
    : [
        { value: "all", label: "All Vendor" },
        ...vendors.map(vendor => {
          return { value: vendor.public_id, label: vendor.user_name };
        }),
      ];

  const partnerFilterOptions = isDashboard
    ? partners.map(partner => {
        return { value: partner.public_id, label: partner.user_name };
      })
    : [
        { value: "all", label: "All Partner" },
        ...partners.map(partner => {
          return { value: partner.public_id, label: partner.user_name };
        }),
      ];
  let defaultOptions = isPartnerOptions ? partnerFilterOptions : vendorFilterOptions;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPartnerOptions) {
      dispatch(fetchPartnersListing());
      dispatch(fetchVendorsListing());
    }
  }, [isPartnerOptions]);

  const handleTitleChangeInternal = selectedOption => {
    handleTitleChange(selectedOption);
  };

  return (
    <>
      <div className="section-heading d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
        {isTitleSelectable ? (
          <div ref={selectRef}>
            <Select
              options={defaultOptions}
              onChange={handleTitleChangeInternal}
              styles={customStyles}
              value={selectedPartnerOption || { label: title, value: title }}
              className="custom-select-menu cursor-pointer"
            />
          </div>
        ) : (
          <h2 className="mb-0 fw-semibold">{title}</h2>
        )}
        {isLink && (
          <Link className="btn btn-primary px-4" to={link}>
            {linkName}
          </Link>
        )}
        {isBackButton && !handleBack && (
          <Link className="btn btn-primary-light px-4" to={backLink}>
            Back
          </Link>
        )}
        {isBackButton && handleBack && (
          <Button className="btn btn-primary-light px-4" onClick={handleBack}>
            Back
          </Button>
        )}
        {isSelectionPart && (
          <div className="d-flex flex-md-row flex-column justify-content-end flex-wrap ms-auto gap-2">
            <div className="d-flex flex-wrap ms-auto gap-2 justify-content-end">
              {isPartnerFilter && (
                <Select
                  options={partnerOptions}
                  onChange={onPartnerSelect}
                  styles={customStyles}
                  defaultValue={partnerOptions[0]}
                  className="custom-select-menu cursor-pointer"
                  // isClearable
                />
              )}
              {isVendorFilter && (
                <Select
                  options={vendorOptions}
                  onChange={onVendorSelect}
                  styles={customStyles}
                  defaultValue={vendorOptions[0]}
                  className="custom-select-menu cursor-pointer"
                  // isClearable
                />
              )}
              {isDataTypeSelect && (
                <Select
                  options={dataTypeCustomOptions ? dataTypeCustomOptions : dataTypeOptions}
                  onChange={onDataTypeSelect}
                  styles={customStyles}
                  placeholder="Select Data Type"
                  defaultValue={dataTypeOptions[0]}
                  className="custom-select-menu cursor-pointer"
                />
              )}
              {isSelect && (
                <Select
                  options={options ? options : statusOptions}
                  onChange={onStatusSelect}
                  styles={customStyles}
                  placeholder="Select Status"
                  defaultValue={statusOptions[0]}
                  className="custom-select-menu cursor-pointer"
                />
              )}
              {isDatePicker && isInvoice && <DatePicker onDateSelect={onDateSelect} />}
              {isDatePicker && !isInvoice && (
                <DatePickerLatest onDateSelect={onDateSelect} propsSelectedDates={selectedDates} />
              )}
            </div>
            {isTopPagination && (
              <div className="d-flex flex-wrap ms-auto gap-2 justify-content-end">
                <Select
                  options={[
                    { value: 50, label: "50" },
                    { value: 100, label: "100" },
                    { value: 200, label: "200" },
                    { value: 500, label: "500" },
                    { value: 1000, label: "1000" },
                  ]}
                  onChange={onLimitChange}
                  styles={customStyles}
                  isSearchable={false}
                  value={{
                    value: paginationLimit,
                    label: `Limit, ${paginationLimit}`,
                  }}
                  className="custom-select-menu cursor-pointer"
                />
              </div>
            )}
            <div className="text-end">
              {isNavigationButton && (
                <Button
                  className="btn action-header-icon"
                  style={{ background: "white" }}
                  onClick={navigationHandle}
                >
                  {navigationButtonText}
                </Button>
              )}
              {isBackButtonLast && (
                <Link className="btn btn-primary-light ms-2 px-4" to={backLink}>
                  Back
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageHeader;
