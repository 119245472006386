import React from "react";

import "../../assets/css/partners.css";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import VendorForm from "./VendorForm";
const CreateVendor = () => {
  return (
    <>
      <TabTitle title={"Create Vendor"} />
      <VendorForm />
    </>
  );
};
export default CreateVendor;
