import { partnerDashboardAction } from "../../../constants";

const initVal = {
  tableData: [],
  statisticsData: [],
  chartData: [],
  billing: [],
};

const dashboardReducer = (state = initVal, action) => {
  switch (action.type) {
    case partnerDashboardAction.GET_DASHBOARD_WIDGET_DATA:
      return { ...state, tableData: action.payload };
    case partnerDashboardAction.GET_DASHBOARD_STATISTICS_DATA:
      return { ...state, statisticsData: action.payload };
    case partnerDashboardAction.GET_DASHBOARD_CHART_DATA:
      return { ...state, chartData: action.payload };
    case partnerDashboardAction.GET_BILLING_DATA:
      return { ...state, billing: action.payload };
    case partnerDashboardAction.GET_WARNING:
      return { ...state, warning: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
