import { vendorDashboardAction } from "../../../constants";

const initVal = {
  tableData: [],
  statisticsData: [],
  chartData: [],
};

const dashboardReducer = (state = initVal, action) => {
  switch (action.type) {
    case vendorDashboardAction.GET_DASHBOARD_WIDGET_DATA:
      return { ...state, tableData: action.payload };
    case vendorDashboardAction.GET_DASHBOARD_STATISTICS_DATA:
      return { ...state, statisticsData: action.payload };
    case vendorDashboardAction.GET_DASHBOARD_CHART_DATA:
      return { ...state, chartData: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
