import toast from "react-hot-toast";
import apiConfig from "../../../config/apiConfig";
import { partnerAPIURL, partnerAction } from "../../../constants";

export const fetchAPIKey = () => dispatch => {
  apiConfig
    .get(`${partnerAPIURL.API_KEY}`)
    .then(response => {
      dispatch({
        type: partnerAction.FETCH_API_KEY,
        payload: response.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
