import { adminActionType } from "../../../constants";

const partnerReducer = (state = [], action) => {
  switch (action.type) {
    case adminActionType.FETCH_PARTNERS:
      return action.payload;
    case adminActionType.FETCH_PARTNER:
      return action.payload;
    case adminActionType.FETCH_PRIORITIES:
      return { ...state, priorities: action.payload };
    case adminActionType.FETCH_SINGLE_PARTNER_LOGS:
      return { ...state, singlePartnerLogs: action.payload };
    case adminActionType.ADD_PARTNER:
      return [...state, action.payload];
    case adminActionType.EDIT_PARTNER:
      return action.payload;
    case adminActionType.REGENERATE_API:
      return;
    case adminActionType.FETCH_PARTNER_DASHBOARD_WIDGET:
      return { ...state, partnerDashboardWidget: action.payload };
    case adminActionType.FETCH_PARTNER_DASHBOARD_SOURCE:
      return { ...state, partnerDashboardSource: action.payload };
    case adminActionType.FETCH_PARTNER_DASHBOARD_CHART:
      return { ...state, partnerDashboardChart: action.payload };
    case adminActionType.FETCH_API_HISTORY:
      return { ...state, apiHistory: action.payload };
    default:
      return state;
  }
};

export default partnerReducer;
