import { adminDashboardChartAction } from "../../../constants";

const initVal = {
  chartData: [],
  revenueChartData: [],
};

const dashboardReducer = (state = initVal, action) => {
  switch (action.type) {
    case adminDashboardChartAction.GET_DASHBOARD_CHART_DATA:
      return { ...state, chartData: action.payload };
    case adminDashboardChartAction.GET_DASHBOARD_REVENUE_CHART_DATA:
      return { ...state, revenueChartData: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
