import React from "react";
import { Link } from "react-router-dom";

const Statistics = props => {
  const {
    name,
    columns,
    statisticsData,
    statisticsFor,
    isPercentage,
    isStatisticsForPartner = false,
    style,
    customClass,
    navigationPath,
    isLink = false,
    isVendorLoggedIn = false,
    isPartnerLoggedIn = false,
  } = props;

  return (
    <div className="partners-statistics-section mb-4 pb-1">
      <div className="bg-white wrapper">
        <div className="table-heading">
          <h5 className="fs-18px fw-semibold mb-0">{name}</h5>
        </div>
        <div className="overflow-auto" style={style}>
          <table
            className={`table statistics-table table-striped table-borderless whitespace-nowrap mb-0 ${customClass}`}
          >
            <thead>
              <tr className="text-end">
                {columns.map((column, index) => {
                  return (
                    <th
                      key={index}
                      className={
                        (statisticsFor === "other" && index === 0) ||
                        index === 0 ||
                        column === "Date"
                          ? "text-start"
                          : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {statisticsFor === "partner" &&
                statisticsData?.listing &&
                statisticsData?.listing.map((statistics, index) => {
                  return (
                    <tr className="text-end" key={index}>
                      <td className="text-primary text-start">
                        <Link
                          onClick={() => {
                            localStorage.removeItem("partnerName");
                            localStorage.removeItem("partnerId");
                            localStorage.setItem("partnerName", statistics?.name);
                            localStorage.setItem("partnerId", statistics?.public_id);
                          }}
                          to={`/admin/partners/${statistics?.public_id}/dashboard`}
                        >
                          {statistics?.name}
                        </Link>
                      </td>
                      <td>{statistics?.total_calls || 0}</td>
                      <td className="text-success">
                        {statistics?.successful || 0}
                        {isPercentage
                          ? `(${statistics?.successPercentage != null ? statistics?.successPercentage : 0}%)`
                          : ""}
                      </td>
                      {/* <td className="text-warning">{statistics?.partial}</td> */}
                      <td className="text-danger">
                        {statistics?.failed || 0}
                        {isPercentage
                          ? `(${statistics?.failedPercentage != null ? statistics?.failedPercentage : 0}%)`
                          : ""}
                      </td>
                      <td>
                        {statistics?.live || 0}
                        {isPercentage ? `(${statistics?.livePercentage ?? 0}%)` : ""}
                      </td>
                      <td>
                        {statistics?.cached || 0}
                        {isPercentage
                          ? `(${statistics?.cachedPercentage != null ? statistics?.cachedPercentage : 0}%)`
                          : "0%"}
                      </td>
                      <td>
                        {statistics?.lite || 0}
                        {isPercentage ? `(${statistics?.litePercentage ?? 0}%)` : "0%"}
                      </td>
                      <td>₹{statistics?.cost?.toFixed(2) || "0.00"}</td>
                      <td>₹{statistics?.revenue?.toFixed(2) || "0.00"}</td>
                      <td className={statistics?.net_revenue < 0 ? "text-danger" : "text-success"}>
                        ₹{statistics?.net_revenue?.toFixed(2) || "0.00"}
                      </td>
                    </tr>
                  );
                })}

              {statisticsFor === "vendor" &&
                statisticsData?.listing &&
                statisticsData?.listing.map((statistics, index) => {
                  return (
                    <tr className="text-end" key={index}>
                      <td className="text-primary text-start">
                        <Link
                          onClick={() => {
                            localStorage.removeItem("vendorName");
                            localStorage.removeItem("vendorId");
                            localStorage.setItem("vendorName", statistics?.name);
                            localStorage.setItem("vendorId", statistics?.public_id);
                          }}
                          to={`/admin/vendors/${statistics?.public_id}/dashboard`}
                        >
                          {statistics?.name}
                        </Link>
                      </td>
                      <td>{statistics?.total_calls || 0}</td>
                      <td className="text-danger">
                        {statistics?.failed || 0}
                        {isPercentage ? `(${statistics?.failedPercentage || 0}%)` : ""}
                      </td>
                      <td className="text-success">
                        {statistics?.successful || 0}
                        {isPercentage ? `(${statistics?.successPercentage || 0}%)` : ""}
                      </td>
                      <td className="text-warning">
                        {statistics?.partial || 0}
                        {isPercentage ? `(${statistics?.partialPercentage || 0}%)` : ""}
                      </td>
                      <td className="text-success">
                        {statistics?.correct || 0}
                        {isPercentage ? `(${statistics?.correctPercentage || 0}%)` : ""}
                      </td>
                      <td>
                        {statistics?.live || 0}
                        {isPercentage ? `(${statistics?.livePercentage || 0}%)` : ""}
                      </td>
                      <td>
                        {statistics?.cached || 0}
                        {isPercentage ? `(${statistics?.cachedPercentage || 0}%)` : ""}
                      </td>
                      <td>
                        {statistics?.lite || 0}
                        {isPercentage ? `(${statistics?.litePercentage || 0}%)` : ""}
                      </td>
                      <td>₹{statistics?.cost || 0}</td>
                      <td>₹{statistics?.revenue || 0}</td>
                      <td className={statistics?.net_revenue < 0 ? "text-danger" : "text-success"}>
                        ₹{statistics?.net_revenue || 0}
                      </td>
                    </tr>
                  );
                })}

              {statisticsFor === "other" &&
                statisticsData?.listing &&
                statisticsData?.listing.map((statistics, index) => {
                  return (
                    <tr className="text-end" key={index}>
                      <td className="text-start">{index + 1}</td>
                      <td className="text-start">
                        {isLink && (
                          <Link
                            to={`${navigationPath}?date=${statistics?.date}`}
                            className="text-primary"
                          >
                            {statistics?.date}
                          </Link>
                        )}
                      </td>
                      <td>{statistics?.total_calls || 0}</td>
                      <td className="text-danger">{`${statistics?.failed || 0} (${
                        isPercentage ? (statistics?.failedPercentage ?? 0) + "%" : "0%"
                      })`}</td>
                      <td className="text-success">{`${statistics?.successful || 0} (${
                        isPercentage ? (statistics?.successfullPercentage ?? 0) + "%" : "0%"
                      })`}</td>

                      {isStatisticsForPartner && (
                        <td>{`${statistics?.live || 0} (${
                          isPercentage ? (statistics?.livePercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {isStatisticsForPartner && (
                        <td>{`${statistics?.cached || 0} (${
                          isPercentage ? (statistics?.cachedPercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {isStatisticsForPartner && (
                        <td>{`${statistics?.lite || 0} (${
                          isPercentage ? (statistics?.litePercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {!isStatisticsForPartner && (
                        <td className="text-warning">
                          {statistics?.insufficient != null
                            ? `${statistics?.insufficient || 0} (${
                                isPercentage
                                  ? (statistics?.insufficientPercentage ?? 0) + "%"
                                  : "0%"
                              })`
                            : `${statistics?.partial || 0} (${
                                isPercentage ? (statistics?.partialPercentage ?? 0) + "%" : "0%"
                              })`}
                        </td>
                      )}
                      {!isStatisticsForPartner && (
                        <td className="text-success">{`${statistics?.correct || 0} (${
                          isPercentage ? (statistics?.correctPercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {!isStatisticsForPartner && (
                        <td>{`${statistics?.live || 0} (${
                          isPercentage ? (statistics?.livePercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {!isStatisticsForPartner && (
                        <td>{`${statistics?.cached || 0} (${
                          isPercentage ? (statistics?.cachedPercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {!isStatisticsForPartner && (
                        <td>{`${statistics?.lite || 0} (${
                          isPercentage ? (statistics?.litePercentage ?? 0) + "%" : "0%"
                        })`}</td>
                      )}
                      {!isVendorLoggedIn && !isPartnerLoggedIn && (
                        <td>{`₹${(statistics?.cost || 0)?.toFixed(2)}`}</td>
                      )}
                      {!isVendorLoggedIn && !isPartnerLoggedIn && (
                        <td>{`₹${(statistics?.revenue || 0)?.toFixed(2)}`}</td>
                      )}
                      {!isVendorLoggedIn && !isPartnerLoggedIn && (
                        <td
                          className={statistics?.profit < 0 ? "text-danger" : "text-success"}
                        >{`₹${(statistics?.profit || 0)?.toFixed(2)}`}</td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
            {statisticsFor !== "other" && (
              <tfoot>
                <tr className="text-end fw-semibold">
                  <td className="text-start fw-medium">Total</td>
                  <td>{statisticsData?.totals?.total_calls || 0}</td>
                  {statisticsFor === "vendor" ? (
                    <td className="text-danger">
                      {statisticsData?.totals?.failed || 0}
                      {isPercentage
                        ? `(${statisticsData?.totals?.totalFailedPercentage || 0}%)`
                        : ""}
                    </td>
                  ) : (
                    ""
                  )}
                  <td className="text-success">
                    {statisticsData?.totals?.successful || 0}
                    {isPercentage
                      ? `(${statisticsData?.totals?.totalSuccessPercentage || 0}%)`
                      : ""}
                  </td>
                  {statisticsFor === "partner" ? (
                    <td className="text-danger">
                      {statisticsData?.totals?.failed || 0}
                      {isPercentage
                        ? `(${statisticsData?.totals?.totalFailedPercentage || 0}%)`
                        : ""}
                    </td>
                  ) : (
                    ""
                  )}
                  {statisticsFor === "vendor" ? (
                    <td className="text-warning">
                      {statisticsData?.totals?.partial || 0}
                      {isPercentage
                        ? `(${statisticsData?.totals?.totalPartialPercentage || 0}%)`
                        : ""}
                    </td>
                  ) : (
                    ""
                  )}
                  {statisticsFor === "vendor" ? (
                    <td className="text-success">
                      {statisticsData?.totals?.correct || 0}
                      {isPercentage
                        ? `(${statisticsData?.totals?.totalCorrectPercentage || 0}%)`
                        : ""}
                    </td>
                  ) : (
                    ""
                  )}
                  <td>
                    {statisticsData?.totals?.live || 0}
                    {isPercentage ? `(${statisticsData?.totals?.totalLivePercentage || 0}%)` : ""}
                  </td>
                  <td>
                    {statisticsData?.totals?.cached || 0}
                    {isPercentage ? `(${statisticsData?.totals?.totalCachedPercentage || 0}%)` : ""}
                  </td>
                  <td>
                    {statisticsData?.totals?.lite || 0}
                    {isPercentage ? `(${statisticsData?.totals?.totalLitePercentage || 0}%)` : ""}
                  </td>
                  <td>{"₹" + (statisticsData?.totals?.cost?.toFixed(2) || 0)}</td>
                  <td>₹{statisticsData?.totals?.revenue?.toFixed(2) || 0}</td>

                  <td
                    className={
                      statisticsData?.totals?.net_revenue < 0 ? "text-danger" : "text-success"
                    }
                  >
                    ₹{statisticsData?.totals?.net_revenue?.toFixed(2) || 0}
                  </td>
                </tr>
              </tfoot>
            )}
            {statisticsFor === "other" && (
              <tfoot>
                <tr className="text-end fw-semibold">
                  <td className="text-start fw-medium">Total</td>
                  <td className="text-start"></td>
                  <td>{statisticsData?.totals?.total_calls || 0}</td>
                  <td className="text-danger">
                    {`${statisticsData?.totals?.failed || 0} (${
                      isPercentage
                        ? (statisticsData?.totals?.totalFailedPercentage || 0) + "%"
                        : "0%"
                    }) `}
                  </td>
                  <td className="text-success">
                    {`${statisticsData?.totals?.successful || 0} (${
                      isPercentage
                        ? (statisticsData?.totals?.totalSuccessfullPercentage || 0) + "%"
                        : "0%"
                    }) `}
                  </td>

                  {isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.live || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalLivePercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.cached || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalCachedPercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.lite || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalLitePercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}

                  {!isStatisticsForPartner && (
                    <td className="text-warning">
                      {Number(statisticsData?.totals?.insufficient) ||
                      Number(statisticsData?.totals?.insufficient) === 0 ||
                      Number(statisticsData?.totals?.insufficient) === 1
                        ? `${statisticsData?.totals?.insufficient} (${
                            isPercentage
                              ? (statisticsData?.totals?.totalInsufficientPercentage || 0) + "%"
                              : "0%"
                          }) `
                        : `${statisticsData?.totals?.partial || 0} (${
                            isPercentage
                              ? (statisticsData?.totals?.totalPartialPercentage || 0) + "%"
                              : "0%"
                          }) `}
                    </td>
                  )}
                  {!isStatisticsForPartner && (
                    <td className="text-success">
                      {`${statisticsData?.totals?.correct || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalCorrectPercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {!isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.live || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalLivePercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {!isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.cached || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalCachedPercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {!isStatisticsForPartner && (
                    <td>
                      {`${statisticsData?.totals?.lite || 0} (${
                        isPercentage
                          ? (statisticsData?.totals?.totalLitePercentage || 0) + "%"
                          : "0%"
                      }) `}
                    </td>
                  )}
                  {!isVendorLoggedIn && !isPartnerLoggedIn && (
                    <td>{`₹${(statisticsData?.totals?.cost || 0)?.toFixed(2)} `}</td>
                  )}
                  {!isVendorLoggedIn && !isPartnerLoggedIn && (
                    <td>{`₹${(statisticsData?.totals?.revenue || 0)?.toFixed(2)}`}</td>
                  )}
                  {!isVendorLoggedIn && !isPartnerLoggedIn && (
                    <td
                      className={
                        statisticsData?.totals?.profit < 0 ? "text-danger" : "text-success"
                      }
                    >
                      {`₹${(statisticsData?.totals?.profit || 0)?.toFixed(2)}`}
                    </td>
                  )}
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
