import React, { useState } from "react";
import Select from "react-select";
import SimpleModal from "../../components/modals/SimpleModal";

const AddPartnerModal = ({ isOpen, onCancel, onSave, options }) => {
  const [selectedVendors, setSelectedVendors] = useState([]);

  const handleVendorChange = selectedOptions => {
    setSelectedVendors(selectedOptions);
  };

  const handleSaveClick = e => {
    e.preventDefault();
    if (selectedVendors.length > 0) {
      onSave(selectedVendors);
      setSelectedVendors([]);
    }
  };

  // Map options to have value and label properties
  const selectOptions = options.map(option => ({
    value: option.id,
    label: option.full_name,
  }));

  return (
    <SimpleModal
      show={isOpen}
      handleClose={onCancel}
      title={"Add Vendor"}
      isSubmit={true}
      handleSubmit={handleSaveClick}
    >
      <Select
        placeholder="Select vendor"
        options={selectOptions}
        className="ms-auto custom-select-menu cursor-pointer"
        onChange={handleVendorChange}
        isMulti
      />
    </SimpleModal>
  );
};

export default AddPartnerModal;
