import { vendorAction } from "../../../constants";

const logsReducer = (state = [], action) => {
  switch (action.type) {
    case vendorAction.FETCH_LOGS:
      return action.payload;
    case vendorAction.FETCH_LOG_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default logsReducer;
