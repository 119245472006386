import { adminActionType } from "../../../constants";

const vendorReducer = (state = [], action) => {
  switch (action.type) {
    case adminActionType.FETCH_VENDORS:
      return action.payload;
    case adminActionType.FETCH_VENDOR_LISTING:
      return { ...state, listing: action.payload };
    case adminActionType.FETCH_VENDOR:
      return action.payload;
    case adminActionType.EDIT_VENDOR:
      return action.payload;
    case adminActionType.FETCH_VENDOR_DASHBOARD_WIDGET:
      return { ...state, vendorDashboardWidget: action.payload };
    case adminActionType.FETCH_VENDOR_DASHBOARD_SOURCE:
      return { ...state, vendorDashboardSource: action.payload };
    case adminActionType.FETCH_VENDOR_DASHBOARD_CHART:
      return { ...state, vendorDashboardChart: action.payload };
    default:
      return state;
  }
};

export default vendorReducer;
