//Admin API URL
export const adminBaseURL = {
  PARTNERS: "admin/partners",
  PARTNER_LOGS: "admin/partner-logs",
  VENDORS: "admin/vendors",
  VENDOR: "admin/vendor",
  UPDATE_PROFILE: "user",
  VENDOR_LOGS: "admin/vendor-logs",
  SETTINGS: "admin/settings",
  DASHBOARD_API: "admin/statatics",
  DASHBOARD_CHART: "admin/number-of-calls/chart",
  DASHBOARD_REVENUE_CHART: "admin/revenue-cost/chart",
  VENDOR_PARTNER: "admin/vendor-partner",
};

export const partnerAPIURL = {
  LOGS_URL: "partner/logs",
  API_KEY: "partner/api-key",
  PARTNER: "partner",
};

export const vendorAPIURL = {
  VENDOR: "vendor",
  LOGS_URL: "vendor/logs",
};

export const commonURL = {
  CHANGE_PASSWORD: "user/change-password",
  FORGOT_PASSWORD: "user/forgot-password",
  RESET_PASSWORD: "user/reset-password",
  INVOICE: "/invoices",
};

export const localStorageConstant = {
  TOKEN: "token",
};

export const authActionType = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_STATE: "RESET_STATE",
};

export const adminActionType = {
  FETCH_PARTNERS: "FETCH_PARTNERS",
  FETCH_PARTNER: "FETCH_PARTNER",
  ADD_PARTNER: "ADD_PARTNER",
  EDIT_PARTNER: "EDIT_PARTNER",
  FETCH_VENDORS: "FETCH_VENDORS",
  FETCH_VENDORS_LISTING: "FETCH_VENDORS_LISTING",
  FETCH_PARTNERS_LISTING: "FETCH_PARTNERS_LISTING",
  FETCH_VENDOR: "FETCH_VENDOR",
  ADD_VENDOR: "ADD_VENDOR",
  EDIT_VENDOR: "EDIT_VENDOR",
  REGENERATE_API: "REGENERATE_API",
  FETCH_PARTNER_LOGS: "FETCH_PARTNER_LOGS",
  FETCH_SINGLE_PARTNER_LOGS: "FETCH_SINGLE_PARTNER_LOGS",
  FETCH_PARTNER_LOGS_DETAILS: "FETCH_PARTNER_LOGS_DETAILS",
  EDIT_PROFILE: "EDIT_PROFILE",
  USER_PROFILE: "USER_PROFILE",
  FETCH_VENDOR_LOGS: "FETCH_VENDOR_LOGS",
  FETCH_SINGLE_VENDOR_LOGS: "FETCH_SINGLE_VENDOR_LOGS",
  FETCH_VENDOR_LOGS_DETAILS: "FETCH_VENDOR_LOGS_DETAILS",
  FETCH_SETTINGS: "FETCH_SETTINGS",
  EDIT_SETTINGS: "EDIT_SETTINGS",
  FETCH_PARTNER_DASHBOARD_WIDGET: "FETCH_PARTNER_DASHBOARD_WIDGET",
  FETCH_PARTNER_DASHBOARD_SOURCE: "FETCH_PARTNER_DASHBOARD_SOURCE",
  FETCH_PARTNER_DASHBOARD_CHART: "FETCH_PARTNER_DASHBOARD_CHART",
  FETCH_VENDOR_DASHBOARD_WIDGET: "FETCH_VENDOR_DASHBOARD_WIDGET",
  FETCH_VENDOR_DASHBOARD_SOURCE: "FETCH_VENDOR_DASHBOARD_SOURCE",
  FETCH_VENDOR_DASHBOARD_CHART: "FETCH_VENDOR_DASHBOARD_CHART",
  FETCH_VENDOR_LISTING: "FETCH_VENDOR_LISTING",
  FETCH_VENDOR_PARTNER: "FETCH_VENDOR_PARTNER",
  FETCH_PRIORITIES: "FETCH_PRIORITIES",
  FETCH_INVOICES: "FETCH_INVOICES",
  FETCH_INVOICE: "FETCH_INVOICE",
  EDIT_INVOICE: "EDIT_INVOICE",
  FETCH_API_HISTORY: "FETCH_API_HISTORY",
};

export const adminDashboardAction = {
  GET_DASHBOARD_WIDGET_DATA: "GET_DASHBOARD_WIDGET_DATA",
  GET_DASHBOARD_STATISTICS_DATA: "GET_DASHBOARD_STATISTICS_DATA",
};

export const adminDashboardChartAction = {
  GET_DASHBOARD_CHART_DATA: "GET_DASHBOARD_CHART_DATA",
  GET_DASHBOARD_REVENUE_CHART_DATA: "GET_DASHBOARD_REVENUE_CHART_DATA",
};

export const Filters = {
  PAGE: 1,
  OBJ: {
    order_By: "",
    page: 1,
    limit: 10,
    direction: "asc",
    search: "",
    created_at: "created_at",
  },
};

export const paginationAction = {
  SET_TOTAL_RECORD: "SET_TOTAL_RECORD",
};

export const statusOptions = [
  { value: "all", label: "All Status" },
  { value: 1, label: "Successful" },
  { value: 2, label: "Insufficient" },
  { value: 3, label: "Failed" },
];

export const statusWithLiteOptions = [
  { value: "all", label: "All Status" },
  { value: 1, label: "Successful" },
  // { value: 2, label: "Lite" },
  { value: 3, label: "Failed" },
];

export const dataTypeOptions = [
  { value: "all", label: "All Request type" },
  { value: 1, label: "Live" },
  { value: 2, label: "Cached" },
  { value: 3, label: "Lite" },
];

export const dataTypeWithMixedOptions = [
  { value: "all", label: "All Request type" },
  { value: 1, label: "Live" },
  { value: 2, label: "Cached" },
  { value: 3, label: "Lite" },
  { value: 4, label: "Mixed" },
];

export const invoicesOptions = [
  { value: "all", label: "All" },
  { value: 1, label: "Partner" },
  { value: 2, label: "Vendor" },
];

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    ":hover": {
      backgroundColor: "#dae2ff",
      color: "#24272c",
    },
    backgroundColor: state.isSelected ? "#dae2ff" : "#ffffff",
    color: state.isSelected ? "#24272C" : "#6C757D",
  }),
};

export const partnerDashboardAction = {
  GET_DASHBOARD_WIDGET_DATA: "GET_DASHBOARD_WIDGET_DATA",
  GET_DASHBOARD_STATISTICS_DATA: "GET_DASHBOARD_STATISTICS_DATA",
  GET_BILLING_DATA: "GET_BILLING_DATA",
  GET_WARNING: "GET_WARNING",
};

export const partnerDashboardChartAction = {
  GET_DASHBOARD_CHART_DATA: "GET_DASHBOARD_CHART_DATA",
};

export const vendorDashboardAction = {
  GET_DASHBOARD_WIDGET_DATA: "GET_DASHBOARD_WIDGET_DATA",
  GET_DASHBOARD_STATISTICS_DATA: "GET_DASHBOARD_STATISTICS_DATA",
  GET_DASHBOARD_CHART_DATA: "GET_DASHBOARD_CHART_DATA",
};

export const partnerAction = {
  FETCH_LOGS: "FETCH_LOGS",
  FETCH_LOG_DETAILS: "FETCH_LOG_DETAILS",
  FETCH_API_KEY: "FETCH_API_KEY",
};

export const vendorAction = {
  FETCH_LOGS: "FETCH_LOGS",
  FETCH_LOG_DETAILS: "FETCH_LOG_DETAILS",
};

export const vendorService = [
  {
    name: "iv",
    data_types: [1, 2, 3],
  },
  {
    name: "car_info",
    data_types: [1],
  },
  {
    name: "rto",
    data_types: [1, 2, 3, 4],
  },
  {
    name: "upgrowth",
    data_types: [1],
  },
];
