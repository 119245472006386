import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import editIcon from "../../assets/images/edit-icon.svg";
import ListTable from "../../components/tables/ListTable";
import { fetchAllPartnerVendor } from "../../store/action/admin/vendorAction";

const VendorModal = ({ id, show, handleClose, isPartner = false }) => {
  const { partnerListing } = useSelector(state => state);
  const [activeTab, setActiveTab] = useState("live");
  const dispatch = useDispatch();
  const handleChangeTab = tabName => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (id) {
      setActiveTab("live");
      dispatch(fetchAllPartnerVendor(id, isPartner));
    }
  }, [id, show]);

  const livePartner = partnerListing && partnerListing.filter(item => item.data_type === "1");
  const cachedPartner = partnerListing && partnerListing.filter(item => item.data_type === "2");

  const columns = [
    {
      id: 1,
      name: isPartner ? "vendor_name" : "partner_name",
      className: "text-start w-70",
      containerClass: "text-start",
    },
    {
      id: 3,
      name: "actions",
      className: "text-center w-15",
      containerClass: "text-center",
      customData: item => (
        <div className="d-flex gap-2 justify-content-center">
          <Link
            target="_blank"
            to={`/admin/${isPartner ? "vendors" : "partners"}/${isPartner ? item.vendor_id : item.partner_id}/edit?listing=true`}
            className="edit-icon action-icon"
          >
            <img src={editIcon} alt="edit" className="img-fluid" />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isPartner ? "Vendors" : "Partners"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeTab}
            onSelect={key => handleChangeTab(key)}
            id="uncontrolled-tab-example"
            className="mb-1 status-tab"
          >
            <Tab eventKey="live" title="Live">
              <ListTable columns={columns} data={livePartner} />
            </Tab>
            <Tab eventKey="cached" title="Cached">
              <ListTable columns={columns} data={cachedPartner} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VendorModal;
