import toast from "react-hot-toast";
import { vendorAPIURL, vendorDashboardAction } from "../../../constants";
import apiConfig from "../../../config/apiConfig";
import _ from "lodash";
import requestParam from "../../../components/shared/requestParam";

export const getDashboardWidgetDetailsAction = filter => dispatch => {
  let url = `${vendorAPIURL.VENDOR}/statatics`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: vendorDashboardAction.GET_DASHBOARD_WIDGET_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardTableStatisticsAction = filter => dispatch => {
  let url = `${vendorAPIURL.VENDOR}/source`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: vendorDashboardAction.GET_DASHBOARD_STATISTICS_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardChartDataAction = filter => dispatch => {
  let url = `${vendorAPIURL.VENDOR}/number-of-calls/chart`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: vendorDashboardAction.GET_DASHBOARD_CHART_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
