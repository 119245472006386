import React from "react";

import totalCalls from "../../assets/images/dashboard/total-calls.png";
import revenue from "../../assets/images/dashboard/revenue.png";
import { Widget, WidgetVendor } from "../../components/shared/Widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const DashboardWidgets = ({ data, isVendor, isTooltip = true }) => {
  if (!data) return null;

  return (
    <>
      <div className="widget-section">
        <div className="row">
          <div className="col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            {isVendor ? (
              <Widget
                isPercentage
                name={"Total Calls"}
                totalNumber={data?.total_calls || 0}
                image={totalCalls}
                total1Class={"live"}
                total1={"Live"}
                number1={data?.live || 0}
                number1Percentage={data?.livePercentage || 0}
                total2Class={"partial"}
                total2={"Cached"}
                number2={data?.cached || 0}
                number2Percentage={data?.cachedPercentage || 0}
                total3Class={"lite"}
                total3={"Lite"}
                number3={data?.lite ?? 0}
                number3Percentage={data?.litePercentage || 0}
                tooltip
                tooltipMessage={
                  "This represents the total number of calls that made by VehcileInfo"
                }
                tooltipMessage1={
                  "This represents the total number of Live calls that were made by VehcileInfo. 'Live' in this context refers to calls that were made directly to the server, as opposed to being retrieved from the cache."
                }
                tooltipMessage2={
                  "This represents the total number of Cache calls that were made by VehcileInfo. Cached calls are retrieved from stored data, rather than being made directly to the server."
                }
                tooltipMessage3={
                  "This represents the total number of Lite calls that were made by VehcileInfo.  'Lite' in this context refers to where some fields might be missing or contain limited information"
                }
                tooltipIdTotal={"tooltip-total-3"}
                tooltipId1={"tooltip-live-3"}
                tooltipId2={"tooltip-cached-3"}
                tooltipId3={"tooltip-lite-3"}
              />
            ) : (
              <Widget
                isPercentage
                name={"Total Request Calls"}
                totalNumber={data?.total_calls || 0}
                image={totalCalls}
                total1Class={"successful"}
                total1={"Successful"}
                number1Percentage={data?.successPercentage || 0}
                number1={data?.successful || 0}
                total2Class={"failed"}
                total2={"Failed"}
                number2Percentage={data?.failedPercentage || 0}
                number2={data?.failed || 0}
                tooltip={isTooltip}
                isVendor={isVendor}
                tooltipMessage={`This represents the total number of requests initiated by the all partners, regardless of their outcome (success or failure).`}
                tooltipMessage1={
                  "This represents the total number of calls that received a successful response."
                }
                tooltipMessage2={
                  "This represents the total number of calls that received a fail response."
                }
                tooltipIdTotal={"tooltip-total-1"}
                tooltipId1={"tooltip-live-1"}
                tooltipId2={"tooltip-cached-1"}
                tooltipId3={"tooltip-lite-1"}
              />
            )}
          </div>
          {isVendor ? (
            <div className="col-xxl-4 col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
              <div className="widget middleWidget d-flex flex-column justify-content-between">
                <div className="d-flex gap-2 mb-3">
                  <div className="flex-1 w-50">
                    <div className="total-numbers successful">
                      <p className="mb-1 text-gray-600 fs-14px">
                        Total Successful{" "}
                        <span style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            id="tooltip-button-two"
                            data-tooltip-content=" Total number of successful calls for VehicleInfo that received a 200 status code, regardless of whether all required parameters were fulfilled or not."
                            icon={faQuestionCircle}
                          />
                        </span>
                      </p>
                      <h4 className="mb-0 fw-semibold fs-22px">{data?.successful || 0}</h4>(
                      {data?.totalSuccessPercentage || 0}%)
                    </div>
                  </div>
                  <Tooltip
                    style={{ maxWidth: "25%" }}
                    anchorId="tooltip-button-two"
                    place="top"
                    effect="solid"
                  />
                  <div className="flex-1 w-50">
                    <div className="total-numbers live">
                      <p className="mb-1 text-gray-600 fs-14px">
                        Successful{" "}
                        <span style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            id="tooltip-button-successful"
                            data-tooltip-content="Number of calls that received a 200 status code and included all required parameters"
                            icon={faQuestionCircle}
                          />
                        </span>
                      </p>
                      <h4 className="mb-0 fw-semibold fs-22px">{data?.correct || 0}</h4>(
                      {data?.successPercentage || 0}%)
                      <Tooltip
                        style={{ maxWidth: "330px" }}
                        anchorId="tooltip-button-successful"
                        place="top"
                        effect="solid"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="flex-1 w-50">
                    <div className="total-numbers failed">
                      <p className="mb-1 text-gray-600 fs-14px">
                        Failed{" "}
                        <span style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            id="tooltip-button-failed"
                            data-tooltip-content="Total number of calls that did not succeed."
                            icon={faQuestionCircle}
                          />
                        </span>
                      </p>
                      <h4 className="mb-0 fw-semibold fs-22px">{data?.failed || 0}</h4>(
                      {data?.failedPercentage || 0}%)
                      <Tooltip anchorId="tooltip-button-failed" place="top" effect="solid" />
                    </div>
                  </div>
                  <div className="flex-1 w-50">
                    <div className="total-numbers partial">
                      <p className="mb-1 text-gray-600 fs-14px">
                        Insufficient{" "}
                        <span style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            id="tooltip-button-insufficient"
                            data-tooltip-content="Number of calls that were successful (received a 200 status code) but were missing some required parameters."
                            icon={faQuestionCircle}
                          />
                        </span>
                      </p>
                      <h4 className="mb-0 fw-semibold fs-22px">{data?.partial || 0}</h4>(
                      {data?.partialPercentage || 0}%)
                      <Tooltip
                        style={{ maxWidth: "330px" }}
                        anchorId="tooltip-button-insufficient"
                        place="top"
                        effect="solid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {!isVendor ? (
            <div className="col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
              <Widget
                isPercentage
                name={"Total Success Calls"}
                totalNumber={data?.successful || 0}
                totalNumberPercentage={data?.successPercentage || 0}
                image={totalCalls}
                total1Class={"live"}
                total1={"Live"}
                number1Percentage={data?.livePercentage || 0}
                number1={data?.live || 0}
                total2Class={"partial"}
                total2={"Cached"}
                number2={data?.cached || 0}
                number2Percentage={data?.cachedPercentage || 0}
                total3={"Lite"}
                total3Class={"lite"}
                number3Percentage={data?.litePercentage || 0}
                number3={data?.lite ?? 0}
                tooltip={isTooltip}
                tooltipMessage={
                  "This represents the total number of calls that received a successful response"
                }
                tooltipMessage1={
                  "This represents the total number of live calls that received a successful response. 'Live' in this context refers to calls that were made directly to the server, as opposed to being retrieved from the cache."
                }
                tooltipMessage2={
                  "This represents the total number of cached calls that received a successful response. Cached calls are retrieved from stored data, rather than being made directly to the server."
                }
                tooltipMessage3={
                  "This represents the total number of Lite calls that received a successful response.  'Lite' in this context refers to where some fields might be missing or contain limited information"
                }
                tooltipIdTotal={"tooltip-total-2"}
                tooltipId1={"tooltip-live-2"}
                tooltipId2={"tooltip-cached-2"}
                tooltipId3={"tooltip-lite-2"}
              />
            </div>
          ) : (
            ""
          )}

          <div className="col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            {!isVendor && (
              <Widget
                name={"Profit"}
                totalNumber={data?.profit?.toFixed(2) || 0}
                image={revenue}
                total1Class={"live"}
                total1={"Revenue"}
                number1={data?.revenue?.toFixed(2) || 0}
                total2Class={"failed"}
                total2={"Cost"}
                number2={data?.cost?.toFixed(2) || 0}
                isRupee
              />
            )}

            {isVendor && (
              <WidgetVendor
                name={"Cost"}
                totalNumber={data.cost?.toFixed(2) || 0}
                image={revenue}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardWidgets;
