import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as EmailValidator from "email-validator";
import { updateSettings } from "../../store/action/admin/settingAction";

const BasicDetailsSetting = () => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [basicSetting, setBasicSetting] = useState({
    phone: "",
    email: "",
    payment_information: "",
    address: "",
    failed_threshold_check_time: "",
  });

  useEffect(() => {
    if (settings) {
      setBasicSetting({
        phone: settings.phone || "",
        email: settings.email || "",
        payment_information: settings.payment_information || "",
        address: settings.address || "",
        failed_threshold_check_time: settings.failed_threshold_check_time || "",
      });
    }
  }, [settings]);

  const [errors, setErrors] = useState({});

  const onChangeInput = e => {
    const { name, value } = e.target;
    setBasicSetting({ ...basicSetting, [name]: value });
  };

  const handleValidation = () => {
    let errors = {};
    let isValid = false;
    if (!EmailValidator.validate(basicSetting["email"])) {
      if (!basicSetting["email"]) {
        errors["email"] = "Please enter an email address";
      } else {
        errors["email"] = "Please enter a valid email address";
      }
    } else if (!/^\d+$/.test(basicSetting["phone"])) {
      errors["phone"] = "Phone number must contain only numbers";
    } else if (basicSetting["phone"].length > 15) {
      errors["phone"] = "Phone number should be less than 15 numbers";
    } else {
      isValid = true;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      dispatch(updateSettings(basicSetting, setLoading));
      setErrors({});
      setIsEditMode(false);
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const formatPaymentInfo = info => {
    return info.replace(/\n/g, "<br />");
  };

  return (
    <>
      <form>
        <div className="p-4">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="form-group">
                <label
                  htmlFor="phone"
                  className={`form-label fw-medium mb-1 fs-16px ${!isEditMode ? "fw-bold" : ""}`}
                >
                  Phone:
                </label>
                {isEditMode ? (
                  <input
                    placeholder="Phone"
                    type="number"
                    id="phone"
                    name="phone"
                    value={basicSetting.phone}
                    onChange={onChangeInput}
                    className="form-control fw-medium fs-16px"
                  />
                ) : (
                  <p className="fw-medium fs-16px">{basicSetting.phone}</p>
                )}
              </div>
              <span className="text-danger d-block fw-400 fs-small mt-2">
                {errors["phone"] ? errors["phone"] : null}
              </span>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-group">
                <label
                  htmlFor="email"
                  className={`form-label fw-medium mb-1 fs-16px ${!isEditMode ? "fw-bold" : ""}`}
                >
                  Email:
                </label>
                {isEditMode ? (
                  <input
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={basicSetting.email}
                    onChange={onChangeInput}
                    className="form-control fw-medium fs-16px"
                  />
                ) : (
                  <p className="fw-medium fs-16px">{basicSetting.email}</p>
                )}
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["email"] ? errors["email"] : null}
                </span>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="form-group">
                <label
                  htmlFor="paymentInfo"
                  className={`form-label fw-medium mb-1 fs-16px ${!isEditMode ? "fw-bold" : ""}`}
                >
                  Payment Information:
                </label>
                {isEditMode ? (
                  <textarea
                    placeholder="Payment Information"
                    name="payment_information"
                    value={basicSetting.payment_information}
                    onChange={onChangeInput}
                    className="form-control fw-medium fs-16px"
                    rows={5}
                  />
                ) : (
                  <p
                    className="fw-medium fs-16px"
                    dangerouslySetInnerHTML={{
                      __html: formatPaymentInfo(basicSetting.payment_information),
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="form-group">
                <label
                  htmlFor="address"
                  className={`form-label fw-medium mb-1 fs-16px ${!isEditMode ? "fw-bold" : ""}`}
                >
                  Address:
                </label>
                {isEditMode ? (
                  <textarea
                    placeholder="Address"
                    name="address"
                    value={basicSetting.address}
                    onChange={onChangeInput}
                    className="form-control fw-medium fs-16px"
                    rows={3}
                  />
                ) : (
                  <p
                    className="fw-medium fs-16px"
                    dangerouslySetInnerHTML={{
                      __html: formatPaymentInfo(basicSetting.address),
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-6 mb-4">
              <div className="form-group">
                <label
                  htmlFor="failed_threshold_check_time"
                  className={`form-label fw-medium mb-1 fs-16px ${!isEditMode ? "fw-bold" : ""}`}
                >
                  Failed threshold time (in minutes):
                </label>
                {isEditMode ? (
                  <input
                    placeholder="0"
                    type="number"
                    name="failed_threshold_check_time"
                    value={basicSetting.failed_threshold_check_time}
                    onChange={onChangeInput}
                    className="form-control fw-medium fs-16px"
                  />
                ) : (
                  <p className="fw-medium fs-16px">{basicSetting.failed_threshold_check_time}</p>
                )}
              </div>
            </div>
            <div className="col-12 d-flex">
              <button type="button" onClick={toggleEditMode} className="btn btn-secondary me-2">
                {isEditMode ? "View" : "Edit"}
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!isEditMode}
                className="btn btn-primary"
              >
                {loading ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BasicDetailsSetting;
