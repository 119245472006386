import apiConfig from "../../../config/apiConfig";
import { adminBaseURL, adminActionType } from "../../../constants";
import toast from "react-hot-toast";

export const fetchSettings = () => async dispatch => {
  await apiConfig
    .get(adminBaseURL.SETTINGS)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_SETTINGS,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const updateSettings = (payload, setLoading) => async dispatch => {
  await apiConfig
    .post(adminBaseURL.SETTINGS, payload)
    .then(response => {
      dispatch({
        type: adminActionType.EDIT_SETTINGS,
        payload: response.data.data,
      });
      setLoading(false);
      dispatch(fetchSettings());
      toast.success("Settings updated successfully");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};
