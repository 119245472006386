import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "../../assets/css/setting.css";
import NotificationSettings from "./NotificationSettings";
import MasterLayout from "../../components/MasterLayout";
import BasicDetailsSetting from "./BasicDetailsSetting";
import { fetchSettings } from "../../store/action/admin/settingAction";
import TabTitle from "../../components/shared/tabTitle/TabTitle";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("basic-details");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleChangeTab = tabName => {
    setActiveTab(tabName);
  };

  return (
    <>
      <MasterLayout>
        <TabTitle title={"Settings"} />
        <div className="setting-page py-30px">
          <div className="container">
            <div className="section-heading d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
              <h2 className="mb-0 fw-semibold">Settings</h2>
            </div>
            <div className="wrapper">
              <div className="p-4">
                <div className="setting-tabs">
                  <Tabs
                    id="settings-tabs"
                    activeKey={activeTab}
                    onSelect={key => handleChangeTab(key)}
                    className="mb-3"
                  >
                    <Tab eventKey="basic-details" title="Basic Details">
                      <BasicDetailsSetting />
                    </Tab>
                    <Tab eventKey="notifications" title="Notifications">
                      <NotificationSettings />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    </>
  );
};

export default Settings;
