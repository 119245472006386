import { adminActionType } from "../../../constants";

const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case adminActionType.FETCH_SETTINGS:
      return action.payload;
    case adminActionType.EDIT_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};

export default AuthReducer;
