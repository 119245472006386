import React, { useEffect, useState, useCallback } from "react";
import DashboardWidgets from "../dashboard/DashboardWidgets";
import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVendorDashboardChart,
  fetchVendorDashboardSource,
  fetchVendorDashboardWidget,
} from "../../store/action/admin/vendorAction";
import Statistics from "../dashboard/Statistics";
import StackedBarChart from "../../components/shared/chart/StackedBarChart";
import PageHeader from "../../components/PageHeader";
import { formatChartLabels } from "../../components/shared/sharedMethod";
import logIcon from "../../assets/images/log-icon.svg";

const VendorsDashboard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const vendorName = localStorage.getItem("vendorName");
  const navigate = useNavigate();

  const { vendorDashboardWidget, vendorDashboardSource, vendorDashboardChart } = useSelector(
    state => state.vendors,
  );

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorName, setSelectedVendorName] = useState(null);
  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return startDate;
    })(),
    new Date(),
  ]);

  const handleVendorSelect = useCallback(vendor => {
    setSelectedVendor(vendor.value);
    setSelectedVendorName(vendor.label);
  }, []);

  const handleDateSelect = useCallback(dateRange => {
    setSelectedDates(dateRange);
  }, []);

  const fetchData = useCallback(() => {
    if (!selectedVendor || !selectedDates) return;

    const filter = {
      start_date: selectedDates && selectedDates[0] ? selectedDates[0] : "",
      end_date: selectedDates && selectedDates[1] ? selectedDates[1] : "",
    };
    const vendorId = selectedVendor || id;
    dispatch(fetchVendorDashboardWidget(filter, vendorId));
    dispatch(fetchVendorDashboardSource(filter, vendorId));
    dispatch(fetchVendorDashboardChart(filter, vendorId));
  }, [dispatch, id, selectedDates, selectedVendor]);

  useEffect(() => {
    setSelectedVendor(localStorage.getItem("vendorId"));
    setSelectedVendorName(localStorage.getItem("vendorName"));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const goToButtonHandle = () => {
    localStorage.removeItem("vendorName");
    localStorage.removeItem("vendorId");
    localStorage.setItem("vendorId", selectedVendor || id);
    localStorage.setItem("vendorName", selectedVendorName);
    navigate(`/admin/vendors/${selectedVendor || id}/logs`);
  };

  const numberOfCalls = [
    {
      label: "Successful",
      backgroundColor: "#08A975",
      data: vendorDashboardChart?.vendorNumOfCalls?.datasets?.[0]?.data || [],
    },
    {
      label: "Insufficient",
      backgroundColor: "#FF9900",
      data: vendorDashboardChart?.vendorNumOfCalls?.datasets?.[1]?.data || [],
    },
    {
      label: "Failed",
      backgroundColor: "#EC6969",
      data: vendorDashboardChart?.vendorNumOfCalls?.datasets?.[2]?.data || [],
    },
  ];

  const numberOfCallsByType = [
    {
      label: "Live",
      backgroundColor: "#08A975",
      data: vendorDashboardChart?.vendorNumOfCallsByType?.datasets?.[0]?.data || [],
    },
    {
      label: "Cached",
      backgroundColor: "#FF9900",
      data: vendorDashboardChart?.vendorNumOfCallsByType?.datasets?.[1]?.data || [],
    },
  ];

  return (
    <MasterLayout>
      <TabTitle title={`${vendorName} Dashboard`} />
      <div className="dashboard-page py-30px">
        <div className="container">
          <PageHeader
            title={`${vendorName} Dashboard`}
            backLink={"/admin/vendors"}
            isDatePicker
            onDateSelect={handleDateSelect}
            isSelectionPart
            isBackButtonLast
            isTopPagination={false}
            navigationButtonText={<img src={logIcon} alt="log" />}
            navigationHandle={goToButtonHandle}
            isNavigationButton
            isTitleSelectable
            handleTitleChange={handleVendorSelect}
            isPartnerOptions={false}
            selectedPartnerOption={{
              value: selectedVendor,
              label: selectedVendorName,
            }}
            isDashboard
          />
          <DashboardWidgets data={vendorDashboardWidget?.[0]} isVendor={false} isTooltip={true} />
          <div className="chart-section">
            <div className="row">
              <div className="col-lg-12 mb-4 pb-1">
                <div className=" bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls</h2>
                  <StackedBarChart
                    id={1}
                    data={numberOfCalls}
                    labels={formatChartLabels(vendorDashboardChart?.vendorNumOfCalls?.labels)}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls by Type</h2>
                  <StackedBarChart
                    id={2}
                    data={numberOfCallsByType}
                    labels={formatChartLabels(vendorDashboardChart?.vendorNumOfCallsByType?.labels)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Statistics
            customClass="partner-dashboard-statastics"
            style={{ maxHeight: "1260px", overflowY: "auto" }}
            statisticsFor={"other"}
            name={"Source Data"}
            columns={[
              "No.",
              "Date",
              "No. of Calls",
              "Failed",
              "Total Successful",
              "Insufficient",
              "Successful",
              "Live",
              "Cached",
              "Lite",
              "Cost",
              "Revenue",
              "Net Revenue",
            ]}
            isPercentage
            statisticsData={vendorDashboardSource}
            isLink
            navigationPath={`/admin/vendors/${id}/logs`}
          />
        </div>
      </div>
    </MasterLayout>
  );
};

export default VendorsDashboard;
