// Admin Routes
import Dashboard from "../admin/dashboard/Dashboard";
import Partners from "../admin/partners/Partners";
import CreatePartner from "../admin/partners/CreatePartner";
import EditPartner from "../admin/partners/EditPartner";
import PartnerDashboard from "../admin/partners/PartnersDashboard";
import PartnerLogs from "../admin/partnerLogs/PartnerLogs";
import PartnerLogsDetails from "../admin/partnerLogs/PartnerLogsDetails";
import EditVendor from "../admin/vendors/EditVendor";
import CreateVendor from "../admin/vendors/CreateVendor";
import Invoices from "../admin/invoices/Invoices";
import Vendors from "../admin/vendors/Vendors";
import VendorsDashboard from "../admin/vendors/VendorsDashboard";
import Settings from "../admin/settings/Settings";
import VendorLogs from "../admin/vendorLogs/VendorLogs";
import VendorLogsDetails from "../admin/vendorLogs/VendorLogsDetails";
import EditInvoice from "../admin/invoices/EditInvoice";
import SinglePartnerLogs from "../admin/partners/SinglePartnerLogs";
import SingleVendorLogs from "../admin/vendors/SingleVendorLogs";

// Partner Routes
import SinglePartnerDashboard from "../partner/dashboard/Dashboard";
import PartnerLog from "../partner/logs/Logs";
import PartnerLogDetails from "../partner/logs/LogDetails";
import PartnerInvoice from "../partner/invoices/Invoices";

// Vendor Routes
import SingleVendorDashboard from "../vendor/dashboard/Dashboard";
import VendorLog from "../vendor/logs/Logs";
import VendorLogDetails from "../vendor/logs/LogDetails";
import VendorInvoice from "../vendor/invoices/Invoices";

export const adminRoutes = [
  {
    path: "/admin/dashboard",
    Element: Dashboard,
  },
  {
    path: "/admin/partners",
    Element: Partners,
  },
  {
    path: "/admin/partners/create",
    Element: CreatePartner,
  },
  {
    path: "/admin/partners/:id/edit",
    Element: EditPartner,
  },
  {
    path: "/admin/partners/:id/dashboard",
    Element: PartnerDashboard,
  },
  {
    path: "/admin/partners-logs",
    Element: PartnerLogs,
  },
  {
    path: "/admin/partners/:id/logs",
    Element: SinglePartnerLogs,
  },
  {
    path: "/admin/partners-logs/:id",
    Element: PartnerLogsDetails,
  },
  {
    path: "/admin/vendors",
    Element: Vendors,
  },
  {
    path: "/admin/vendors/create",
    Element: CreateVendor,
  },
  {
    path: "/admin/vendors/:id/logs",
    Element: SingleVendorLogs,
  },
  {
    path: "/admin/vendors/:id/edit",
    Element: EditVendor,
  },
  {
    path: "/admin/vendors/:id/dashboard",
    Element: VendorsDashboard,
  },
  {
    path: "/admin/vendors-logs",
    Element: VendorLogs,
  },
  {
    path: "/admin/vendors-logs/:id",
    Element: VendorLogsDetails,
  },
  {
    path: "/admin/invoices",
    Element: Invoices,
  },
  {
    path: "/admin/invoices/:id/edit",
    Element: EditInvoice,
  },
  {
    path: "/admin/settings",
    Element: Settings,
  },
];

export const partnerRoutes = [
  {
    path: "/partner/dashboard",
    Element: SinglePartnerDashboard,
  },
  {
    path: "/partner/logs",
    Element: PartnerLog,
  },
  {
    path: "/partner/logs/:id",
    Element: PartnerLogDetails,
  },
  {
    path: "/partner/invoices",
    Element: PartnerInvoice,
  },
];

export const vendorRoutes = [
  {
    path: "/vendor/dashboard",
    Element: SingleVendorDashboard,
  },
  {
    path: "/vendor/logs",
    Element: VendorLog,
  },
  {
    path: "/vendor/logs/:id",
    Element: VendorLogDetails,
  },
  {
    path: "/vendor/invoices",
    Element: VendorInvoice,
  },
];
