import React from "react";
import totalCalls from "../../assets/images/dashboard/total-calls.png";
import revenue from "../../assets/images/dashboard/revenue.png";
import limit from "../../assets/images/dashboard/limit.png";
import { Widget } from "../../components/shared/Widget";
import { useSelector } from "react-redux";

const DashboardWidgets = ({ data, isTooltip = true, isAdmin = false }) => {
  const { userProfile } = useSelector(state => state);
  const userName = localStorage.getItem("partnerName");
  return (
    <>
      <div className="widget-section">
        <div className="row">
          <div className="col-xxl-3 col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            <Widget
              isPercentage
              name={"Total Request Calls"}
              totalNumber={data?.total_calls || 0}
              image={totalCalls}
              total1Class={"successful"}
              total1={"Successful"}
              number1={data?.successful || 0}
              number1Percentage={data?.successPercentage || 0.0}
              total2Class={"failed"}
              total2={"Failed"}
              number2={data?.failed || 0}
              number2Percentage={data?.failedPercentage || 0.0}
              tooltip
              tooltipMessage={`This represents the total number of requests initiated by the ${isAdmin ? userName : userProfile.full_name}, regardless of their outcome (success or failure).`}
              tooltipMessage1={
                "This represents the total number of calls that received a successful response."
              }
              tooltipMessage2={
                "This represents the total number of calls that received a fail response."
              }
              tooltipIdTotal={"tooltip-total-1"}
              tooltipId1={"tooltip-live-1"}
              tooltipId2={"tooltip-cached-1"}
              tooltipId3={"tooltip-lite-1"}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            <Widget
              isPercentage
              name={"Total Success Calls"}
              totalNumber={data?.successful || 0}
              image={totalCalls}
              total1Class={"live"}
              total1={"Live"}
              number1={data?.live || 0}
              number1Percentage={data?.livePercentage || 0.0}
              total2Class={"partial"}
              total2={"Cached"}
              number2={data?.cached || 0}
              number2Percentage={data?.cachedPercentage || 0.0}
              total3={"Lite"}
              total3Class={"lite"}
              number3={data?.lite || 0}
              number3Percentage={data?.litePercentage || 0.0}
              tooltip
              tooltipMessage={
                "This represents the total number of calls that received a successful response"
              }
              tooltipMessage1={
                "This represents the total number of live calls that received a successful response. 'Live' in this context refers to calls that were made directly to the server, as opposed to being retrieved from the cache."
              }
              tooltipMessage2={
                "This represents the total number of cached calls that received a successful response. Cached calls are retrieved from stored data, rather than being made directly to the server."
              }
              tooltipMessage3={
                "This represents the total number of Lite calls that received a successful response.  'Lite' in this context refers to where some fields might be missing or contain limited information"
              }
              tooltipIdTotal={"tooltip-total-2"}
              tooltipId1={"tooltip-live-2"}
              tooltipId2={"tooltip-cached-2"}
              tooltipId3={"tooltip-lite-2"}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            <Widget
              name={"Profit"}
              totalNumber={data?.profit?.toFixed(2) || 0}
              image={revenue}
              total1Class={"live"}
              total1={"Revenue"}
              number1={data?.revenue?.toFixed(2) || 0}
              total2Class={"failed"}
              total2={"Cost"}
              number2={data?.cost?.toFixed(2) || 0}
              isRupee
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 mb-sm-4 mb-3 pb-md-2">
            <Widget
              name={"Total Month Limit"}
              totalNumber={data?.monthly_limit || 0}
              image={limit}
              total1Class={"canceled"}
              total1={"Used Limit"}
              number1={data?.monthly_limit - data?.recurring_monthly_limit || "0"}
              total2Class={"canceled"}
              total2={"Remaining Limit"}
              number2={data?.recurring_monthly_limit || 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardWidgets;
