import apiConfig from "../../../config/apiConfig";
import { adminBaseURL, adminActionType } from "../../../constants";
import toast from "react-hot-toast";

export const fetchVendorsListing = () => async dispatch => {
  apiConfig
    .get(`${adminBaseURL.VENDORS}/filter/listing`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_VENDORS_LISTING,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const fetchPartnersListing = () => async dispatch => {
  apiConfig
    .get(`${adminBaseURL.PARTNERS}/filter/listing`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_PARTNERS_LISTING,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
