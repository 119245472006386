import toast from "react-hot-toast";
import { adminDashboardAction, adminBaseURL, adminDashboardChartAction } from "../../../constants";
import apiConfig from "../../../config/apiConfig";
import moment from "moment";

export const getDashboardWidgetDetailsAction = (start_date, end_date) => dispatch => {
  let url = adminBaseURL.DASHBOARD_API;
  if (start_date && end_date) {
    url += `?from_date=${moment(start_date).format(
      "YYYY-MM-DD",
    )}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: adminDashboardAction.GET_DASHBOARD_WIDGET_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardTableStatisticsAction = (start_date, end_date) => dispatch => {
  let url = `${adminBaseURL.DASHBOARD_API}/listing`;
  if (start_date && end_date) {
    url += `?from_date=${moment(start_date).format(
      "YYYY-MM-DD",
    )}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: adminDashboardAction.GET_DASHBOARD_STATISTICS_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardChartDataAction = (start_date, end_date) => dispatch => {
  let url = `${adminBaseURL.DASHBOARD_CHART}`;
  if (start_date && end_date) {
    url += `?from_date=${moment(start_date).format(
      "YYYY-MM-DD",
    )}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: adminDashboardChartAction.GET_DASHBOARD_CHART_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardRevenueChartDataAction = (start_date, end_date) => dispatch => {
  let url = `${adminBaseURL.DASHBOARD_REVENUE_CHART}`;
  if (start_date && end_date) {
    url += `?from_date=${moment(start_date).format(
      "YYYY-MM-DD",
    )}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: adminDashboardChartAction.GET_DASHBOARD_REVENUE_CHART_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
