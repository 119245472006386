import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../components/tab/Tab";
import { fetchLogDetails } from "../../store/action/vendor/logsAction";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import MasterLayout from "../../components/MasterLayout";
import PageHeader from "../../components/PageHeader";
import { getDataType, getStatusClass, getStatusText } from "../../components/shared/sharedMethod";
import moment from "moment";

const LogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorLog = useSelector(state => state.vendorLog);
  const { userProfile } = useSelector(state => state);

  useEffect(() => {
    dispatch(fetchLogDetails(id, navigate));
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    window.close();
  };

  return (
    <>
      {/* <MasterLayout> */}
      <TabTitle title={`${vendorLog?.reg_no} | ${userProfile?.full_name}`} />
      <div className="log-page log-details-page py-30px">
        <div className="container">
          <PageHeader
            title={"Log Details"}
            isBackButton
            backLink={"/vendor/logs"}
            handleBack={handleBack}
          />
          <div className="mb-4 pb-1">
            <div className="bg-white wrapper p-4">
              <div className="row">
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Time:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {moment(vendorLog.created_at).format("YYYY-MM-DD H:mm:ss")}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Duration:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{vendorLog.duration}ms</h4>
                </div>
                {/* <div className="col-lg-4 col-6 mb-4">
                    <p className="mb-1 text-gray-600 fs-16px">Vendor Name:</p>
                    <h4 className="mb-0 fw-medium fs-16px">
                      {vendorLog.full_name}
                    </h4>
                  </div> */}
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status Code:</p>
                  <span
                    className={`badge ${
                      vendorLog.status_code === 200 ? "successful-badge" : "failed-badge"
                    }`}
                  >
                    {vendorLog.status_code}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status:</p>
                  <span className={`badge ${getStatusClass(vendorLog.status)}`}>
                    {getStatusText(vendorLog.status, true)}
                  </span>
                </div>
                <div className="col-lg-4 col-6">
                  <p className="mb-1 text-gray-600 fs-16px">Revenue:</p>
                  <h4 className="mb-0 fw-medium fs-16px">₹{vendorLog.cost}</h4>
                </div>
                <div className="col-lg-4 col-6">
                  <p className="mb-1 text-gray-600 fs-16px">Request type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{getDataType(vendorLog.data_type)}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">RC No.:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{vendorLog.reg_no}</h4>
                </div>
              </div>
            </div>
          </div>

          <Tabs
            className={"vendor-tabs"}
            headerDetails={vendorLog.headers ? JSON.stringify(vendorLog.headers) : null}
            requestDetails={vendorLog.request ? JSON.stringify(vendorLog.request) : null}
            responseDetails={vendorLog.response ? JSON.stringify(vendorLog.response) : null}
          />
        </div>
      </div>
      {/* </MasterLayout> */}
    </>
  );
};
export default LogDetails;
