import React, { useState, useEffect } from "react";
import SimpleModal from "../modals/SimpleModal";
import { useDispatch } from "react-redux";
import { changePassword } from "../../store/action/authAction";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({ name, label, value, onChange, error, autoFocus }) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = React.useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className="mb-3">
      <label className="form-label fw-medium mb-1 fs-16px">{label}:</label>
      <span className="text-danger fs-16px">*</span>
      <div className="mb-2 position-relative">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control fw-medium fs-16px"
          name={name}
          value={value}
          onChange={onChange}
          ref={inputRef}
          autoComplete="off"
        />
        <span
          onClick={togglePasswordVisibility}
          className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0 me-3 input-icon cursor-pointer text-gray-600 input-password-toggle"
        >
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </span>
      </div>
      <span className="text-danger d-block fw-400 fs-small mt-2">{error ? error : null}</span>
    </div>
  );
};

const ChangePassword = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [passwordInputs, setPasswordInputs] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setPasswordInputs(inputs => ({
      ...inputs,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    for (let field in passwordInputs) {
      if (passwordInputs[field].trim() === "") {
        errors[field] = `Please enter ${field.replace("_", " ")}`;
        isValid = false;
      }
    }

    if (passwordInputs["password"] !== passwordInputs["password_confirmation"]) {
      errors["password_confirmation"] = "Password and confirm password should be same";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const onSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      dispatch(changePassword(passwordInputs, handleClose, setLoading));
    }
  };

  return (
    <SimpleModal
      title={"Change Password"}
      show={show}
      handleSubmit={onSubmit}
      handleClose={handleClose}
      loading={loading}
    >
      <PasswordInput
        label="Current Password"
        name="current_password"
        value={passwordInputs.current_password}
        onChange={handleInputChange}
        error={errors.current_password}
        autoFocus={true}
      />
      <PasswordInput
        label="New Password"
        name="password"
        value={passwordInputs.password}
        onChange={handleInputChange}
        error={errors.password}
      />
      <PasswordInput
        label="Confirm Password"
        name="password_confirmation"
        value={passwordInputs.password_confirmation}
        onChange={handleInputChange}
        error={errors.password_confirmation}
      />
    </SimpleModal>
  );
};

export default ChangePassword;
