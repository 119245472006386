import toast from "react-hot-toast";
import {
  partnerDashboardAction,
  partnerAPIURL,
  partnerDashboardChartAction,
} from "../../../constants";
import apiConfig from "../../../config/apiConfig";
import _ from "lodash";
import requestParam from "../../../components/shared/requestParam";

export const getDashboardWidgetDetailsAction = filter => dispatch => {
  let url = `${partnerAPIURL.PARTNER}/statatics`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: partnerDashboardAction.GET_DASHBOARD_WIDGET_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardTableStatisticsAction = filter => dispatch => {
  let url = `${partnerAPIURL.PARTNER}/source`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }

  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: partnerDashboardAction.GET_DASHBOARD_STATISTICS_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getDashboardChartDataAction = filter => dispatch => {
  let url = `${partnerAPIURL.PARTNER}/number-of-calls/chart`;
  if (!_.isEmpty(filter)) {
    url += requestParam(filter, url);
  }
  apiConfig
    .get(url)
    .then(response => {
      dispatch({
        type: partnerDashboardChartAction.GET_DASHBOARD_CHART_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getBillingDataAction = () => dispatch => {
  apiConfig
    .get(`${partnerAPIURL.PARTNER}/billing`)
    .then(response => {
      dispatch({
        type: partnerDashboardAction.GET_BILLING_DATA,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const getUsageWarning = () => dispatch => {
  apiConfig
    .get(`${partnerAPIURL.PARTNER}/warning`)
    .then(response => {
      dispatch({
        type: partnerDashboardAction.GET_WARNING,
        payload: response.data.message,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
