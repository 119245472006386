import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import "../../assets/css/partners.css";
import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PageHeader from "../../components/PageHeader";
import { getInvoice, updateInvoice } from "../../store/action/admin/invoiceAction";
import { formatCurrency } from "../../components/shared/sharedMethod";

const EditInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoice } = useSelector(state => state.invoices);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [deletedItems, setDeletedItems] = useState([]);

  const [invoiceValue, setInvoiceValue] = useState({
    full_name: "",
    phone_number: "",
    invoice_number: "",
    invoice_date: "",
    invoiceItems: [],
    address: "",
    tax: 0,
  });

  const [errors, setErrors] = useState({
    phone_number: "",
    address: "",
  });

  const handleValidation = () => {
    let errors = {};
    let isValid = false;

    if (invoiceValue["phone_number"].trim() === "") {
      errors["phone_number"] = "Please enter phone number";
    } else if (!/^\d+$/.test(invoiceValue["phone_number"])) {
      errors["phone_number"] = "Phone number must contain only numbers";
    } else if (invoiceValue["phone_number"].length > 15) {
      errors["phone_number"] = "Phone number should be less than 15 numbers";
    } else if (invoiceValue["address"].trim() === "") {
      errors["address"] = "Please enter an address";
    } else {
      isValid = true;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (invoice) {
      setInvoiceValue({
        full_name: invoice.full_name || "",
        phone_number: invoice.phone_number || "",
        invoice_number: invoice.invoice_number || "",
        invoice_date: invoice.invoice_date || "",
        invoiceItems: invoice.invoiceItems || "",
        address: invoice.address || "",
        tax: invoice.tax || 0,
      });
    }
  }, [invoice]);

  useEffect(() => {
    dispatch(getInvoice("admin", id));
  }, [dispatch, id]);

  const onChangeInput = e => {
    const { name, value } = e.target;

    setInvoiceValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...invoiceValue.invoiceItems];
    items[index][name] = value;
    setInvoiceValue(prevState => ({
      ...prevState,
      invoiceItems: items,
    }));
  };

  const handleAddItem = () => {
    setInvoiceValue(prevState => ({
      ...prevState,
      invoiceItems: [...prevState.invoiceItems, { item_name: "", qty: "", unit_price: "" }],
    }));
  };

  const handleRemoveItem = index => {
    const items = [...invoiceValue.invoiceItems];
    const deletedItem = items.splice(index, 1)[0];
    setInvoiceValue(prevState => ({
      ...prevState,
      invoiceItems: items,
    }));
    setDeletedItems(prevDeletedItems => [...prevDeletedItems, deletedItem.id]);
  };

  const handleDateChange = date => {
    setInvoiceValue({
      ...invoiceValue,
      invoice_date: moment(date[0]).format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    let itemsValid = true;
    invoiceValue.invoiceItems.forEach(item => {
      if (!item.item_name || !item.qty || !String(item.unit_price)) {
        itemsValid = false;
      }
    });
    if (!itemsValid) {
      setErrors(prevErrors => ({
        ...prevErrors,
        itemError: "Please fill in all item details",
      }));
      return;
    }
    if (valid) {
      setLoading(true);
      dispatch(
        updateInvoice(
          "admin",
          id,
          {
            ...invoiceValue,
            invoice_date: moment(invoiceValue.invoice_date).format("YYYY-MM-DD"),
            deletedItems: deletedItems,
          },
          setLoading,
          navigate,
        ),
      );
    }
  };

  const calculateTotal = () => {
    let total = 0;
    invoiceValue.invoiceItems.forEach(item => {
      total += item.qty * item.unit_price;
    });
    return total;
  };

  const calculateTax = () => {
    if (invoiceValue.tax !== undefined) {
      const total = calculateTotal();
      let tax = (Number(invoiceValue.tax) * Number(total)) / 100;
      return parseFloat(tax);
    }
    return parseFloat(0);
  };

  return (
    <>
      <MasterLayout>
        <TabTitle title={"Edit Invoice"} />
        <div className="edit-partners-page py-30px">
          <div className="container">
            <PageHeader title={"Edit Invoice"} isBackButton backLink={"/admin/invoices"} />
            <div className="wrapper">
              <div className="p-4">
                <form>
                  <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div>
                        <label className="form-label fw-medium mb-1 fs-16px">Invoice Number:</label>
                        <span className="form-control fw-medium fs-16px bg-gray-100">
                          #{invoiceValue.invoice_number}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div>
                        <label className="form-label fw-medium mb-1 fs-16px">Invoice Date:</label>
                        <div className="position-relative ms-auto custom-rangepicker">
                          <Flatpickr
                            placeholder={moment(invoiceValue.invoice_date).format("DD-MM-YYYY")}
                            options={{
                              mode: "single",
                              enableTime: false,
                              maxDate: new Date(),
                              dateFormat: "d-m-Y",
                              defaultDate: invoiceValue.invoice_date,
                              onChange: handleDateChange,
                            }}
                            value={moment(invoiceValue.invoice_date).format("DD-MM-YYYY")}
                            className="form-control datepicker custom-datepicker "
                          />
                          <div className="date-icon pe-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M18 5.25V6H0V5.25C0 3.17925 1.67925 1.5 3.75 1.5H4.5V0.75C4.5 0.336 4.836 0 5.25 0C5.664 0 6 0.336 6 0.75V1.5H12V0.75C12 0.336 12.336 0 12.75 0C13.164 0 13.5 0.336 13.5 0.75V1.5H14.25C16.3207 1.5 18 3.17925 18 5.25ZM18 12.75C18 15.645 15.6442 18 12.75 18C9.85575 18 7.5 15.645 7.5 12.75C7.5 9.855 9.85575 7.5 12.75 7.5C15.6442 7.5 18 9.855 18 12.75ZM14.25 13.1895L13.5 12.4395V11.25C13.5 10.836 13.164 10.5 12.75 10.5C12.336 10.5 12 10.836 12 11.25V12.75C12 12.9487 12.0787 13.14 12.2197 13.2803L13.1895 14.25C13.482 14.5425 13.9575 14.5425 14.25 14.25C14.5425 13.9575 14.5425 13.482 14.25 13.1895ZM6 12.75C6 10.6283 6.981 8.7375 8.51175 7.5H0V14.25C0 16.3207 1.67925 18 3.75 18H8.51175C6.981 16.7625 6 14.8717 6 12.75Z"
                                fill="#456DFF"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h6 className="fs-18px fw-semibold"> Billed To</h6>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <label className="form-label fw-medium mb-1 fs-16px">Name:</label>
                      <span className="form-control fw-medium fs-16px bg-gray-100">
                        {invoiceValue.full_name}
                      </span>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <label className="form-label fw-medium mb-2 fs-16px">Phone Number:</label>
                      <span className="text-danger fs-16px">*</span>
                      <input
                        type="number"
                        className="form-control fw-medium fs-16px"
                        placeholder="Phone number"
                        name="phone_number"
                        value={invoiceValue.phone_number}
                        onChange={e => onChangeInput(e)}
                      />
                      <span className="text-danger d-block fw-400 fs-small mt-2">
                        {errors["phone_number"] ? errors["phone_number"] : null}
                      </span>
                    </div>
                    <div className="col-xl-6  mb-4">
                      <label className="form-label fw-medium mb-2 fs-16px">Address:</label>
                      <span className="text-danger fs-16px">*</span>
                      <input
                        type="text"
                        className="form-control fw-medium fs-16px"
                        placeholder="Address"
                        name="address"
                        value={invoiceValue.address}
                        onChange={e => onChangeInput(e)}
                      />
                      <span className="text-danger d-block fw-400 fs-small mt-2">
                        {errors["address"] ? errors["address"] : null}
                      </span>
                    </div>
                  </div>
                  <div className="mb-md-0 mb-4">
                    <h6 className="fs-18px fw-semibold"> Item Details</h6>
                    <div className="w-100 overflow-auto">
                      <table className="table table-borderless w-100 mb-0 whitespace-nowrap">
                        <thead>
                          <tr>
                            <th className="fw-medium fs-16px ps-0">Item Name:</th>
                            <th className="fw-medium fs-16px">Quantity:</th>
                            <th className="fw-medium fs-16px">Unit Price (In Rupees):</th>
                            <th className="fw-medium fs-16px text-end">Amount:</th>
                            <th className="fw-medium fs-16px text-end pe-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceValue.invoiceItems.length > 0 &&
                            invoiceValue.invoiceItems.map((item, index) => (
                              <tr key={index}>
                                <td className="ps-0 w-40">
                                  <input
                                    type="text"
                                    className="form-control fw-medium fs-16px "
                                    placeholder="Item Name"
                                    name="item_name"
                                    value={item.item_name}
                                    onChange={e => handleItemChange(index, e)}
                                  />
                                </td>
                                <td className="w-20">
                                  <input
                                    type="number"
                                    className="form-control fw-medium fs-16px"
                                    placeholder="Quantity"
                                    name="qty"
                                    value={item.qty}
                                    onChange={e => handleItemChange(index, e)}
                                    min={0}
                                  />
                                </td>
                                <td className="w-20">
                                  <input
                                    type="number"
                                    className="form-control fw-medium fs-16px"
                                    placeholder="Unit Price"
                                    name="unit_price"
                                    value={item.unit_price}
                                    onChange={e => handleItemChange(index, e)}
                                    min={0}
                                  />
                                </td>
                                <td className="fw-medium fs-16px text-end w-15">
                                  {formatCurrency(item.qty * item.unit_price)}
                                </td>
                                <td className="text-end pe-0">
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      className="bg-transparent"
                                      onClick={() => handleRemoveItem(index)}
                                    >
                                      <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.43214 0.691406L5.14286 1.25H1.28571C0.574554 1.25 0 1.80859 0 2.5C0 3.19141 0.574554 3.75 1.28571 3.75H16.7143C17.4254 3.75 18 3.19141 18 2.5C18 1.80859 17.4254 1.25 16.7143 1.25H12.8571L12.5679 0.691406C12.3509 0.265625 11.9049 0 11.4188 0H6.58125C6.09509 0 5.64911 0.265625 5.43214 0.691406ZM16.7143 5H1.28571L2.1375 18.2422C2.20179 19.2305 3.04554 20 4.06205 20H13.9379C14.9545 20 15.7982 19.2305 15.8625 18.2422L16.7143 5Z"
                                          fill="#EC6969"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          <span className="text-danger d-block fw-400 fs-small mt-2">
                            {errors["itemError"] ? errors["itemError"] : null}
                          </span>
                        </tbody>
                      </table>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary-light mt-2"
                      onClick={handleAddItem}
                    >
                      + Add Item
                    </button>
                  </div>
                  <div className="row align-items-end flex-md-row flex-column-reverse">
                    <div className="col-xxl-8 col-xl-7 col-md-6">
                      <div className="d-flex gap-2 pt-3">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">
                          {loading ? "Saving..." : "Save"}
                        </button>
                        <button
                          onClick={e => navigate("/admin/invoices")}
                          type="button"
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-5 col-md-6">
                      <div className="mb-4">
                        <label className="form-label fw-medium mb-1 fs-16px">
                          Tax (In Percentage):
                        </label>
                        <input
                          type="number"
                          className="form-control fw-medium fs-16px"
                          placeholder="0"
                          name="tax"
                          value={invoiceValue.tax}
                          onChange={e => onChangeInput(e)}
                        />
                      </div>
                      <div className="total">
                        <div className="d-flex justify-content-between mb-3 fs-16px fw-medium">
                          <div>Sub Total:</div>
                          <div className="text-end">{formatCurrency(calculateTotal())}</div>
                        </div>
                        <div className="d-flex justify-content-between mb-3 fs-16px fw-medium">
                          <div>
                            Tax ({`${invoiceValue.tax === undefined ? 0 : invoiceValue.tax}%`}
                            ):
                          </div>
                          <div className="text-end">{formatCurrency(calculateTax())}</div>
                        </div>
                        <div className="d-flex justify-content-between fs-16px fw-semibold border-top pt-3">
                          <div>Total:</div>
                          <div className="text-end">
                            {formatCurrency(calculateTotal() + calculateTax())}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    </>
  );
};
export default EditInvoice;
