import { authActionType } from "../../constants";

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case authActionType.LOGIN_USER:
      return action.payload;
    case authActionType.RESET_PASSWORD:
      return action.payload;
    case authActionType.FORGOT_PASSWORD:
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
