import React, { useEffect, useState } from "react";

import MasterLayout from "../../components/MasterLayout";
import PageHeader from "../../components/PageHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { fetchSingleVendorLogs } from "../../store/action/admin/vendorLogsAction";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../components/tables/ListTable";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import { classNameAndStatus, getDataType, timeAgo } from "../../components/shared/sharedMethod";
import { exportLogs } from "../../store/action/excelExportAction";
import TopProgressBar from "../../components/shared/TopProgressBar";
import dashboardIcon from "../../assets/images/dashboard-icon.svg";
import moment from "moment";
import toast from "react-hot-toast";

const columns = [
  {
    id: 1,
    label: "Status Code",
    name: "status_code",
    customData: item => (
      <span className={item.status_code === 200 ? "successful-badge badge" : "failed-badge badge"}>
        {item.status_code}
      </span>
    ),
  },
  {
    id: 2,
    label: "Vendor Name",
    name: "full_name",
    className: "text-center",
    containerClass: "text-center",
  },
  {
    id: 3,
    label: "RC No.",
    name: "reg_no",
    className: "text-center",
    containerClass: "text-center",
  },
  {
    id: 4,
    label: "Status",
    name: "status",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      if (item.status) {
        const { className, text } = classNameAndStatus(item, true);
        return <span className={className}>{text}</span>;
      }
    },
  },
  {
    id: 5,
    label: "Data Type",
    name: "data_type",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      const dataTypeText = getDataType(item?.data_type);
      return dataTypeText;
    },
  },
  {
    id: 6,
    label: "Cost",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>₹{item.cost}</span>,
  },
  {
    id: 7,
    label: "Duration",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>{item.duration}ms</span>,
  },
  {
    id: 9,
    label: "Happened",
    className: "text-center",
    containerClass: "text-center",
    customData: item => (
      <span>
        <div className="mb-1">
          {timeAgo(item.created_at).formattedDate}
          {timeAgo(item.created_at).time}
        </div>
      </span>
    ),
  },
  {
    id: 8,
    label: "",
    className: "text-end",
    customData: item => (
      <Link
        to={`/admin/vendors-logs/${item.public_id}`}
        className="link-arrow ms-auto"
        target="_blank"
      >
        <BsChevronRight />
      </Link>
    ),
  },
];

const VendorLogs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleVendorLogs } = useSelector(state => state.vendorLogs);
  const { totalRecord } = useSelector(state => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const totalPages = Math.ceil(totalRecord / perPage);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDataTypeStatus, setSelectedDataTypeStatus] = useState(null);
  const vendorName = localStorage.getItem("vendorName");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showExportFieldModal, setShowExportFieldModal] = useState(false);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorName, setSelectedVendorName] = useState(null);

  const handleVendorSelect = vendor => {
    setSelectedVendor(vendor.value);
    setSelectedVendorName(vendor.label);
  };

  useEffect(() => {
    setSelectedVendor(localStorage.getItem("vendorId"));
    setSelectedVendorName(localStorage.getItem("vendorName"));
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedDate = queryParams.get("date");
  const parsedDate = moment(selectedDate, "DD-MM-YYYY");

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return parsedDate.isValid() ? parsedDate : startDate;
    })(),
    parsedDate.isValid() ? parsedDate : new Date(),
  ]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleDateSelect = dateRange => {
    setSelectedDates(dateRange);
  };

  const onDataTypeSelect = selectedOption => {
    setSelectedDataTypeStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const handleStatusSelect = selectedOption => {
    setSelectedStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const filter = {
    page: currentPage,
    limit: perPage,
    start_date: selectedDates && selectedDates[0] ? selectedDates[0] : "",
    end_date: selectedDates && selectedDates[1] ? selectedDates[1] : "",
    status: selectedStatus,
    data_type: selectedDataTypeStatus,
    search: searchTerm.trim(),
  };

  const fetchLogs = () => {
    dispatch(fetchSingleVendorLogs(filter, selectedVendor ? selectedVendor : id));
  };

  useEffect(() => {
    fetchLogs();
  }, [
    currentPage,
    perPage,
    selectedDates,
    selectedStatus,
    selectedDataTypeStatus,
    id,
    selectedVendor,
    searchTerm,
  ]);

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(currentPage > 1 ? currentPage : 1);
  };

  const handleNextPage = i => {
    setCurrentPage(i);
  };

  const handlePrevPage = i => {
    setCurrentPage(i);
  };

  const onClickExport = e => {
    e.preventDefault();
    if (selectedVendor === "all") {
      toast.error("Please select vendor");
    } else {
      setShowExportFieldModal(true);
    }
  };

  const handleLimitChange = selectedOption => {
    setPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const goToButtonHandle = () => {
    localStorage.removeItem("vendorName");
    localStorage.removeItem("vendorId");
    localStorage.setItem("vendorId", selectedVendor ? selectedVendor : id);
    localStorage.setItem("vendorName", selectedVendorName);
    navigate(`/admin/vendors/${selectedVendor ? selectedVendor : id}/dashboard`);
  };

  const handleExport = (e, payload) => {
    e.preventDefault();
    if (payload.length === 0) {
      toast.error("Please select fields to export");
    } else {
      const jsonPayload = { fields: payload };
      dispatch(
        exportLogs(
          selectedVendor ? selectedVendor : id,
          "vendor",
          filter,
          setLoading,
          true,
          jsonPayload,
        ),
      );
      setShowExportFieldModal(false);
    }
  };

  const handleClose = () => {
    setShowExportFieldModal(false);
  };

  return (
    <>
      <MasterLayout>
        <TopProgressBar isLoading={loading} />
        <TabTitle title={`${vendorName} Logs`} />
        <div className="log-page py-30px">
          <div className="container">
            <PageHeader
              title={`${vendorName} Logs`}
              isSelect
              isDatePicker
              isSelectionPart
              onStatusSelect={handleStatusSelect}
              onDateSelect={handleDateSelect}
              isBackButtonLast={selectedVendor === "all" ? false : true}
              backLink={"/admin/vendors"}
              isDataTypeSelect
              onDataTypeSelect={onDataTypeSelect}
              onLimitChange={handleLimitChange}
              paginationLimit={perPage}
              navigationButtonText={<img src={dashboardIcon} alt="log" />}
              navigationHandle={goToButtonHandle}
              isNavigationButton={selectedVendor === "all" ? false : true}
              handleTitleChange={handleVendorSelect}
              isTitleSelectable
              isPartnerOptions={false}
              selectedPartnerOption={{
                value: selectedVendor,
                label: selectedVendorName,
              }}
              selectedDates={selectedDates}
            />
            <ListTable
              handleExport={handleExport}
              handleClose={handleClose}
              showExportFieldModal={showExportFieldModal}
              loading={loading}
              isExport
              onClickExport={onClickExport}
              onSearch={handleSearch}
              currentPage={currentPage}
              totalRecord={totalRecord}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              setPerPage={setPerPage}
              perPage={perPage}
              isSearch
              isPagination
              columns={columns}
              data={singleVendorLogs}
              tableClass={"logs-table"}
              placeholder="Search by RC No."
              clickableRowPath={"/admin/vendors-logs"}
              isClickableRow
              isVendor
            />
          </div>
        </div>
      </MasterLayout>
    </>
  );
};
export default VendorLogs;
