import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/partners.css";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PartnerWidgets from "./PartnerWidgets";
import MasterLayout from "../../components/MasterLayout";
import StackedBarChart from "../../components/shared/chart/StackedBarChart";
import PageHeader from "../../components/PageHeader";
import {
  getDashboardWidgetDetailsAction,
  getDashboardTableStatisticsAction,
  getDashboardChartDataAction,
  getUsageWarning,
} from "../../store/action/partner/dashboardAction";
import Statistics from "../../admin/dashboard/Statistics";
import { formatChartLabels } from "../../components/shared/sharedMethod";
import WarningAlert from "../Warning";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { tableData, statisticsData, warning } = useSelector(state => state.partnerDashboard);
  const { chartData } = useSelector(state => state.partnerDashboardChart);

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return startDate;
    })(),
    new Date(),
  ]);

  const handleDateSelect = useCallback(dateRange => {
    setSelectedDates(dateRange);
  }, []);

  const fetchData = useCallback(() => {
    const filter = {
      start_date: selectedDates[0] ? selectedDates[0] : "",
      end_date: selectedDates[1] ? selectedDates[1] : "",
    };
    dispatch(getDashboardWidgetDetailsAction(filter));
    dispatch(getDashboardTableStatisticsAction(filter));
    dispatch(getDashboardChartDataAction(filter));
  }, [dispatch, selectedDates]);

  useEffect(() => {
    dispatch(getUsageWarning());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const numberOfCalls = useMemo(
    () => [
      {
        label: "Successful",
        backgroundColor: "#08A975",
        data: chartData?.partnerNumOfCalls?.datasets?.[0]?.data || [],
      },
      {
        label: "Failed",
        backgroundColor: "#EC6969",
        data: chartData?.partnerNumOfCalls?.datasets?.[2]?.data || [],
      },
    ],
    [chartData?.partnerNumOfCalls?.datasets],
  );

  const numberOfCallsByType = useMemo(
    () => [
      {
        label: "Live",
        backgroundColor: "#08A975",
        data: chartData?.partnerNumOfCallsByType?.datasets?.[0]?.data || [],
      },
      {
        label: "Cached",
        backgroundColor: "#FF9900",
        data: chartData?.partnerNumOfCallsByType?.datasets?.[1]?.data || [],
      },
      {
        label: "Lite",
        backgroundColor: "#1F77B4",
        data: chartData?.partnerNumOfCallsByType?.datasets?.[2]?.data || [],
      },
    ],
    [chartData?.partnerNumOfCallsByType?.datasets],
  );

  return (
    <MasterLayout>
      <TabTitle title={"Dashboard"} />
      <div className="dashboard-page py-30px">
        <div className="container">
          {warning && <WarningAlert message={warning} />}
          <PageHeader
            title={"Dashboard"}
            isSelectionPart
            isDatePicker
            onDateSelect={handleDateSelect}
            isTopPagination={false}
          />
          <PartnerWidgets data={tableData?.[0]} />
          <div className="chart-section">
            <div className="row">
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls</h2>
                  <StackedBarChart
                    id={1}
                    data={numberOfCalls}
                    labels={
                      chartData?.partnerNumOfCalls &&
                      formatChartLabels(chartData?.partnerNumOfCalls.labels)
                    }
                  />
                </div>
              </div>
              <div className="col-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls by Type</h2>
                  <StackedBarChart
                    id={2}
                    data={numberOfCallsByType}
                    labels={
                      chartData?.partnerNumOfCallsByType &&
                      formatChartLabels(chartData?.partnerNumOfCallsByType.labels)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Statistics
            customClass="partner-dashboard-statastics"
            style={{ maxHeight: "1260px", overflowY: "auto" }}
            statisticsFor={"other"}
            isPercentage
            name={"Source Data"}
            columns={[
              "No.",
              "Date",
              "No. of Calls",
              "Failed",
              "Successful",
              "Live",
              "Cached",
              "Lite",
            ]}
            isPartnerLoggedIn
            statisticsData={statisticsData}
            isStatisticsForPartner={true}
            navigationPath={"/partner/logs"}
            isLink
          />
        </div>
      </div>
    </MasterLayout>
  );
};

export default Dashboard;
