import { combineReducers } from "redux";

// Common Reducers
import authReducer from "./authReducer";
import totalRecordReducer from "./totalRecordReducer";
import profileReducer from "./profileReducer";

// Admin Reducers
import dashboardReducer from "./admin/dashboardReducer";
import partnerReducer from "./admin/partnerReducer";
import partnerLogsReducer from "./admin/partnerLogsReducer";
import vendorReducer from "./admin/vendorReducer";
import vendorLogsReducer from "./admin/vendorLogsReducer";
import settingReducer from "./admin/settingReducer";
import invoiceReducer from "./admin/invoiceReducer";
import vendorPartnerReducer from "./admin/vendorPartnerReducer";
import listingReducer from "./admin/listingReducer";
import dashboardChartReducer from "./admin/dashboardChartReducer";

// Partner Reducers
import partnerLogReducer from "./partner/logsReducer";
import apiKeyReducer from "./partner/apiKeyReducer";
import partnerDashboardReducer from "./partner/dashboardReducer";

// Partner Reducers
import vendorLogReducer from "./vendor/logsReducer";
import vendorDashboardReducer from "./vendor/dashboardReducer";
import partnerDashboardChartReducer from "./partner/partnerDashboardChartReducer";

export default combineReducers({
  loginUser: authReducer,
  partners: partnerReducer,
  totalRecord: totalRecordReducer,
  partnerLogs: partnerLogsReducer,
  vendors: vendorReducer,
  userProfile: profileReducer,
  vendorLogs: vendorLogsReducer,
  settings: settingReducer,
  dashboardDetails: dashboardReducer,
  partnerLog: partnerLogReducer,
  partnerAPIKey: apiKeyReducer,
  vendorLog: vendorLogReducer,
  vendorDashboard: vendorDashboardReducer,
  partnerDashboard: partnerDashboardReducer,
  partnerDashboardChart: partnerDashboardChartReducer,
  invoices: invoiceReducer,
  partnerListing: vendorPartnerReducer,
  listing: listingReducer,
  dashboardChart: dashboardChartReducer,
});
