import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";

import { localStorageConstant } from "./constants";
import { adminRoutes, partnerRoutes, vendorRoutes } from "./routes/index";
import { ProtectedRoute } from "./components/shared/sharedMethod";
import { fetchProfile } from "./store/action/profileAction";

const App = () => {
  const token = localStorage.getItem(localStorageConstant.TOKEN);
  const { loginUser } = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(fetchProfile());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (!loginUser || loginUser === undefined || loginUser === null) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Routes>
        {window.location.pathname.includes("/admin") && loginUser && loginUser.user_type === "1" ? (
          <>
            {adminRoutes.map(route => (
              <Route
                key={route.path}
                exact={true}
                path={route.path}
                element={<ProtectedRoute>{<route.Element />}</ProtectedRoute>}
              />
            ))}
          </>
        ) : loginUser && loginUser.user_type === "3" ? (
          <>
            {vendorRoutes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={true}
                element={<ProtectedRoute>{<route.Element />}</ProtectedRoute>}
              />
            ))}
          </>
        ) : (
          <>
            {partnerRoutes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={true}
                element={<ProtectedRoute>{<route.Element />}</ProtectedRoute>}
              />
            ))}
          </>
        )}
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={
                loginUser && loginUser.user_type
                  ? loginUser.user_type === "3"
                    ? "/vendor/dashboard"
                    : loginUser.user_type === "2"
                      ? "/partner/dashboard"
                      : "/admin/dashboard"
                  : "/login"
              }
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </>
  );
};

export default App;
