import dashboardIcon from "../assets/images/dashboardIcon.svg";
import partnersIcon from "../assets/images/partnersIcon.svg";
import vendorsIcon from "../assets/images/vendorsIcon.svg";
import logsIcon from "../assets/images/logsIcon.svg";
import settingsIcon from "../assets/images/settingsIcon.svg";
import invoicesIcon from "../assets/images/invoicesIcon.svg";
import dashboardActiveIcon from "../assets/images/dashboardActiveIcon.svg";
import partnersActiveIcon from "../assets/images/partnersActiveIcon.svg";
import vendorsActiveIcon from "../assets/images/vendorsActiveIcon.svg";
import logsActiveIcon from "../assets/images/logsActiveIcon.svg";
import settingsActiveIcon from "../assets/images/settingsActiveIcon.svg";
import invoicesActiveIcon from "../assets/images/invoicesActiveIcon.svg";

export const adminConfig = [
  {
    title: "Dashboard",
    fontIcon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    to: "/admin/dashboard",
  },
  {
    title: "Partners",
    fontIcon: partnersIcon,
    activeIcon: partnersActiveIcon,
    to: "/admin/partners",
    submenu: [
      "/admin/partners/:id/dashboard",
      "/admin/partners/:id/create",
      "/admin/partners/:id/edit",
      "/admin/partners/:id/logs",
    ],
  },
  {
    title: "Partner Logs",
    fontIcon: logsIcon,
    activeIcon: logsActiveIcon,
    to: "/admin/partners-logs",
    submenu: ["/admin/partners-logs"],
  },
  {
    title: "Vendors",
    fontIcon: vendorsIcon,
    activeIcon: vendorsActiveIcon,
    to: "/admin/vendors",
    submenu: ["/admin/vendors/:id/dashboard", "/admin/vendors/:id/logs", "/admin/vendors/:id/edit"],
  },
  {
    title: "Vendor Logs",
    fontIcon: logsIcon,
    activeIcon: logsActiveIcon,
    to: "/admin/vendors-logs",
  },
  {
    title: "Invoices",
    fontIcon: invoicesIcon,
    activeIcon: invoicesActiveIcon,
    to: "/admin/invoices",
  },
  {
    title: "Settings",
    fontIcon: settingsIcon,
    activeIcon: settingsActiveIcon,
    to: "/admin/settings",
  },
];

export const partnerConfig = [
  {
    title: "Dashboard",
    fontIcon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    to: "/partner/dashboard",
  },
  {
    title: "Logs",
    fontIcon: logsIcon,
    activeIcon: logsActiveIcon,
    to: "/partner/logs",
    submenu: ["/partner/logs/:id"],
  },
  {
    title: "Invoices",
    fontIcon: invoicesIcon,
    activeIcon: invoicesActiveIcon,
    to: "/partner/invoices",
  },
];

export const vendorConfig = [
  {
    title: "Dashboard",
    fontIcon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    to: "/vendor/dashboard",
  },
  {
    title: "Logs",
    fontIcon: logsIcon,
    activeIcon: logsActiveIcon,
    to: "/vendor/logs",
    submenu: ["/vendor/logs/:id"],
  },
  {
    title: "Invoices",
    fontIcon: invoicesIcon,
    activeIcon: invoicesActiveIcon,
    to: "/vendor/invoices",
  },
];
