import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import Tabs from "../../components/tab/Tab";
import { fetchPartnerLogDetails } from "../../store/action/admin/partnerLogsAction";
import PageHeader from "../../components/PageHeader";
import moment from "moment";
import { getDataType, getStatusClass, getStatusText } from "../../components/shared/sharedMethod";

const PartnerLogsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const partnerLogs = useSelector(state => state.partnerLogs);
  useEffect(() => {
    dispatch(fetchPartnerLogDetails(id, navigate));
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    window.close();
  };

  function getPartialReasonText(log) {
    if (!log?.partial_reason) return null;

    const message = status => {
      if (status === 2) {
        return log.partial_reason;
      } else if (status === 3) {
        return (
          log?.partial_reason?.response_message ||
          log.partial_reason.errors?.message ||
          log.partial_reason?.message
        );
      } else {
        return null;
      }
    };

    const reasonText = message(Number(log.status));

    return reasonText ? (
      <>
        <p className="mb-1 text-gray-600 fs-16px">
          {Number(log.status) === 3 ? "Failed" : "Partial"} Reason:
        </p>
        <h4 className="mb-0 fw-medium fs-16px">{reasonText}</h4>
      </>
    ) : null;
  }

  return (
    <>
      {/* <MasterLayout> */}
      {/* <TabTitle title={"Partner Log Details"} /> */}
      <TabTitle title={`${partnerLogs.reg_no} | ${partnerLogs?.full_name}`} />
      <div className="log-page log-details-page py-30px">
        <div className="container">
          <PageHeader
            isBackButton
            backLink={"/admin/partners-logs"}
            title={"Partner Log Details"}
            handleBack={handleBack}
          />
          <div className="mb-4 pb-1">
            <div className="bg-white wrapper p-4">
              <div className="row">
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Time:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {moment(partnerLogs.created_at).format("YYYY-MM-DD H:mm:ss")}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Duration:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLogs.duration} ms</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Partner Name:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLogs.full_name}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status Code:</p>
                  <span
                    className={`badge ${
                      partnerLogs.status_code === 200 ? "successful-badge" : "failed-badge"
                    }`}
                  >
                    {partnerLogs.status_code}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status:</p>
                  <span className={`badge ${getStatusClass(partnerLogs.status)}`}>
                    {getStatusText(partnerLogs.status)}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">RC No.:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLogs.reg_no}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Request Type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{getDataType(partnerLogs?.data_type)}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Response Type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {getDataType(partnerLogs?.response_type)}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Source IP Address:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLogs.source_ip}</h4>
                </div>

                <div className="col-lg-4 col-6 mb-lg-4 ">
                  <p className="mb-1 text-gray-600 fs-16px">Cost:</p>
                  <h4 className="mb-0 fw-medium fs-16px">₹{partnerLogs.cost}</h4>
                </div>
                <div className="col-lg-4 col-6">
                  <p className="mb-1 text-gray-600 fs-16px">Revenue:</p>
                  <h4 className="mb-0 fw-medium fs-16px">₹{partnerLogs.revenue}</h4>
                </div>
              </div>
            </div>
          </div>

          {partnerLogs.vendor_logs?.map((log, index) => {
            return (
              <div className="mb-4 pb-1" key={index}>
                <div className="bg-white wrapper p-4">
                  <h5 className="fw-semibold fs-18px mb-3">Vendor Log {index + 1}</h5>
                  <div className="row">
                    <div className="col-lg-2 col-6 mb-lg-0 mb-4">
                      <p className="mb-1 text-gray-600 fs-16px">Name:</p>
                      <h4 className="mb-0 fw-medium fs-16px">{log.vendor_name}</h4>
                    </div>
                    <div className="col-lg-2 col-6 mb-lg-0 mb-4">
                      <p className="mb-1 text-gray-600 fs-16px">Vendor Log:</p>
                      <Link
                        to={`/admin/vendors-logs/${log.vendor_log_id}`}
                        target="_blank"
                        className="mb-0 fw-medium fs-16px text-primary"
                      >
                        Go To Vendor Log
                      </Link>
                    </div>
                    <div className="col-lg-2 col-6">
                      <p className="mb-1 text-gray-600 fs-16px">Cost:</p>
                      <h4 className="mb-0 fw-medium fs-16px">₹{log.vendor_cost}</h4>
                    </div>
                    <div className="col-lg-2 col-6">
                      <p className="mb-1 text-gray-600 fs-16px">Status:</p>
                      <span className={`badge ${getStatusClass(log.status)}`}>
                        {getStatusText(log.status, true)}
                      </span>
                    </div>
                    {log?.partial_reason && (
                      <div className="col-lg-4 col-6">
                        <h4 className="mb-0 fw-medium fs-16px">{getPartialReasonText(log)}</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <Tabs
            headerDetails={partnerLogs.headers ? JSON.stringify(partnerLogs.headers) : null}
            requestDetails={partnerLogs.request ? JSON.stringify(partnerLogs.request) : null}
            responseDetails={partnerLogs.response ? JSON.stringify(partnerLogs.response) : null}
          />
        </div>
      </div>
      {/* </MasterLayout> */}
    </>
  );
};
export default PartnerLogsDetails;
