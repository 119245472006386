import { Navigate } from "react-router-dom";
import { localStorageConstant } from "../../constants";
import moment from "moment";

export const ProtectedRoute = props => {
  const { children } = props;
  const token = localStorage.getItem(localStorageConstant.TOKEN);
  if (!token || token === null) {
    return <Navigate to="/login" replace={true} />;
  } else {
    return children;
  }
};

export const formatChartLabels = labels => {
  return labels && labels.map(label => moment(label).format("D MMM"));
};

export const timeAgo = created_at => {
  // const date = new Date(created_at);
  // const now = new Date();
  // const diff = now - date;
  // const minutes = Math.floor(diff / 60000);
  // const hours = Math.floor(minutes / 60);
  // const days = Math.floor(hours / 24);

  const date = moment(created_at);
  const formattedDate = date.format("DD/MM/YYYY");
  const time = date.format(" hh:mm:ss A");

  return { formattedDate, time };
  // if (days > 0) {
  //   return `${days}d ago`;
  // } else if (hours > 0) {
  //   return `${hours}h ago`;
  // } else {
  //   return `${minutes}m ago`;
  // }
};

export const getStatusText = (status, isVendor = false) => {
  switch (parseInt(status)) {
    case 1:
      return "Successful";
    case 2:
      return isVendor ? "Insufficient" : "Lite";
    case 3:
      return "Failed";
    default:
      return "";
  }
};

export const getStatusClass = status => {
  switch (parseInt(status)) {
    case 1:
      return "successful-badge";
    case 2:
      return "partial-badge";
    case 3:
      return "failed-badge";
    default:
      return "";
  }
};

export const classNameAndStatus = (item, isVendor = false) => {
  const statusMap = {
    1: { className: "successful-badge badge", text: "Successful" },
    2: {
      className: "partial-badge badge",
      text: isVendor ? "Insufficient" : "Lite",
    },
    3: { className: "failed-badge badge", text: "Failed" },
  };
  const { className, text } = statusMap[parseInt(item.status)] || statusMap[0];

  return { className, text };
};

export const getDataType = data_type => {
  let dataTypeText = "";
  switch (parseInt(data_type)) {
    case 1:
      dataTypeText = "Live";
      break;
    case 2:
      dataTypeText = "Cached";
      break;
    case 3:
      dataTypeText = "Lite";
      break;
    case 4:
      dataTypeText = "Mixed";
      break;
    default:
      dataTypeText = "-";
  }
  return dataTypeText;
};

export const formatCurrency = amount => {
  const parts = String(amount).split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  let formattedAmount = "";

  for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
    if (j > 0 && j % 3 === 0) {
      formattedAmount = "," + formattedAmount;
    }
    formattedAmount = integerPart[i] + formattedAmount;
  }

  return `₹${formattedAmount}${decimalPart}`;
};
