import { partnerAction } from "../../../constants";

const logsReducer = (state = [], action) => {
  switch (action.type) {
    case partnerAction.FETCH_LOGS:
      return action.payload;
    case partnerAction.FETCH_LOG_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default logsReducer;
