import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "../../assets/css/sidebar.css";
import { adminConfig, partnerConfig, vendorConfig } from "../../config/asideConfig";
import { useSelector } from "react-redux";

const CustomSidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const location = useLocation();
  const { loginUser } = useSelector(state => state);

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const closeMenuClick = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      const sidebar = document.querySelector(".sidebar");
      if (
        sidebar.classList.contains("ps-collapsed") &&
        !event.target.classList.contains("close-menu") &&
        !sidebar.contains(event.target) &&
        window.innerWidth < 992
      ) {
        setMenuCollapse(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const isActive = menuItem => {
    if (
      menuItem.to === location.pathname ||
      (menuItem.submenu &&
        menuItem.submenu.some(subRoute => location.pathname.startsWith(subRoute.trim())))
    ) {
      return menuItem.activeIcon;
    } else if (
      menuItem.to.split("/").length === 3 &&
      menuItem.to !== "/" &&
      location.pathname.startsWith(menuItem.to.trim() + "/")
    ) {
      return menuItem.activeIcon;
    } else {
      return menuItem.fontIcon;
    }
  };

  const renderMenuItems = () => {
    if (loginUser) {
      switch (loginUser.user_type) {
        case "1":
          return adminConfig.map((menuItem, index) => (
            <MenuItem key={index}>
              <NavLink
                to={menuItem.to}
                activeclassname="active"
                isActive={(match, location) => isActive(menuItem) === menuItem.activeIcon}
              >
                <img alt="sidebarIcon" src={isActive(menuItem)} />
                <span>{menuItem.title}</span>
              </NavLink>
            </MenuItem>
          ));
        case "2":
          return partnerConfig.map((menuItem, index) => (
            <MenuItem key={index}>
              <NavLink
                to={menuItem.to}
                activeclassname="active"
                isActive={(match, location) => isActive(menuItem) === menuItem.activeIcon}
              >
                <img alt="sidebarIcon" src={isActive(menuItem)} />
                <span>{menuItem.title}</span>
              </NavLink>
            </MenuItem>
          ));
        case "3":
          return vendorConfig.map((menuItem, index) => (
            <MenuItem key={index}>
              <NavLink
                to={menuItem.to}
                activeclassname="active"
                isActive={(match, location) => isActive(menuItem) === menuItem.activeIcon}
              >
                <img alt="sidebarIcon" src={isActive(menuItem)} />
                <span>{menuItem.title}</span>
              </NavLink>
            </MenuItem>
          ));
        default:
          return null;
      }
    }
    return null;
  };
  return (
    <>
      <Sidebar className={`sidebar ${menuCollapse ? "ps-collapsed" : ""}`} onClick={closeMenuClick}>
        <div className="sidenav-header d-flex justify-content-between align-items-center">
          <Link to={"#"} className="navbar-brand p-0 m-0">
            <img src={logo} alt="Logo" className="h-100" />
          </Link>
          <div className="close-menu d-lg-block d-none" onClick={menuIconClick}>
            <svg
              width="20"
              height="16"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8511 2.33338H1.14894C0.514647 2.33338 0 1.81078 0 1.16669C0 0.5226 0.514647 0 1.14894 0H16.8511C17.4854 0 18 0.5226 18 1.16669C18 1.81078 17.4854 2.33338 16.8511 2.33338Z"
                fill="#6C757D"
              />
              <path
                d="M16.8511 8.16683H1.14894C0.514647 8.16683 0 7.64423 0 7.00014C0 6.35605 0.514647 5.83345 1.14894 5.83345H16.8511C17.4854 5.83345 18 6.35605 18 7.00014C18 7.64423 17.4854 8.16683 16.8511 8.16683Z"
                fill="#6C757D"
              />
              <path
                d="M12.2553 14.0003H1.14894C0.514647 14.0003 0 13.4777 0 12.8336C0 12.1895 0.514647 11.6669 1.14894 11.6669H12.2553C12.8896 11.6669 13.4043 12.1895 13.4043 12.8336C13.4043 13.4777 12.8896 14.0003 12.2553 14.0003Z"
                fill="#6C757D"
              />
            </svg>
          </div>
        </div>
        <Menu iconShape="square">{renderMenuItems()}</Menu>
      </Sidebar>
      <div className="close-menu d-lg-none d-block" onClick={menuIconClick}>
        <svg
          className="pe-none"
          width="20"
          height="16"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8511 2.33338H1.14894C0.514647 2.33338 0 1.81078 0 1.16669C0 0.5226 0.514647 0 1.14894 0H16.8511C17.4854 0 18 0.5226 18 1.16669C18 1.81078 17.4854 2.33338 16.8511 2.33338Z"
            fill="#6C757D"
          />
          <path
            d="M16.8511 8.16683H1.14894C0.514647 8.16683 0 7.64423 0 7.00014C0 6.35605 0.514647 5.83345 1.14894 5.83345H16.8511C17.4854 5.83345 18 6.35605 18 7.00014C18 7.64423 17.4854 8.16683 16.8511 8.16683Z"
            fill="#6C757D"
          />
          <path
            d="M12.2553 14.0003H1.14894C0.514647 14.0003 0 13.4777 0 12.8336C0 12.1895 0.514647 11.6669 1.14894 11.6669H12.2553C12.8896 11.6669 13.4043 12.1895 13.4043 12.8336C13.4043 13.4777 12.8896 14.0003 12.2553 14.0003Z"
            fill="#6C757D"
          />
        </svg>
      </div>
      <div className="bg-overlay" onClick={closeMenuClick}></div>
    </>
  );
};
export default CustomSidebar;
