import React from "react";
import scooter from "../../assets/images/scooter.png";
import taxi from "../../assets/images/taxi.png";
import leftBg from "../../assets/images/left-bg-shape.png";
import rightBg from "../../assets/images/right-bg-shape.png";

const Footer = () => {
  return (
    <>
      <div className="scooter-img">
        <img src={scooter} alt="scooter" className="img-fluid" />
      </div>
      <div className="taxi-img">
        <img src={taxi} alt="taxi" className="img-fluid" />
      </div>
      <div className="left-img">
        <img src={leftBg} alt="left-bg" className="img-fluid" />
      </div>
      <div className="right-img">
        <img src={rightBg} alt="right-bg" className="img-fluid" />
      </div>
    </>
  );
};

export default Footer;
