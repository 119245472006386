import React, { useEffect } from "react";
import SimpleModal from "./SimpleModal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIKey } from "../../store/action/partner/apiKeyAction";

const ApiKeyModal = ({
  show,
  handleClose,
  apiKey,
  isEdit,
  name,
  isEditName,
  isRegenerate,
  isPartner,
  onClickRegenerate,
}) => {
  const copyApiKeyToClipboard = () => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        toast.success("API Key copied successfully");
      })
      .catch(error => {
        toast.error("Failed to copy API key:", error);
      });
  };

  const { partnerAPIKey } = useSelector(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPartner) {
      dispatch(fetchAPIKey());
    }
  }, [isPartner, dispatch]);

  return (
    <>
      <SimpleModal show={show} isSubmit={false} handleClose={handleClose} isShowContent={true}>
        <div className="text-center py-sm-3 py-2 my-sm-1">
          <h5 className="fs-22px fw-semibold mb-sm-2 mb-0">{isEdit ? isEditName : name}</h5>
          <div className="max-w-380px d-flex flex-sm-row flex-column align-items-sm-center justify-content-sm-center justify-content-start gap-sm-3 gap-1 py-4 mx-auto">
            <label className="form-label fw-medium mb-0 fs-16px text-start whitespace-nowrap">
              API Key:
            </label>
            <div className="position-relative flex-1">
              <input
                type="text"
                className="form-control text-primary fw-semibold fs-16px bg-light pe-5"
                value={apiKey ? apiKey : partnerAPIKey.data}
                readOnly
                placeholder="f87qa••••••••••••"
              />
              <button
                onClick={copyApiKeyToClipboard}
                className="border-0 bg-transparent position-absolute top-0 right-0 me-3 h-100"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M8.35714 0H13.3433C13.8536 0 14.3438 0.199219 14.7054 0.550781L17.4335 3.20312C17.7951 3.55469 18 4.03125 18 4.52734V13.125C18 14.1602 17.1362 15 16.0714 15H8.35714C7.29241 15 6.42857 14.1602 6.42857 13.125V1.875C6.42857 0.839844 7.29241 0 8.35714 0ZM1.92857 5H5.14286V7.5H2.57143V17.5H10.2857V16.25H12.8571V18.125C12.8571 19.1602 11.9933 20 10.9286 20H1.92857C0.863839 20 0 19.1602 0 18.125V6.875C0 5.83984 0.863839 5 1.92857 5Z"
                    fill="#A9B2BD"
                  />
                </svg>
              </button>
            </div>
          </div>
          <p className="fs-14px text-gray-600 ">
            Save this API Key securely. You will not able to see this API key again in the future.
            For security reasons, we do not save plain API keys on our servers.
          </p>
          <div className="d-flex gap-2 justify-content-center pt-3 pt-2 gap-2">
            {isRegenerate && !isEdit && (
              <button className="btn btn-primary m-0" onClick={onClickRegenerate}>
                Regenerate
              </button>
            )}
            <button
              className={isRegenerate ? "btn btn-secondary m-0" : "btn btn-primary m-0"}
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default ApiKeyModal;
