import React from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const renderToolTipTag = (id, message) => (
  <span style={{ cursor: "pointer" }}>
    <FontAwesomeIcon id={id} data-tooltip-content={message} icon={faQuestionCircle} />
  </span>
);

export const Widget = props => {
  const {
    totalNumber,
    customClass = "",
    name,
    image,
    total1,
    number1,
    total2,
    number2,
    total3,
    number3,
    total1Class,
    total2Class,
    total3Class,
    isRupee,
    tooltip = false,
    tooltipMessage,
    tooltipMessage1,
    tooltipMessage2,
    tooltipMessage3,
    tooltipIdTotal,
    tooltipId1,
    tooltipId2,
    tooltipId3,
    isVendor = false,
    isPercentage = false,
    number1Percentage,
    number2Percentage,
    number3Percentage,
    totalNumberPercentage,
  } = props;

  return (
    <>
      <div className={`widget ${customClass}`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <p className="mb-1 text-gray-600 fw-medium">
              {name} {tooltip && renderToolTipTag(tooltipIdTotal, tooltipMessage)}
            </p>
            <h4 className="mb0 fw-bold">
              {isRupee ? `₹${totalNumber}` : totalNumber}{" "}
              <span style={{ fontWeight: 400, fontSize: "16px" }}>
                {totalNumberPercentage ? `(${totalNumberPercentage}%)` : ""}
              </span>
            </h4>
          </div>
          <div className="widget-img d-flex justify-content-center align-items-center">
            <img src={image} alt={name} className="img-fluid" />
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="flex-1 w-50">
            <div className={`total-numbers ${total1Class}`}>
              <p className="mb-1 text-gray-600 fs-14px">
                {total1} {tooltip && renderToolTipTag(tooltipId1, tooltipMessage1)}
              </p>
              <h4 className="mb-0 fw-semibold fs-16px">
                {isRupee && number1 ? `₹${number1}` : `${number1 ? number1 : ""}`}
              </h4>
              {isPercentage ? `(${number1Percentage}%)` : ""}
            </div>
          </div>
          <div className="flex-1 w-50">
            <div className={`total-numbers ${total2Class}`}>
              <p className="mb-1 text-gray-600 fs-14px">
                {total2} {tooltip && renderToolTipTag(tooltipId2, tooltipMessage2)}
              </p>
              <h4 className="mb-0 fw-semibold fs-16px">
                {isRupee && number2 ? `₹${number2}` : `${number2 ? number2 : ""} `}
              </h4>
              {isPercentage ? `(${number2Percentage}%)` : ""}
            </div>
          </div>
          {total3 && !isVendor && (
            <div className="flex-1 w-50">
              <div className={`total-numbers ${total3Class}`}>
                <p className="mb-1 text-gray-600 fs-14px">
                  {total3} {tooltip && renderToolTipTag(tooltipId3, tooltipMessage3)}
                </p>
                <h4 className="mb-0 fw-semibold fs-16px">
                  {isRupee && number3 ? `₹${number3}` : `${number3 ? number3 : ""} `}
                </h4>
                {isPercentage ? `(${number3Percentage}%)` : ""}
              </div>
            </div>
          )}
        </div>
      </div>
      {tooltip && (
        <>
          <Tooltip
            style={{ maxWidth: "25%", zIndex: 2 }}
            anchorId={tooltipIdTotal}
            place="top"
            effect="solid"
          />
          <Tooltip
            style={{ maxWidth: "25%", zIndex: 2 }}
            anchorId={tooltipId1}
            place="top"
            effect="solid"
          />
          <Tooltip
            style={{ maxWidth: "25%", zIndex: 2 }}
            anchorId={tooltipId2}
            place="top"
            effect="solid"
          />
          <Tooltip
            style={{ maxWidth: "25%", zIndex: 2 }}
            anchorId={tooltipId3}
            place="top"
            effect="solid"
          />
        </>
      )}
    </>
  );
};

export const WidgetVendor = ({ totalNumber, customClass, name, image }) => (
  <div className={`widget ${customClass}`}>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div>
        <p className="mb-1 text-gray-600 fw-medium">{name}</p>
        <h4 className="mb0 fw-bold">₹{totalNumber}</h4>
      </div>
      <div className="widget-img d-flex justify-content-center align-items-center">
        <img src={image} alt={name} className="img-fluid" />
      </div>
    </div>
  </div>
);
