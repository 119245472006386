import apiConfig from "../../../config/apiConfig";
import { adminBaseURL, adminActionType, partnerAPIURL } from "../../../constants";
import requestParam from "../../../components/shared/requestParam";
import { setTotalRecord } from "../totalRecordAction";
import _ from "lodash";
import toast from "react-hot-toast";

export const fetchPartners =
  (filter = {}) =>
  async dispatch => {
    let url = adminBaseURL.PARTNERS;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_PARTNERS,
          payload: response.data.data,
        });
        dispatch(
          setTotalRecord(
            response.data.total !== undefined && response.data.total >= 0
              ? response.data.total
              : response.data.total,
          ),
        );
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const updatePartnerStatus =
  (public_id, status, isVendor = false) =>
  async dispatch => {
    let url = `${adminBaseURL.PARTNERS}/${public_id}/update-status`;
    if (isVendor) {
      url += "?isVendor=true";
    }

    apiConfig
      .post(url, status)
      .then(response => {
        return response;
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchPartner = id => async dispatch => {
  apiConfig
    .get(`${adminBaseURL.PARTNERS}/${id}`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_PARTNER,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const createPartner =
  (partner, setShowAPIModal, setAPIKey, setLoading) => async dispatch => {
    await apiConfig
      .post(adminBaseURL.PARTNERS, partner)
      .then(response => {
        dispatch({
          type: adminActionType.ADD_PARTNER,
          payload: response.data.data,
        });
        setAPIKey(response.data.data.apiKey);
        setShowAPIModal(true);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        toast.error(response.data.message);
      });
  };

export const editPartner = (id, partner, navigate, setLoading) => async dispatch => {
  await apiConfig
    .post(`${adminBaseURL.PARTNERS}/${id}`, partner)
    .then(response => {
      dispatch({
        type: adminActionType.EDIT_PARTNER,
        payload: response.data,
      });
      toast.success("Partner updated successfully");
      dispatch(fetchPartners());
      navigate("/admin/partners");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};

export const regeneratePartnerAPIKey =
  (id, setRegenerateAPI, setAPIKey, setShowAPIModal, isPartnerLoggedIn, setShowRegenerateModal) =>
  dispatch => {
    let url;
    if (isPartnerLoggedIn) {
      url = `${partnerAPIURL.API_KEY}`;
    } else {
      url = `${adminBaseURL.PARTNERS}/${id}/generate-api-key`;
    }
    apiConfig
      .post(url)
      .then(response => {
        setRegenerateAPI(false);
        setAPIKey(response.data.data);
        if (isPartnerLoggedIn) {
          setShowAPIModal(false);
          setShowRegenerateModal(true);
        } else {
          setShowAPIModal(true);
        }
        toast.success("API key regenerated successfully");
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchPartnerDashboardWidget =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.PARTNERS}/statatics/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_PARTNER_DASHBOARD_WIDGET,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchPartnerDashboardSource =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.PARTNERS}/source/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_PARTNER_DASHBOARD_SOURCE,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchPartnerDashboardChart =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.PARTNERS}/number-of-calls/chart/${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_PARTNER_DASHBOARD_CHART,
          payload: response.data.data,
        });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchSinglePartnerLogs =
  (filter = {}, id) =>
  async dispatch => {
    let url = `${adminBaseURL.PARTNER_LOGS}?partner_id=${id}`;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }

    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_SINGLE_PARTNER_LOGS,
          payload: response.data.data,
        });
        dispatch(
          setTotalRecord(
            response.data.total !== undefined && response.data.total >= 0
              ? response.data.total
              : response.data.total,
          ),
        );
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const editPriority = (id, partner, navigate) => dispatch => {
  apiConfig
    .post(`${adminBaseURL.PARTNERS}/${id}/set-priority`, partner)
    .then(response => {
      dispatch({
        type: adminActionType.EDIT_PARTNER,
        payload: response.data,
      });
      toast.success("Partner priority updated successfully");
      navigate("/admin/partners");
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const fetchPriority = id => dispatch => {
  apiConfig
    .get(`${adminBaseURL.PARTNERS}/${id}/priorities`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_PRIORITIES,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const fetchAPIHistory = id => dispatch => {
  apiConfig
    .get(`${adminBaseURL.PARTNERS}/${id}/api-key-history`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_API_HISTORY,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};
