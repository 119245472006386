import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import profile from "../../assets/images/modal-profile.png";
import SimpleModal from "../modals/SimpleModal";
import { editProfile } from "../../store/action/profileAction";
import { environment } from "../../config/environment";

const EditProfile = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.userProfile);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = e => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  // const removeImage = () => {
  //   setImageFile(null);
  // };

  const [updateUserProfile, setUpdateUserProfile] = useState({
    full_name: userProfile.full_name ? userProfile.full_name : "",
    email: userProfile.email ? userProfile.email : "",
  });

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
  });

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!updateUserProfile.full_name.trim()) {
      errorss.full_name = "Please enter full name";
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUpdateUserProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const onSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("full_name", updateUserProfile.full_name);
      if (imageFile) {
        formData.append("image", imageFile);
      }
      dispatch(editProfile(formData, handleClose, setLoading));
    }
  };

  let imageSource;
  if (imageFile) {
    imageSource = URL.createObjectURL(imageFile);
  } else if (userProfile.image) {
    imageSource = `${environment.API_URL}/api/image/${userProfile.image}`;
  } else {
    imageSource = profile;
  }

  return (
    <>
      <SimpleModal
        show={show}
        handleSubmit={onSubmit}
        handleClose={handleClose}
        title={"Edit Profile"}
        loading={loading}
      >
        <div className="d-flex flex-sm-row flex-column gap-sm-5 gap-4">
          <div className="text-center mx-auto">
            <div className="modal-profile-img position-relative">
              <div className="profile-img">
                <img src={imageSource} alt="profile" className="h-100 w-100 object-fit-cover" />
              </div>
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => handleImageUpload(e)}
                />
                <label htmlFor="imageUpload"></label>
              </div>
            </div>
            {/* {imageFile || userProfile.image ? ( // Only show remove button if an image is selected or exists
              <button className="btn remove-img-btn mt-3" onClick={removeImage}>
                Remove Image
              </button>
            ) : null} */}
          </div>
          <div className="flex-1">
            <div className="mb-3">
              <label className="form-label fw-medium mb-1 fs-16px">Name:</label>
              <span className="text-danger fs-16px">*</span>
              <input
                type="text"
                className="form-control fw-medium fs-16px"
                name="full_name"
                placeholder="Full name"
                value={updateUserProfile.full_name}
                onChange={handleInputChange}
              />
              <span className="text-danger d-block fw-400 fs-small mt-2">{errors.full_name}</span>
            </div>
            <div className="mb-3">
              <label className="form-label fw-medium mb-1 fs-16px">Email:</label>
              <input
                type="email"
                className="form-control fw-medium fs-16px  bg-gray-100"
                name="email"
                placeholder="Email"
                value={updateUserProfile.email}
                onChange={handleInputChange}
                readOnly
              />
              <span className="text-danger d-block fw-400 fs-small mt-2">{errors.email}</span>
            </div>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default EditProfile;
