import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import * as EmailValidator from "email-validator";
import { useDispatch } from "react-redux";

import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import TabTitle from "../shared/tabTitle/TabTitle";
import { localStorageConstant } from "../../constants";
import { forgotPassword } from "../../store/action/authAction";
import Footer from "../common/Footer";

const ForgotPassword = () => {
  const token = localStorage.getItem(localStorageConstant.TOKEN);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const history = createBrowserHistory();

  const onSubmit = e => {
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      dispatch(forgotPassword({ email }, navigate, setLoading));
    }
  };

  useEffect(() => {
    if (token) {
      history.push(window.location.pathname);
    }
  }, [dispatch, history, token]);

  const handleValidation = () => {
    let isValid = false;
    if (!email) {
      setError("Please enter an email");
    } else if (!EmailValidator.validate(email)) {
      setError("Please enter valid email address");
    } else {
      isValid = true;
      setError("");
    }
    return isValid;
  };

  return (
    <>
      <TabTitle title={"Forgot Password"} />
      <div className="login-page h-100 d-flex flex-column justify-content-center">
        <div className="container">
          <div className="company-logo text-center mb-80px">
            <img src={logo} alt="Logo" className="h-100" />
          </div>
          <div className="login-form bg-white  mx-auto">
            <div className="text-center mb-60px">
              <h3 className="fw-bold mb-1">Forgot Password?</h3>
              <p className="text-gray-600 fs-16px">Enter your email to get reset password link</p>
            </div>
            <form>
              <div>
                <div className="mb-3">
                  <label className="form-label fw-medium mb-1 fs-16px">Email:</label>
                  <span className="text-danger fs-16px">*</span>
                  <input
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    className="form-control fw-medium fs-16px"
                    autoComplete="off"
                  />
                  <span className="text-danger d-block fw-400 fs-small mt-2">
                    {error ? error : null}
                  </span>
                </div>
                <button type="submit" onClick={e => onSubmit(e)} className="btn btn-primary w-100">
                  {loading ? "Loading..." : "Submit"}
                </button>
                <Link
                  to="/login"
                  className="text-center text-black d-block fw-medium mt-3 mx-auto w-100"
                >
                  <span className="me-2">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.0383 5.85419C14.9729 5.84335 14.9066 5.83835 14.8403 5.83923H3.55262L3.79875 5.72242C4.03934 5.60624 4.25821 5.44812 4.44556 5.25522L7.61091 2.02567C8.02779 1.61964 8.09784 0.966469 7.7769 0.478059C7.40338 -0.0423995 6.68706 -0.155404 6.17691 0.225695C6.1357 0.256502 6.09653 0.290082 6.05972 0.326217L0.335755 6.16627C-0.111572 6.62216 -0.111966 7.36169 0.334861 7.81809C0.335147 7.81838 0.335469 7.81871 0.335755 7.819L6.05972 13.6591C6.5074 14.1145 7.23223 14.1135 7.6787 13.6568C7.71383 13.6208 7.74664 13.5826 7.7769 13.5423C8.09784 13.0538 8.02779 12.4007 7.61091 11.9946L4.45128 8.75925C4.28332 8.5877 4.09021 8.44385 3.87889 8.33293L3.53545 8.17525H14.7773C15.3621 8.1974 15.8752 7.7806 15.9851 7.19412C16.0863 6.55737 15.6624 5.95749 15.0383 5.85419Z"
                        fill="#456DFF"
                      />
                    </svg>
                  </span>
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
