import React, { useState, useEffect, useCallback } from "react";
import "../../assets/css/partners.css";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import VendorWidgets from "./VendorWidgets";
import MasterLayout from "../../components/MasterLayout";
import StackedBarChart from "../../components/shared/chart/StackedBarChart";
import PageHeader from "../../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardTableStatisticsAction,
  getDashboardWidgetDetailsAction,
  getDashboardChartDataAction,
} from "../../store/action/vendor/dashboardAction";
import Statistics from "../../admin/dashboard/Statistics";
import { formatChartLabels } from "../../components/shared/sharedMethod";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { tableData, statisticsData, chartData } = useSelector(state => state.vendorDashboard);

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return startDate;
    })(),
    new Date(),
  ]);

  const handleDateSelect = useCallback(dateRange => {
    setSelectedDates(dateRange);
  }, []);

  const fetchData = useCallback(() => {
    const filter = {
      start_date: selectedDates && selectedDates[0] ? selectedDates[0] : "",
      end_date: selectedDates && selectedDates[1] ? selectedDates[1] : "",
    };
    dispatch(getDashboardWidgetDetailsAction(filter));
    dispatch(getDashboardTableStatisticsAction(filter));
    dispatch(getDashboardChartDataAction(filter));
  }, [dispatch, selectedDates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const numberOfCalls = [
    {
      label: "Successful",
      backgroundColor: "#08A975",
      data: chartData?.vendorNumOfCalls?.datasets?.[0]?.data || [],
    },
    {
      label: "Insufficient",
      backgroundColor: "#FF9900",
      data: chartData?.vendorNumOfCalls?.datasets?.[1]?.data || [],
    },
    {
      label: "Failed",
      backgroundColor: "#EC6969",
      data: chartData?.vendorNumOfCalls?.datasets?.[2]?.data || [],
    },
  ];

  const numberOfCallsByType = [
    {
      label: "Live",
      backgroundColor: "#08A975",
      data: chartData?.vendorNumOfCallsByType?.datasets?.[0]?.data || [],
    },
    {
      label: "Cached",
      backgroundColor: "#FF9900",
      data: chartData?.vendorNumOfCallsByType?.datasets?.[1]?.data || [],
    },
    {
      label: "Lite",
      backgroundColor: "#1F77B4",
      data: chartData?.vendorNumOfCallsByType?.datasets?.[2]?.data || [],
    },
  ];

  return (
    <MasterLayout>
      <TabTitle title={"Dashboard"} />
      <div className="dashboard-page py-30px">
        <div className="container">
          <PageHeader
            title={"Dashboard"}
            isDatePicker
            onDateSelect={handleDateSelect}
            isSelectionPart
            isTopPagination={false}
          />
          <VendorWidgets data={tableData?.[0]} />
          <div className="chart-section">
            <div className="row">
              <div className="col-lg-12 mb-4 pb-1">
                <div className=" bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls</h2>
                  <StackedBarChart
                    id={1}
                    data={numberOfCalls}
                    labels={
                      chartData?.vendorNumOfCalls &&
                      formatChartLabels(chartData?.vendorNumOfCalls.labels)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls by Type</h2>
                  <StackedBarChart
                    id={2}
                    data={numberOfCallsByType}
                    labels={
                      chartData?.vendorNumOfCallsByType &&
                      formatChartLabels(chartData?.vendorNumOfCallsByType.labels)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Statistics
            customClass="partner-dashboard-statastics"
            style={{ maxHeight: "1260px", overflowY: "auto" }}
            statisticsFor={"other"}
            name={"Source Data"}
            columns={[
              "No.",
              "Date",
              "No. of Calls",
              "Failed",
              "Total Successful",
              "Insufficient",
              "Successful",
              "Live",
              "Cached",
              "Lite",
            ]}
            isVendorLoggedIn
            isPercentage
            statisticsData={statisticsData}
            navigationPath={"/vendor/logs"}
            isLink
          />
        </div>
      </div>
    </MasterLayout>
  );
};

export default Dashboard;
