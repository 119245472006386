import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import PartnerForm from "./PartnerForm";
import { fetchPartner, fetchPriority } from "../../store/action/admin/partnerAction";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PageHeader from "../../components/PageHeader";
import MasterLayout from "../../components/MasterLayout";
import PriorityListing from "./PriorityListing";
import { fetchAllVendorListing } from "../../store/action/admin/vendorAction";

const EditPartner = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("basic-details");
  const partners = useSelector(state => state.partners);
  const { listing } = useSelector(state => state.vendors);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const listingParam = query.get("listing");
    if (listingParam === "true") {
      setActiveTab("priority-listing");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchPartner(id));
    dispatch(fetchPriority(id));
    dispatch(fetchAllVendorListing());
  }, [dispatch, id]);

  const handleChangeTab = tabName => {
    setActiveTab(tabName);
  };

  const partnerValue = {
    full_name: partners.full_name,
    email: partners.email,
    accepted_data_type: partners.accepted_data_type ? partners.accepted_data_type.split(",") : [],
    monthly_limit: partners.monthly_limit,
    partners_slabs: partners.partners_slabs?.map(slab => ({
      ...slab,
      calls: slab.end_value ? parseInt(slab.end_value) + 1 : "",
    })),
    phone_number: partners.phone_number,
    address: partners.address,
    api_key: partners.api_key,
    alternate_emails: partners?.alternative_email?.split(","),
    do_not_call_rto_lite_api: partners.do_not_call_rto_lite_api,
  };

  return (
    partners && (
      <>
        <TabTitle title="Edit Partner" />
        <MasterLayout>
          <div className="new-partners-page py-30px">
            <div className="container">
              <PageHeader title={"Edit Partner"} isBackButton backLink={"/admin/partners"} />
              <div className="wrapper">
                <div className="p-4">
                  <div className="setting-tabs">
                    <Tabs
                      id="settings-tabs"
                      activeKey={activeTab}
                      onSelect={key => handleChangeTab(key)}
                      className="mb-3"
                    >
                      <Tab eventKey="basic-details" title="Basic Details">
                        <PartnerForm id={id} singlePartner={partnerValue} isEdit={true} />
                      </Tab>
                      <Tab eventKey="priority-listing" title="Priority Listing">
                        <PriorityListing
                          priorities={partners.priorities}
                          dataType={partnerValue.accepted_data_type}
                          id={id}
                          vendors={listing}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MasterLayout>
      </>
    )
  );
};

export default EditPartner;
