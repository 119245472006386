import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import downloadIcon from "../../assets/images/download-icon.svg";
import viewIcon from "../../assets/images/view.svg";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import MasterLayout from "../../components/MasterLayout";
import PageHeader from "../../components/PageHeader";
import { customStyles, invoicesOptions } from "../../constants";
import ListTable from "../../components/tables/ListTable";
import { fetchInvoices, generateInvoice } from "../../store/action/admin/invoiceAction";
import { formatCurrency } from "../../components/shared/sharedMethod";
import TopProgressBar from "../../components/shared/TopProgressBar";

const Invoices = () => {
  const dispatch = useDispatch();
  const { invoices, totalRecord } = useSelector(state => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const totalPages = Math.ceil(totalRecord / perPage);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const financialYearStart = new Date(currentYear, 3, 1);
  const financialYearEnd = new Date(currentYear + 1, 2, 31);
  const [selectedDates, setSelectedDates] = useState([financialYearStart, financialYearEnd]);

  const filter = {
    page: currentPage,
    limit: perPage,
    start_date: selectedDates && selectedDates[0] ? selectedDates[0] : "",
    end_date: selectedDates && selectedDates[1] ? selectedDates[1] : "",
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(fetchInvoices(filter, "vendor"));
    }
  }, [selectedDates, currentPage, perPage, dispatch]);

  const handleNextPage = i => {
    setCurrentPage(i);
  };

  const handlePrevPage = i => {
    setCurrentPage(i);
  };

  const handleDateSelect = dateRange => {
    setSelectedDates(dateRange);
  };

  const downloadInvoice = (e, item, isView = false) => {
    e.preventDefault();
    dispatch(generateInvoice(item, setLoading, isView));
  };

  const handleSearch = e => {
    const searchText = e.target.value.toLowerCase().replace(/#/g, "");
    if (searchText.trim() === "") {
      setIsSearch(false);
      dispatch(fetchInvoices(filter, "vendor"));
    } else {
      setCurrentPage(1);
      setIsSearch(true);
      dispatch(
        fetchInvoices(
          {
            page: 1,
            limit: perPage,
            search: searchText,
          },
          "vendor",
        ),
      );
    }
  };

  const columns = [
    {
      id: 1,
      label: "Invoice No.",
      className: "text-start",
      containerClass: "text-start",
      customData: item => <span>{`#${item.invoice_number}`}</span>,
    },
    {
      id: 2,
      label: "Generated On",
      className: "text-end",
      containerClass: "text-end",
      customData: item => <span>{moment(item.invoice_date).format("MMM YYYY")}</span>,
    },
    {
      id: 3,
      label: "Total Amount",
      className: "text-end",
      containerClass: "text-end",
      customData: item => <span>{formatCurrency(item.total_amount)}</span>,
    },
    {
      id: 4,
      label: "Action",
      name: "actions",
      className: "text-end w-15",
      containerClass: "text-end",
      customData: item => (
        <div className="d-flex gap-2 justify-content-end">
          <button onClick={e => downloadInvoice(e, item, true)} className=" action-icon">
            <img src={viewIcon} alt="log" className="img-fluid" />
          </button>
          <button onClick={e => downloadInvoice(e, item)} className=" action-icon">
            <img src={downloadIcon} alt="log" className="img-fluid" />
          </button>
        </div>
      ),
    },
  ];

  const handleLimitChange = selectedOption => {
    setPerPage(selectedOption.value);
  };

  return (
    <>
      <MasterLayout>
        <TopProgressBar isLoading={loading} />
        <TabTitle title={"Invoices"} />
        <div className="invoices-page py-30px">
          <div className="container">
            <PageHeader
              title={"Invoices"}
              isSelectionPart
              isDatePicker
              customStyles={customStyles}
              onDateSelect={handleDateSelect}
              options={invoicesOptions}
              isInvoice
              onLimitChange={handleLimitChange}
              paginationLimit={perPage}
            />
            <ListTable
              totalRecord={totalRecord}
              currentPage={currentPage}
              perPage={perPage}
              isPagination
              isSearch
              onSearch={handleSearch}
              columns={columns}
              data={invoices}
              tableClass={"statistics-table"}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              setPerPage={setPerPage}
              placeholder="Search by Invoice No."
            />
          </div>
        </div>
      </MasterLayout>
    </>
  );
};
export default Invoices;
