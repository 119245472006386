import { Alert } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";

const WarningAlert = ({ message }) => {
  return (
    <Alert variant="warning" className="d-flex align-items-center gap-3 fw-medium mb-4">
      <BsExclamationTriangleFill className="warning-alert-icon" />
      We wanted to inform you that you have utilized {message}% of your monthly limit.
    </Alert>
  );
};

export default WarningAlert;
