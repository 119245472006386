import { adminActionType } from "../../../constants";

const partnerLogsReducer = (state = [], action) => {
  switch (action.type) {
    case adminActionType.FETCH_PARTNER_LOGS:
      return action.payload;
    case adminActionType.FETCH_SINGLE_PARTNER_LOGS:
      return { ...state, singlePartnerLogs: action.payload };
    case adminActionType.FETCH_PARTNER_LOGS_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default partnerLogsReducer;
