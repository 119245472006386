import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    0: " #456dff",
    "1.0": "#456dff",
  },
  shadowBlur: 0,
  barThickness: 2,
});

const TopProgressBar = ({ isLoading }) => {
  return isLoading ? <TopBarProgress /> : null;
};

export default TopProgressBar;
