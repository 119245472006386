import toast from "react-hot-toast";
import { localStorageConstant } from "../constants";
import { environment } from "./environment";

const axiosInterceptor = {
  setupInterceptors: (axios, isToken = false, isFormData = false) => {
    axios.interceptors.request.use(
      config => {
        let token = localStorage.getItem("token");

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (isToken) {
          return config;
        }

        if (!token) {
          if (
            !window.location.href.includes("login") &&
            !window.location.href.includes("reset-password") &&
            !window.location.href.includes("forgot-password")
          ) {
            window.location.href = `${environment.APP_URL}/login`;
          }
        }
        if (isFormData) {
          config.headers["Content-Type"] = "multipart/form-data";
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      response => successHandler(response),
      error => errorHandler(error),
    );
  },
};

const errorHandler = error => {
  if (error.response.status === 401) {
    localStorage.removeItem(localStorageConstant.TOKEN);
    toast.error(error.response?.data?.message);
    window.location.href = `${environment.APP_URL}/login`;
  } else if (error.response.status === 403) {
    toast.error(error.response?.data?.message);
    window.location.href = `${environment.APP_URL}`;
  } else {
    return Promise.reject({ ...error });
  }
};

const successHandler = response => {
  return response;
};

export default axiosInterceptor;
