import React from "react";
import { Modal, Button } from "react-bootstrap";
import PrimaryButton from "../buttons/PrimaryButton";

const SimpleModal = ({
  show,
  handleSubmit,
  handleClose,
  title,
  children,
  isSubmit = true,
  isShowContent = false,
  isCancel = true,
  loading,
  isFooter = true,
  submitName = "Save",
}) => {
  return (
    <>
      {isShowContent ? (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>{children}</Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <form>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{children}</Modal.Body>
              {isFooter && (
                <Modal.Footer>
                  {isSubmit && (
                    <PrimaryButton
                      type="submit"
                      variant="primary"
                      name={loading ? "Saving..." : submitName}
                      onClick={event => handleSubmit(event)}
                    />
                  )}
                  {isCancel && (
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                  )}
                </Modal.Footer>
              )}
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default SimpleModal;
