import React, { useEffect, useState } from "react";
import { updateSettings } from "../../store/action/admin/settingAction";

import "../../assets/css/setting.css";
import { useDispatch, useSelector } from "react-redux";
import cancel from "../../assets/images/cancel-icon.svg";

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const [loading, setLoading] = useState(false);

  const [limitFields, setLimitFields] = useState([]);

  useEffect(() => {
    if (
      settings.monthly_limit_notifications &&
      typeof settings.monthly_limit_notifications === "string"
    ) {
      setLimitFields(settings.monthly_limit_notifications.split(","));
    } else {
      setLimitFields([]);
    }
  }, [settings.monthly_limit_notifications]);

  const handleAddField = () => {
    const newFields = [...limitFields, ""];
    setLimitFields(newFields);
  };

  const handleRemoveField = index => {
    const updatedFields = [...limitFields];
    updatedFields.splice(index, 1);
    setLimitFields(updatedFields);
  };

  const handleFieldChange = (index, value) => {
    const newFields = [...limitFields];
    newFields[index] = value;
    setLimitFields(newFields);
  };

  const validateFields = () => {
    return limitFields.every(field => field.trim() !== "");
  };

  const [error, setError] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    const isValid = validateFields();
    if (isValid) {
      setLoading(true);
      dispatch(updateSettings({ monthly_limit_notifications: limitFields.join(",") }, setLoading));
      setError("");
    } else {
      setError("Please fill in all added fields.");
    }
  };
  return (
    <>
      <form>
        <div>
          <label className="form-label fw-medium mb-1 fs-16px">Monthly Limit Exceed:</label>
          <div className="d-flex gap-3 flex-wrap mb-3">
            {limitFields?.map((value, index) => (
              <div key={index} className="position-relative">
                <input
                  type="text"
                  className="form-control fw-medium fs-16px add-limit-feild"
                  placeholder={value}
                  value={value}
                  onChange={e => handleFieldChange(index, e.target.value)}
                />
                {index !== 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveField(index)}
                    className="border-0 bg-transparent p-0 cancel-btn"
                  >
                    <img src={cancel} alt="cancel-icon" className="img-fluid" />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              className="btn btn-primary-light d-flex gap-2"
              onClick={handleAddField}
            >
              + Add
            </button>
          </div>
          <span className="text-danger d-block fw-400 fs-small mt-2">{error ? error : null}</span>
        </div>
        <p className="fs-14px text-gray-600">
          Notifications email will be triggered to partners, when they hit their X% of monthly
          limit.
        </p>
        <div className="d-flex gap-2 pt-3">
          <button type="submit" className="btn btn-primary" onClick={onSubmit}>
            {loading ? "Saving" : "Save"}
          </button>
        </div>
      </form>
    </>
  );
};

export default NotificationSettings;
