import React, { useState, useEffect } from "react";
import SimpleModal from "./SimpleModal";

const ExportFieldModal = ({
  show,
  handleClose,
  handleExport,
  isVendor = false,
  isLoginVendor = false,
}) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const fields = [
    { name: "RC No", value: "reg_no" },
    { name: "Duration", value: "duration" },
    { name: "Status Code", value: "status_code" },
    { name: "Status", value: "status" },
    { name: "Revenue", value: "revenue" },
    { name: "Cost", value: "cost" },
    { name: "Partial Flag Reason", value: "partial_flag_reason" },
    { name: "Happened", value: "created_at" },
    { name: "Response Type", value: "response_type" },
    { name: "Request Type", value: "data_type" },
  ];

  const vendorFields = [
    { name: "RC No", value: "reg_no" },
    { name: "Cost", value: "cost" },
    { name: "Status Code", value: "status_code" },
    { name: "Status", value: "status" },
    { name: "Data Type", value: "data_type" },
    { name: "Duration", value: "duration" },
    { name: "Happened", value: "created_at" },
    { name: "Failed Reason", value: "failed_reason" },
  ];

  const updateVendorFields = isLoginVendor
    ? vendorFields.map(field =>
        field.value === "cost" ? { value: "vendor_revenue", name: "Revenue" } : field,
      )
    : vendorFields;

  useEffect(() => {
    if (show) {
      setSelectedFields([]);
      setAllSelected(false);
    }
  }, [show]);

  const handleFieldChange = field => {
    setSelectedFields(prevFields =>
      prevFields.includes(field) ? prevFields.filter(f => f !== field) : [...prevFields, field],
    );
  };

  const handleSelectAllChange = () => {
    if (allSelected) {
      setSelectedFields([]);
    } else {
      setSelectedFields(mappedFields.map(field => field.value));
    }
    setAllSelected(!allSelected);
  };

  let mappedFields = isVendor ? updateVendorFields : fields;

  useEffect(() => {
    setAllSelected(selectedFields.length === mappedFields.length);
  }, [selectedFields, mappedFields]);

  return (
    <SimpleModal
      title={"Select fields for export"}
      show={show}
      isSubmit
      handleClose={handleClose}
      handleSubmit={e => handleExport(e, selectedFields)}
      submitName="Export"
    >
      <div className="text-center py-sm-3 py-2 my-sm-1">
        <div className="row">
          <div className="col-12 mb-3">
            <div
              className="form-check"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="select_all"
                checked={allSelected}
                onChange={handleSelectAllChange}
                style={{ height: "22px", width: "22px" }}
              />
              <label className="form-check-label mx-2" htmlFor="select_all">
                Select All
              </label>
            </div>
          </div>
          {mappedFields.map(field => (
            <div key={field.value} className="col-sm-6 col-12 mb-3">
              <div
                className="form-check"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  id={field.value}
                  value={field.value}
                  checked={selectedFields.includes(field.value)}
                  onChange={() => handleFieldChange(field.value)}
                  style={{ height: "22px", width: "22px" }}
                />
                <label className="form-check-label mx-2" htmlFor={field.value}>
                  {field.name}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SimpleModal>
  );
};

export default ExportFieldModal;
