import { adminActionType } from "../../constants";

const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case adminActionType.USER_PROFILE:
      return action.payload;
    case adminActionType.EDIT_PROFILE:
      return action.payload;
    default:
      return state;
  }
};

export default AuthReducer;
