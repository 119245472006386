import { adminActionType, authActionType } from "../../../constants";

const invoiceReducer = (state = [], action) => {
  switch (action.type) {
    case adminActionType.FETCH_INVOICES:
      return action.payload;
    case adminActionType.FETCH_INVOICE:
      return { ...state, invoice: action.payload };
    case authActionType.RESET_STATE:
      return [];
    default:
      return state;
  }
};

export default invoiceReducer;
