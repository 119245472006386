import { paginationAction } from "../../constants";

const totalRecordReducer = (state = 0, action) => {
  switch (action.type) {
    case paginationAction.SET_TOTAL_RECORD:
      return action.payload;
    default:
      return state;
  }
};

export default totalRecordReducer;
