import { partnerDashboardChartAction } from "../../../constants";

const initVal = {
  chartData: [],
};

const partnerDashboardChartReducer = (state = initVal, action) => {
  switch (action.type) {
    case partnerDashboardChartAction.GET_DASHBOARD_CHART_DATA:
      return { ...state, chartData: action.payload };
    default:
      return state;
  }
};

export default partnerDashboardChartReducer;
