import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PageHeader from "../../components/PageHeader";
import ListTable from "../../components/tables/ListTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchSinglePartnerLogs } from "../../store/action/admin/partnerAction";
import { classNameAndStatus, getDataType, timeAgo } from "../../components/shared/sharedMethod";
import { dataTypeWithMixedOptions, statusWithLiteOptions } from "../../constants";
import { exportLogs } from "../../store/action/excelExportAction";
import TopProgressBar from "../../components/shared/TopProgressBar";
import dashboardIcon from "../../assets/images/dashboard-icon.svg";
import moment from "moment";
import toast from "react-hot-toast";

const columns = [
  {
    id: 1,
    label: "Status Code",
    name: "status_code",
    customData: item => (
      <span className={item.status_code === 200 ? "successful-badge badge" : "failed-badge badge"}>
        {item.status_code}
      </span>
    ),
  },
  {
    id: 2,
    label: "Partner Name",
    name: "full_name",
  },
  {
    id: 3,
    label: "RC No.",
    name: "reg_no",
    className: "text-center",
    containerClass: "text-center",
  },
  {
    id: 4,
    label: "Status",
    name: "status",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      if (item.status) {
        const { className, text } = classNameAndStatus(item);
        return <span className={className}>{text}</span>;
      }
    },
  },
  {
    id: 5,
    label: "Req. Type",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      const dataTypeText = getDataType(item?.data_type);
      return dataTypeText;
    },
  },
  {
    id: 11,
    label: "Res. Type",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      const dataTypeText = getDataType(item?.response_type);
      return dataTypeText;
    },
  },
  {
    id: 6,
    label: "Cost",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>₹{item.cost}</span>,
  },
  {
    id: 7,
    label: "Revenue",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>₹{item.revenue}</span>,
  },
  {
    id: 8,
    label: "Duration",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>{item.duration}ms</span>,
  },
  {
    id: 9,
    label: "Happened",
    className: "text-center",
    containerClass: "text-center",
    customData: item => (
      <span>
        <div className="mb-1">
          {timeAgo(item.created_at).formattedDate}
          {timeAgo(item.created_at).time}
        </div>
      </span>
    ),
  },
  {
    id: 10,
    label: "",
    className: "text-end",
    customData: item => (
      <Link
        to={`/admin/partners-logs/${item.public_id}`}
        className="link-arrow ms-auto"
        target="_blank"
      >
        <BsChevronRight />
      </Link>
    ),
  },
];

const PartnerLogs = () => {
  const dispatch = useDispatch();

  const { singlePartnerLogs } = useSelector(state => state.partners);
  const { totalRecord } = useSelector(state => state);

  const navigate = useNavigate();

  const partnerName = localStorage.getItem("partnerName");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const totalPages = Math.ceil(totalRecord / perPage);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDataTypeStatus, setSelectedDataTypeStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedPartner, setSelectedPartner] = useState("all");
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [showExportFieldModal, setShowExportFieldModal] = useState(false);

  const handleVendorSelect = vendor => {
    setSelectedPartner(vendor.value);
    setSelectedPartnerName(vendor.label);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedDate = queryParams.get("date");
  const parsedDate = moment(selectedDate, "DD-MM-YYYY");

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return parsedDate.isValid() ? parsedDate : startDate;
    })(),
    parsedDate.isValid() ? parsedDate : new Date(),
  ]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setSelectedPartner("all");
    setSelectedPartnerName("All Partner");
  }, []);

  const handleDateSelect = dateRange => {
    setSelectedDates(dateRange);
  };

  const onDataTypeSelect = selectedOption => {
    setSelectedDataTypeStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const handleStatusSelect = selectedOption => {
    setSelectedStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const filter = {
    page: currentPage,
    limit: perPage,
    start_date: selectedDates[0],
    end_date: selectedDates[1],
    status: selectedStatus,
    data_type: selectedDataTypeStatus,
    search: searchTerm.trim(),
  };

  const fetchLogs = () => {
    dispatch(fetchSinglePartnerLogs(filter, selectedPartner));
  };

  useEffect(() => {
    fetchLogs();
  }, [
    currentPage,
    perPage,
    selectedDates,
    selectedStatus,
    selectedDataTypeStatus,
    searchTerm,
    selectedPartner,
  ]);

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(currentPage > 1 ? currentPage : 1);
  };
  const handleNextPage = i => {
    setCurrentPage(i);
  };

  const handlePrevPage = i => {
    setCurrentPage(i);
  };

  const onClickExport = e => {
    e.preventDefault();
    if (selectedPartner === "all") {
      toast.error("Please select partner");
    } else {
      setShowExportFieldModal(true);
    }
  };

  const handleLimitChange = selectedOption => {
    setPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const goToButtonHandle = () => {
    localStorage.removeItem("partnerName");
    localStorage.removeItem("partnerId");
    localStorage.setItem("partnerId", selectedPartner);
    localStorage.setItem("partnerName", selectedPartnerName);
    navigate(`/admin/partners/${selectedPartner !== "all" && selectedPartner}/dashboard`);
  };

  const handleExport = (e, payload) => {
    e.preventDefault();
    if (payload.length === 0) {
      toast.error("Please select fields to export");
    } else {
      const jsonPayload = { fields: payload };
      dispatch(exportLogs(selectedPartner, "partner", filter, setLoading, false, jsonPayload));
      setShowExportFieldModal(false);
    }
  };

  const handleClose = () => {
    setShowExportFieldModal(false);
  };

  return (
    <>
      <MasterLayout>
        <TopProgressBar isLoading={loading} />
        <TabTitle title={"Partner Logs"} />
        <div className="log-page py-30px">
          <div className="container">
            <PageHeader
              title={`${partnerName} Logs`}
              isSelect
              isDatePicker
              isBackButtonLast={selectedPartner === "all" ? false : true}
              backLink={"/admin/partners"}
              isSelectionPart
              onStatusSelect={handleStatusSelect}
              onDateSelect={handleDateSelect}
              onDataTypeSelect={onDataTypeSelect}
              isDataTypeSelect
              options={statusWithLiteOptions}
              dataTypeCustomOptions={dataTypeWithMixedOptions}
              onLimitChange={handleLimitChange}
              paginationLimit={perPage}
              navigationButtonText={<img src={dashboardIcon} alt="log" />}
              navigationHandle={goToButtonHandle}
              isNavigationButton={selectedPartner === "all" ? false : true}
              isTitleSelectable
              handleTitleChange={handleVendorSelect}
              isPartnerOptions={true}
              selectedPartnerOption={{
                value: selectedPartner,
                label: selectedPartnerName,
              }}
              selectedDates={selectedDates}
            />
            <ListTable
              showExportFieldModal={showExportFieldModal}
              handleClose={handleClose}
              handleExport={handleExport}
              loading={loading}
              isExport
              onClickExport={onClickExport}
              columns={columns}
              data={singlePartnerLogs}
              tableClass={"logs-table"}
              onSearch={handleSearch}
              currentPage={currentPage}
              totalRecord={totalRecord}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              setPerPage={setPerPage}
              perPage={perPage}
              isSearch
              isPagination
              placeholder="Search by RC No."
              clickableRowPath={"/admin/partners-logs"}
              isClickableRow
            />
          </div>
        </div>
      </MasterLayout>
    </>
  );
};

export default PartnerLogs;
