import React, { useEffect } from "react";
import VendorForm from "./VendorForm";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendor } from "../../store/action/admin/vendorAction";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import { vendorService } from "../../constants";

const EditVendor = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const vendors = useSelector(state => state.vendors);

  useEffect(() => {
    dispatch(fetchVendor(id));
  }, [dispatch, id]);

  const vendorValue = {
    full_name: vendors.full_name,
    email: vendors.email,
    accepted_data_type: vendors.accepted_data_type ? vendors.accepted_data_type.split(",") : [],
    failed_threshold: vendors.failed_threshold,
    store_cached_data: vendors.store_cached_data ? 1 : 0,
    vendors_slabs: vendors.vendors_slabs?.map(slab => ({
      ...slab,
      calls: slab.end_value ? parseInt(slab.end_value) + 1 : "",
    })),
    alternate_emails: vendors?.alternative_email?.split(","),
    phone_number: vendors.phone_number,
    address: vendors.address,
    user_name: vendors.user_name,
    insufficient_threshold: vendors.insufficient_threshold,
  };

  const supportedDataTypes = vendorService.find(vendor => vendor.name === vendors.user_name) || {
    data_types: [1, 2, 3],
  };

  if (
    supportedDataTypes?.data_types?.length === 4 &&
    vendorValue.accepted_data_type.includes("3")
  ) {
    vendorValue.accepted_data_type = ["1", "2"];
  }

  return (
    <>
      <TabTitle title={"Edit Vendor"} />
      <VendorForm
        id={id}
        singleVendor={vendorValue}
        isEdit
        supportedDataTypes={supportedDataTypes?.data_types}
      />
    </>
  );
};

export default EditVendor;
