import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";

import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import TabTitle from "../shared/tabTitle/TabTitle";
import { loginAction } from "../../store/action/authAction";
import { localStorageConstant } from "../../constants";
import Footer from "../common/Footer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const token = localStorage.getItem(localStorageConstant.TOKEN);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      history.push(window.location.pathname);
    }
  }, [dispatch, history, token]);

  const [loginInputs, setLoginInputs] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [changePassword, setChangePassword] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setLoginInputs(prevInputs => ({
      ...prevInputs,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleValidation = () => {
    let isValid = true;
    let newErrors = {};

    if (!loginInputs.email) {
      newErrors.email = "Please enter your email";
      isValid = false;
    }

    if (!loginInputs.password) {
      newErrors.password = "Please enter your password";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handlePasswordVisibility = () => {
    setChangePassword(prevState => !prevState);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      dispatch(loginAction(loginInputs, navigate, setLoading));
    }
  };

  return (
    <>
      <TabTitle title={"Login"} />
      <div className="login-page h-100 d-flex flex-column justify-content-center">
        <div className="container">
          <div className="company-logo text-center mb-80px">
            <img src={logo} alt="Logo" className="h-100" />
          </div>
          <div className="login-form bg-white  mx-auto">
            <div className="text-center mb-60px">
              <h3 className="fw-bold mb-1">Sign In</h3>
              <p className="text-gray-600 fs-16px">Enter your details to continue</p>
            </div>
            <form>
              <div className="mb-3">
                <label className="form-label fw-medium mb-1 fs-16px">Email:</label>
                <span className="text-danger fs-16px">*</span>
                <input
                  type="email"
                  name="email"
                  className="form-control fw-medium fs-16px"
                  value={loginInputs.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="text-danger">{errors.email && errors.email}</span>
              </div>
              <div className="mb-3">
                <label className="form-label fw-medium mb-1 fs-16px">Password:</label>
                <span className="text-danger fs-16px">*</span>
                <div className="mb-2 position-relative">
                  <input
                    type={changePassword ? "password" : "text"}
                    name="password"
                    className="form-control fw-medium fs-16px"
                    value={loginInputs.password}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <span
                    onClick={handlePasswordVisibility}
                    className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                  >
                    {changePassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <Link
                  to="/forgot-password"
                  className="fs-16px text-decoration-none text-end d-block text-primary fw-medium"
                >
                  Forgot your password?
                </Link>
                <span className="text-danger">{errors.password && errors.password}</span>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                onClick={e => handleSubmit(e)}
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Login;
