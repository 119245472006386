import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import dashboardIcon from "../../assets/images/dashboard-icon.svg";
import logIcon from "../../assets/images/log-icon.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import { fetchVendors } from "../../store/action/admin/vendorAction";
import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import ListTable from "../../components/tables/ListTable";
import PageHeader from "../../components/PageHeader";
import VendorModal from "./VendorModal";
import { Button } from "react-bootstrap";
import { updatePartnerStatus } from "../../store/action/admin/partnerAction";
import toast from "react-hot-toast";

const Vendors = () => {
  const dispatch = useDispatch();
  const { vendors, totalRecord } = useSelector(state => state);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const totalPages = Math.ceil(totalRecord / perPage);
  const [vendorModal, setVendorModal] = useState(false);
  const [vendorId, setVendorId] = useState("");

  const onClickVendorModal = id => {
    setVendorId(id);
    setVendorModal(true);
  };

  const fetchData = () => {
    const filter = {
      search: searchText,
      page: currentPage,
      limit: perPage,
    };
    dispatch(fetchVendors(filter));
  };

  useEffect(() => {
    fetchData();
  }, [searchText, currentPage, perPage]);

  const handleSearch = e => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);
    setCurrentPage(1);
    if (searchText.trim() === "") {
      dispatch(fetchVendors());
    } else {
      fetchData();
    }
  };

  const handleNextPage = i => {
    setCurrentPage(i);
  };

  const handlePrevPage = i => {
    setCurrentPage(i);
  };

  const handleStatusToggle = (id, currentStatus) => {
    const data = { status: currentStatus === 1 ? 0 : 1 };
    dispatch(updatePartnerStatus(id, data, true))
      .then(() => {
        setTimeout(fetchData, 500);
        toast.success("Vendor status updated successfully");
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

  const columns = [
    {
      id: 1,
      label: "Vendor Name",
      name: "full_name",
      sortBy: "name",
      className: "text-start w-70",
      containerClass: "text-start",
    },
    {
      id: 2,
      label: "Status",
      name: "status",
      sortBy: "name",
      className: "text-center",
      containerClass: "text-center",
      customData: item => (
        <div className="form-check form-switch">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            id={`statusSwitch${item.public_id}`}
            checked={item.status}
            onChange={() => handleStatusToggle(item.public_id, item.status)}
          />
          <label className="form-check-label" htmlFor={`statusSwitch${item.public_id}`}></label>
        </div>
      ),
    },
    {
      id: 3,
      label: "Action",
      name: "actions",
      className: "text-center w-15",
      containerClass: "text-center",
      customData: item => (
        <div className="d-flex gap-2 justify-content-center">
          <Button
            onClick={() => onClickVendorModal(item.public_id)}
            className="action-icon bg-white p-0"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
              <path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z" />
            </svg>
          </Button>
          <Link
            to={`/admin/vendors/${item.public_id}/dashboard`}
            className="dashboard-icon action-icon"
            onClick={() => {
              localStorage.removeItem("vendorName");
              localStorage.removeItem("vendorId");
              localStorage.setItem("vendorName", item.full_name);
              localStorage.setItem("vendorId", item.public_id);
            }}
          >
            <img src={dashboardIcon} alt="dashboard" className="img-fluid" />
          </Link>
          <Link
            to={`/admin/vendors/${item.public_id}/logs`}
            onClick={() => {
              localStorage.removeItem("vendorName");
              localStorage.removeItem("vendorId");
              localStorage.setItem("vendorName", item.full_name);
              localStorage.setItem("vendorId", item.public_id);
            }}
            className=" action-icon"
          >
            <img src={logIcon} alt="log" width={18} height={18} />
          </Link>
          <Link to={`/admin/vendors/${item.public_id}/edit`} className="edit-icon action-icon">
            <img src={editIcon} alt="edit" className="img-fluid" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <MasterLayout>
        <TabTitle title={"Vendors"} />
        <div className="partner-page py-30px">
          <div className="container">
            <PageHeader
              title={"Vendors"}
              isLink
              link={"/admin/vendors/create"}
              linkName={"+ New Vendor"}
            />
            <ListTable
              columns={columns}
              data={vendors}
              isSearch
              onSearch={handleSearch}
              currentPage={currentPage}
              totalRecord={totalRecord}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              tableClass={"statistics-table"}
              setPerPage={setPerPage}
              perPage={perPage}
            />
          </div>
        </div>
      </MasterLayout>
      <VendorModal show={vendorModal} id={vendorId} handleClose={() => setVendorModal(false)} />
    </>
  );
};
export default Vendors;
