import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  return (
    <div style={{ width: "100%", height: "330px" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <Bar
          data={{
            labels: data.labels,
            datasets: data.datasets,
          }}
          options={{
            interaction: {
              mode: "index",
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
                align: "end",
                labels: {
                  boxWidth: 12,
                  borderRadius: 4,
                },
              },
              tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
                callbacks: {
                  label: function (tooltipItem) {
                    return `${tooltipItem.dataset.label}: ${tooltipItem?.raw?.toFixed(2)}`;
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                },
                grid: {
                  display: false,
                },
                min: Math.min(0, Math.min(...data.datasets.flatMap(dataset => dataset.data))),
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;
