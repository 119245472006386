import toast from "react-hot-toast";
import { adminActionType, adminBaseURL } from "../../constants";
import apiConfig from "../../config/apiConfig";

export const fetchProfile = () => async dispatch => {
  await apiConfig
    .get(`${adminBaseURL.UPDATE_PROFILE}`)
    .then(response => {
      dispatch({
        type: adminActionType.USER_PROFILE,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      toast.error(response.data.message);
    });
};

export const editProfile = (profile, closeModal, setLoading) => async dispatch => {
  await apiConfig
    .post(`${adminBaseURL.UPDATE_PROFILE}/update-profile`, profile)
    .then(() => {
      closeModal();
      dispatch(fetchProfile());
      toast.success("Profile updated successfully");
    })
    .catch(({ response }) => {
      setLoading(false);
      toast.error(response.data.message);
    });
};
