import React, { useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

import "../../assets/css/partners.css";
import Checked from "../../assets/images/checked.svg";
import {
  createPartner,
  editPartner,
  regeneratePartnerAPIKey,
} from "../../store/action/admin/partnerAction";
import Trash from "../../assets/images/trash-icon.svg";
import ReGenerateAPIModal from "./ReGenerateAPIModal";
import ApiKeyModal from "../../components/modals/ApiKeyModal";
import ApiHistoryModal from "./APIHistoryModal";
import HistoryIcon from "../../assets/images/history.svg";

const PartnerForm = ({ id, isEdit = false, singlePartner }) => {
  const [changePassword, setChangePassword] = useState(true);
  const [showAPIModal, setShowAPIModal] = useState(false);
  const [changeConfirmPassword, setChangeConfirmPassword] = useState(true);
  const [showAPIHistoryModal, setShowAPIHistoryModal] = useState(false);
  const [apiKey, setAPIKey] = useState("");
  const [regenerateAPI, setRegenerateAPI] = useState(false);
  const changeIcon = changePassword === true ? false : true;
  const changeConfirmIcon = changeConfirmPassword === true ? false : true;
  const [emails, setEmails] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    if (!isEdit) {
      setShowAPIModal(false);
      navigate("/admin/partners");
      toast.success("Partner added successfully");
    } else {
      setShowAPIModal(false);
    }
  };

  const handleCloseRegenerateModal = () => {
    setRegenerateAPI(false);
  };

  const regenerateAPICall = () => {
    dispatch(regeneratePartnerAPIKey(id, setRegenerateAPI, setAPIKey, setShowAPIModal, false));
  };

  const [partnerValue, setPartnerValue] = useState({
    full_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    monthly_limit: "",
    accepted_data_type: [],
    partners_slab: [],
    phone_number: "",
    address: "",
    api_key: "",
    alternate_emails: [],
    do_not_call_rto_lite_api: false,
  });

  const [validationErrors, setValidationErrors] = useState([]);

  const [slabs, setSlabs] = useState([
    {
      calls: "",
      live_price: "",
      cached_price: "",
      start_value: 0,
      end_value: 0,
      lite_price: "",
    },
  ]);

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    monthly_limit: "",
    accepted_data_type: "",
  });

  useEffect(() => {
    if (singlePartner !== undefined && singlePartner !== null) {
      setPartnerValue({
        full_name: singlePartner.full_name,
        monthly_limit: singlePartner.monthly_limit,
        accepted_data_type: singlePartner.accepted_data_type,
        email: singlePartner.email,
        phone_number: singlePartner.phone_number,
        address: singlePartner.address,
        api_key: singlePartner.api_key,
        alternate_emails: singlePartner.alternate_emails || [],
        do_not_call_rto_lite_api: singlePartner.do_not_call_rto_lite_api,
      });
      setEmails(singlePartner.alternate_emails || []);
      const mappedSlabs =
        singlePartner &&
        singlePartner.partners_slabs &&
        singlePartner.partners_slabs.map((slab, index) => {
          return {
            ...slab,
            calls: slab.end_value.toString(),
          };
        });

      mappedSlabs?.length > 0
        ? setSlabs(mappedSlabs)
        : setSlabs([{ calls: "", live_price: "", cached_price: "", lite_price: "" }]);
    }
  }, [singlePartner]);

  const handleValidation = () => {
    let errors = {};
    let isValid = false;

    if (partnerValue["full_name"].trim() === "") {
      errors["full_name"] = "Please enter partner name";
    } else if (!isEdit && !EmailValidator.validate(partnerValue["email"])) {
      if (!partnerValue["email"]) {
        errors["email"] = "Please enter email address";
      } else {
        errors["email"] = "Please enter valid email address";
      }
    } else if (!isEdit && !partnerValue["password"]) {
      errors["password"] = "Please enter a password";
    } else if (!isEdit && !partnerValue["password_confirmation"]) {
      errors["password_confirmation"] = "Please enter a confirm password";
    } else if (!isEdit && partnerValue["password"] !== partnerValue["password_confirmation"]) {
      errors["password_confirmation"] = "Password and confirm password must be the same";
    } else if (!partnerValue["accepted_data_type"].length) {
      errors["accepted_data_type"] = "Please select Data type";
    } else if (String(partnerValue["phone_number"]).trim() === "") {
      errors["phone_number"] = "Please enter a phone number";
    } else if (!/^\d+$/.test(partnerValue["phone_number"])) {
      errors["phone_number"] = "Phone number must contain only numbers";
    } else if (partnerValue["phone_number"].length > 15) {
      errors["phone_number"] = "Phone number should be less than 15 numbers";
    } else if (!partnerValue["address"] || partnerValue["address"].trim() === "") {
      errors["address"] = "Please enter an address";
    } else if (String(partnerValue["monthly_limit"]).trim() === "") {
      errors["monthly_limit"] = "Please enter a monthly limit";
    } else {
      isValid = true;
    }

    setErrors(errors);
    return isValid;
  };

  const onChangeInput = e => {
    const { name, value, checked } = e.target;

    if (name === "accepted_data_type") {
      const updatedDataTypes = checked
        ? [...partnerValue.accepted_data_type, value]
        : partnerValue.accepted_data_type.filter(item => item !== value);

      setPartnerValue(prevState => ({
        ...prevState,
        [name]: updatedDataTypes,
      }));
    } else if (name === "do_not_call_rto_lite_api") {
      setPartnerValue(prevState => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setPartnerValue(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const addSlab = () => {
    setSlabs([...slabs, { calls: "", live_price: "", cached_price: "", lite_price: "" }]);
  };

  const deleteSlab = index => {
    const updatedSlabs = [...slabs];
    updatedSlabs.splice(index, 1);
    setSlabs(updatedSlabs);
  };

  const handleSlabChange = (index, field, value) => {
    const updatedSlabs = [...slabs];

    while (updatedSlabs.length <= index) {
      const previousSlab = updatedSlabs[index - 1] || {};
      updatedSlabs.push({
        calls: "",
        live_price: "",
        cached_price: "",
        start_value: index === 0 ? 0 : parseInt((previousSlab.end_value || 0) + 1),
        end_value: "",
      });
    }

    updatedSlabs[index][field] = value;

    if (field === "calls") {
      updatedSlabs[index].end_value = parseInt(value);

      if (index > 0 && parseInt(value) <= parseInt(updatedSlabs[index - 1].calls)) {
        setValidationErrors([
          "Calls value must be strictly greater than the previous slab's calls value",
        ]);
        return;
      }

      // Check if any other slab has the same call value
      const duplicateCalls = updatedSlabs.some(
        (slab, i) => i !== index && slab.calls === parseInt(value),
      );
      if (duplicateCalls) {
        setValidationErrors(["Slab calls value must be unique"]);
        return;
      }

      setValidationErrors([]);

      for (let i = index + 1; i < updatedSlabs.length; i++) {
        updatedSlabs[i].start_value = parseInt(updatedSlabs[i - 1].end_value) + 1;
      }
    }

    for (let i = 0; i < updatedSlabs.length; i++) {
      if (i === 0) {
        updatedSlabs[i].start_value = 0;
      } else {
        updatedSlabs[i].start_value = parseInt(updatedSlabs[i - 1].end_value) + 1;
      }
    }

    updatedSlabs.forEach(slab => {
      slab.live_price = slab.live_price || "";
      slab.cached_price = slab.cached_price || "";
      slab.lite_price = slab.lite_price || "";
    });

    setSlabs(updatedSlabs);

    const partnersSlabData = updatedSlabs.map(slab => ({
      start_value: slab.start_value,
      end_value: slab.end_value,
      live_price: slab.live_price,
      cached_price: slab.cached_price,
      lite_price: slab.lite_price,
    }));

    setPartnerValue({ ...partnerValue, partners_slab: partnersSlabData });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      const acceptedDataString = partnerValue.accepted_data_type.join(",");
      let alternate_emails = partnerValue?.alternate_emails?.join(",");
      if (isEdit) {
        const partnerData = {
          full_name: partnerValue.full_name,
          accepted_data_type: acceptedDataString,
          monthly_limit: partnerValue.monthly_limit,
          partners_slab: slabs,
          address: partnerValue.address,
          phone_number: String(partnerValue.phone_number),
          alternative_email: alternate_emails,
          do_not_call_rto_lite_api: partnerValue.do_not_call_rto_lite_api,
        };
        setLoading(true);
        dispatch(editPartner(id, partnerData, navigate, setLoading));
      } else {
        setLoading(true);
        dispatch(
          createPartner(
            { ...partnerValue, accepted_data_type: acceptedDataString },
            setShowAPIModal,
            setAPIKey,
            setLoading,
          ),
        );
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-4">
          <div>
            <label className="form-label fw-medium mb-1 fs-16px">Partner's Name:</label>
            <span className="text-danger fs-16px">*</span>
            <input
              type="text"
              onChange={e => onChangeInput(e)}
              name="full_name"
              className="form-control fw-medium fs-16px"
              value={partnerValue.full_name}
            />
          </div>
          <span className="text-danger d-block fw-400 fs-small mt-2">
            {errors["full_name"] ? errors["full_name"] : null}
          </span>
        </div>
        <div className="col-md-6 mb-4">
          <div>
            <label className="form-label fw-medium mb-1 fs-16px">Email:</label>
            <span className="text-danger fs-16px">*</span>
            <input
              type="email"
              onChange={e => onChangeInput(e)}
              name="email"
              className={`form-control fw-medium fs-16px ${isEdit ? "bg-gray-100" : ""}`}
              value={partnerValue.email}
              readOnly={isEdit}
            />
          </div>
          <span className="text-danger d-block fw-400 fs-small mt-2">
            {errors["email"] ? errors["email"] : null}
          </span>
        </div>
        {!singlePartner && (
          <div className="col-md-6 mb-4">
            <div>
              <label className="form-label fw-medium mb-1 fs-16px">Password:</label>
              <span className="text-danger fs-16px">*</span>
              <div className="mb-2 position-relative">
                <input
                  onChange={e => onChangeInput(e)}
                  name="password"
                  type={changePassword ? "password" : "text"}
                  className="form-control fw-medium fs-16px"
                />
                <span
                  onClick={() => {
                    setChangePassword(changeIcon);
                  }}
                  className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                >
                  {changeIcon ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <span className="text-danger d-block fw-400 fs-small mt-2">
              {errors["password"] ? errors["password"] : null}
            </span>
          </div>
        )}
        {!singlePartner && (
          <div className="col-md-6 mb-4">
            <div>
              <label className="form-label fw-medium mb-1 fs-16px">Confirm Password:</label>
              <span className="text-danger fs-16px">*</span>
              <div className="mb-2 position-relative">
                <input
                  onChange={e => onChangeInput(e)}
                  name="password_confirmation"
                  type={changeConfirmPassword ? "password" : "text"}
                  className="form-control fw-medium fs-16px"
                />
                <span
                  onClick={() => {
                    setChangeConfirmPassword(changeConfirmIcon);
                  }}
                  className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                >
                  {changeConfirmIcon ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <span className="text-danger d-block fw-400 fs-small mt-2">
              {errors["password_confirmation"] ? errors["password_confirmation"] : null}
            </span>
          </div>
        )}
        <div className="col-md-6 mb-4">
          <label className="form-label fw-medium mb-1 fs-16px">Alternate Email (s):</label>
          <ReactMultiEmail
            className="form-control"
            placeholder="Alternate email (s)"
            emails={emails}
            onChange={updatedEmails => {
              setEmails(updatedEmails);
              setPartnerValue(prevState => ({
                ...prevState,
                alternate_emails: updatedEmails,
              }));
            }}
            validateEmail={isEmail}
            getLabel={(email, index, removeEmail) => (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )}
          />
        </div>
        <div className="col-md-6 mb-4">
          <div>
            <label className="form-label fw-medium mb-1 fs-16px">Phone Number:</label>
            <span className="text-danger fs-16px">*</span>
            <input
              type="number"
              onChange={e => onChangeInput(e)}
              name="phone_number"
              className={`form-control fw-medium fs-16px`}
              value={partnerValue.phone_number}
            />
          </div>
          <span className="text-danger d-block fw-400 fs-small mt-2">
            {errors["phone_number"] ? errors["phone_number"] : null}
          </span>
        </div>

        <div className="col-md-6 mb-4">
          <div>
            <label className="form-label fw-medium mb-1 fs-16px">Address:</label>
            <span className="text-danger fs-16px">*</span>
            <input
              type="text"
              onChange={e => onChangeInput(e)}
              name="address"
              className={`form-control fw-medium fs-16px`}
              value={partnerValue.address}
            />
          </div>
          <span className="text-danger d-block fw-400 fs-small mt-2">
            {errors["address"] ? errors["address"] : null}
          </span>
        </div>

        {isEdit && (
          <div className="col-md-6 mb-4">
            <div>
              <label className="form-label fw-medium mb-1 fs-16px">API Key:</label>
              <div className="position-relative" style={{ display: "flex", gap: "10px" }}>
                <input
                  type="text"
                  className="form-control fw-medium fs-16px bg-gray-100"
                  placeholder={partnerValue.api_key}
                  disabled
                />
                <button
                  className="bg-transparent regenerate-icon"
                  onClick={() => setRegenerateAPI(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M3.52761 6.85585C3.83246 5.97991 4.32736 5.1562 5.02417 4.45304C7.49865 1.94174 11.5093 1.94174 13.9837 4.45304L14.6608 5.14415H13.3028C12.602 5.14415 12.0358 5.71873 12.0358 6.42993C12.0358 7.14113 12.602 7.71572 13.3028 7.71572H17.7172H17.7331C18.4338 7.71572 19 7.14113 19 6.42993V1.92968C19 1.21848 18.4338 0.643898 17.7331 0.643898C17.0323 0.643898 16.4661 1.21848 16.4661 1.92968V3.34405L15.7733 2.63687C12.309 -0.878955 6.69494 -0.878955 3.23067 2.63687C2.26464 3.61728 1.56783 4.77047 1.14024 6.00402C0.906647 6.67504 1.25505 7.40633 1.91227 7.6434C2.56949 7.88046 3.29402 7.52687 3.52761 6.85987V6.85585ZM0.910606 10.3395C0.712648 10.3998 0.522609 10.5083 0.368202 10.669C0.209835 10.8297 0.102938 11.0226 0.0475099 11.2315C0.0356325 11.2798 0.0237549 11.332 0.0158366 11.3842C0.00395914 11.4525 0 11.5208 0 11.5892V16.0733C0 16.7845 0.56616 17.3591 1.26693 17.3591C1.9677 17.3591 2.53386 16.7845 2.53386 16.0733V14.663L3.23067 15.3661C6.69494 18.878 12.309 18.878 15.7693 15.3661C16.7354 14.3857 17.4361 13.2325 17.8637 12.003C18.0973 11.332 17.7489 10.6007 17.0917 10.3636C16.4345 10.1266 15.7099 10.4802 15.4763 11.1472C15.1715 12.0231 14.6766 12.8468 13.9798 13.55C11.5053 16.0613 7.49469 16.0613 5.02021 13.55L5.01625 13.546L4.33924 12.8589H5.70119C6.40196 12.8589 6.96812 12.2843 6.96812 11.5731C6.96812 10.8619 6.40196 10.2873 5.70119 10.2873H1.28277C1.21942 10.2873 1.15607 10.2913 1.09273 10.2993C1.02938 10.3074 0.969994 10.3194 0.910606 10.3395Z"
                      fill="#FF9900"
                    />
                  </svg>
                </button>
                <button className="bg-transparent" onClick={() => setShowAPIHistoryModal(true)}>
                  <img src={HistoryIcon} width={22} alt="History Icon" />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="col-md-6 mb-4">
          <label className="form-label fw-medium mb-2 fs-16px">Data Type:</label>
          <span className="text-danger fs-16px">*</span>
          <div className="d-flex flex-wrap gap-2">
            <div>
              <input
                type="checkbox"
                id="radio1"
                name="accepted_data_type"
                className="select-radio"
                checked={partnerValue.accepted_data_type.includes("1")}
                onChange={e => onChangeInput(e)}
                value="1" // Use string value instead of number
              />
              <label htmlFor="radio1" className="data-type">
                Live
                <div className="checked-img">
                  <img src={Checked} alt="check" />
                </div>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="radio2"
                name="accepted_data_type"
                className="select-radio"
                value="2" // Use string value instead of number
                onChange={e => onChangeInput(e)}
                checked={partnerValue.accepted_data_type.includes("2")}
              />
              <label htmlFor="radio2" className="data-type">
                Cached
                <div className="checked-img">
                  <img src={Checked} alt="check" />
                </div>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="radio3"
                name="accepted_data_type"
                className="select-radio"
                checked={partnerValue.accepted_data_type.includes("3")}
                onChange={e => onChangeInput(e)}
                value="3" // Use string value instead of number
              />
              <label htmlFor="radio3" className="data-type">
                Lite
                <div className="checked-img">
                  <img src={Checked} alt="check" />
                </div>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="radio4"
                name="accepted_data_type"
                className="select-radio"
                value="4" // Use string value instead of number
                onChange={e => onChangeInput(e)}
                checked={partnerValue.accepted_data_type.includes("4")}
              />
              <label htmlFor="radio4" className="data-type">
                Mixed
                <div className="checked-img">
                  <img src={Checked} alt="check" />
                </div>
              </label>
            </div>
          </div>

          <span className="text-danger d-block fw-400 fs-small mt-2">
            {errors["accepted_data_type"] ? errors["accepted_data_type"] : null}
          </span>
        </div>
        {isEdit && (
          <div className="col-md-6 mb-4">
            <div className="mt-3">
              <label className="form-label fw-medium mb-1 fs-16px">Monthly Limit:</label>
              <span className="text-danger fs-16px">*</span>
              <input
                type="text"
                onChange={e => onChangeInput(e)}
                name="monthly_limit"
                className="form-control fw-medium fs-16px"
                value={partnerValue.monthly_limit}
              />
            </div>
            <span className="text-danger d-block fw-400 fs-small mt-2">
              {errors["monthly_limit"] ? errors["monthly_limit"] : null}
            </span>
          </div>
        )}

        <div className="col-md-6 mb-4">
          <div className="overflow-auto">
            <table className="table table-borderless add-slab-table w-100 mb-0">
              <thead>
                <tr>
                  <th className="fw-medium mb-0 fs-16px ps-0">Slabs:</th>
                  <th className="text-gray-600 fw-medium mb-0 fs-16px">No. Of Calls</th>
                  <th className="text-gray-600 fw-medium mb-0 fs-16px">Live Price</th>
                  <th className="text-gray-600 fw-medium mb-0 fs-16px">Cache Price</th>
                  <th className="text-gray-600 fw-medium mb-0 fs-16px">Lite Price</th>
                  <th className="text-gray-600 fw-medium mb-0 fs-16px"></th>
                </tr>
              </thead>
              <tbody>
                {slabs &&
                  slabs.map((slab, index) => (
                    <tr key={index}>
                      <td className="fw-medium mb-0 fs-16px ps-0 whitespace-nowrap">Less than</td>
                      <td>
                        <input
                          min={1}
                          type="number"
                          className="form-control fw-medium fs-16px"
                          placeholder="0"
                          value={slab.calls}
                          onChange={e => handleSlabChange(index, "calls", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          min={1}
                          type="number"
                          className="form-control fw-medium fs-16px"
                          placeholder="0"
                          value={slab.live_price}
                          onChange={e => handleSlabChange(index, "live_price", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          min={1}
                          type="number"
                          className="form-control fw-medium fs-16px"
                          placeholder="0"
                          value={slab.cached_price}
                          onChange={e => handleSlabChange(index, "cached_price", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          min={1}
                          type="number"
                          className="form-control fw-medium fs-16px"
                          placeholder="0"
                          value={slab.lite_price}
                          onChange={e => handleSlabChange(index, "lite_price", e.target.value)}
                        />
                      </td>
                      <td className="pe-0">
                        {index !== 0 && (
                          <button className="bg-transparent" onClick={() => deleteSlab(index)}>
                            <img src={Trash} alt="trash" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* Validation errors */}
            {validationErrors.map((error, index) => (
              <div key={index} className="text-danger">
                {error}
              </div>
            ))}
          </div>
          {/* Add Slab button */}
          <button className="btn btn-primary-light py-1 px-3 mt-2" onClick={addSlab}>
            + Add Slab
          </button>
        </div>
        {isEdit && (
          <div className="col-6 mb-3">
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input custom-checkbox"
                name="do_not_call_rto_lite_api"
                type="checkbox"
                id="flexCheckDefault"
                checked={partnerValue.do_not_call_rto_lite_api}
                onChange={e => onChangeInput(e)}
              />
              <label className="form-check-label ms-2 mt-1" htmlFor="flexCheckDefault">
                Do not use RTO Lite API
              </label>
            </div>
          </div>
        )}

        {!isEdit && (
          <div className="col-md-6 mb-4">
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input custom-checkbox"
                name="do_not_call_rto_lite_api"
                type="checkbox"
                id="flexCheckDefault"
                checked={partnerValue.do_not_call_rto_lite_api}
                onChange={e => onChangeInput(e)}
              />
              <label className="form-check-label ms-2 mt-1" htmlFor="flexCheckDefault">
                Do not use RTO Lite API
              </label>
            </div>
            <div className="mt-3">
              <label className="form-label fw-medium mb-1 fs-16px">Monthly Limit:</label>
              <span className="text-danger fs-16px">*</span>
              <input
                type="text"
                onChange={e => onChangeInput(e)}
                name="monthly_limit"
                className="form-control fw-medium fs-16px"
                value={partnerValue.monthly_limit}
              />
            </div>
            <span className="text-danger d-block fw-400 fs-small mt-2">
              {errors["monthly_limit"] ? errors["monthly_limit"] : null}
            </span>
          </div>
        )}

        <div className="col-12">
          <div className="d-flex gap-2 pt-3">
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
              {loading ? "Saving..." : "Save"}
            </button>
            <button onClick={e => navigate("/admin/partners")} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>

      {showAPIModal && (
        <ApiKeyModal
          handleClose={handleClose}
          apiKey={apiKey}
          show={showAPIModal}
          isEditName={"API Key Regenerated Successfully"}
          name={"Partner Created Successfully"}
          isEdit={isEdit}
          isPartner={false}
        />
      )}

      {regenerateAPI && (
        <ReGenerateAPIModal
          show={regenerateAPI}
          handleClose={handleCloseRegenerateModal}
          handleSubmit={regenerateAPICall}
        />
      )}

      {showAPIHistoryModal && (
        <ApiHistoryModal
          id={id}
          show={showAPIHistoryModal}
          handleClose={() => setShowAPIHistoryModal(false)}
        />
      )}
    </>
  );
};

export default PartnerForm;
