import React from "react";
import { Button } from "react-bootstrap";

const PrimaryButton = ({
  name,
  onClick,
  type = "button",
  btnClasses,
  disabled = false,
  loading = false,
  loadingName = "",
  variant,
}) => {
  return (
    <>
      <Button
        variant={variant}
        type={type}
        className={`${btnClasses}`}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {loading ? loadingName : name}
      </Button>
    </>
  );
};

export default PrimaryButton;
