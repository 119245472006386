import { partnerAction } from "../../../constants";

const apiKeyReducer = (state = [], action) => {
  switch (action.type) {
    case partnerAction.FETCH_API_KEY:
      return action.payload;
    default:
      return state;
  }
};

export default apiKeyReducer;
