import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../assets/css/dashboard.css";
import MasterLayout from "../../components/MasterLayout";
import DashboardWidgets from "./DashboardWidgets";
import Statistics from "./Statistics";
import {
  getDashboardChartDataAction,
  getDashboardRevenueChartDataAction,
  getDashboardTableStatisticsAction,
  getDashboardWidgetDetailsAction,
} from "../../store/action/admin/dashboardAction";
import StackedBarChart from "../../components/shared/chart/StackedBarChart";
import BarChart from "../../components/shared/chart/BarChart";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import { formatChartLabels } from "../../components/shared/sharedMethod";
import PageHeader from "../../components/PageHeader";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { tableData, statisticsData, isTableDataLoaded } = useSelector(
    state => state.dashboardDetails,
  );
  const { chartData, revenueChartData } = useSelector(state => state.dashboardChart);

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return startDate;
    })(),
    new Date(),
  ]);

  const handleDateSelect = useCallback(dateRange => {
    setSelectedDates(dateRange);
  }, []);

  const fetchDashboardData = useCallback(() => {
    const startDate = selectedDates[0] ? selectedDates[0] : "";
    const endDate = selectedDates[1] ? selectedDates[1] : "";

    dispatch(getDashboardWidgetDetailsAction(startDate, endDate));
    dispatch(getDashboardTableStatisticsAction(startDate, endDate));
  }, [dispatch, selectedDates]);

  const fetchChartData = useCallback(() => {
    const startDate = selectedDates[0] ? selectedDates[0] : "";
    const endDate = selectedDates[1] ? selectedDates[1] : "";

    dispatch(getDashboardChartDataAction(startDate, endDate));
    dispatch(getDashboardRevenueChartDataAction(startDate, endDate));
  }, [dispatch, selectedDates]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  useEffect(() => {
    if (isTableDataLoaded) {
      fetchChartData();
    }
  }, [isTableDataLoaded, fetchChartData]);

  const numberOfCalls = [
    {
      label: "Successful",
      backgroundColor: "#08A975",
      data: chartData?.numOfCalls?.datasets?.[0]?.data,
    },
    {
      label: "Failed",
      backgroundColor: "#EC6969",
      data: chartData?.numOfCalls?.datasets?.[2]?.data,
    },
  ];

  const numberOfCallsByType = [
    {
      label: "Live",
      backgroundColor: "#08A975",
      data: chartData?.numOfCallsByType?.datasets?.[0]?.data,
    },
    {
      label: "Cached",
      backgroundColor: "#FF9900",
      data: chartData?.numOfCallsByType?.datasets?.[1]?.data,
    },
    {
      label: "Lite",
      backgroundColor: "#1F77B4",
      data: chartData?.numOfCallsByType?.datasets?.[2]?.data,
    },
  ];

  const revenueCostChartData = [
    {
      label: "Cost",
      data: revenueChartData?.datasets?.[0]?.data,
      backgroundColor: "#FF9900",
      borderRadius: 5,
    },
    {
      label: "Revenue",
      data: revenueChartData?.datasets?.[1]?.data,
      backgroundColor: "#08A975",
      borderRadius: 5,
    },
    {
      label: "Profit",
      data: revenueChartData?.datasets?.[2]?.data,
      backgroundColor: "#1F77B4",
      borderRadius: 5,
    },
  ];

  const revenueChartDataFormatted = {
    labels: formatChartLabels(revenueChartData?.labels),
    datasets: revenueCostChartData,
  };

  return (
    <MasterLayout>
      <TabTitle title={"Dashboard"} />
      <div className="dashboard-page py-30px">
        <div className="container">
          <PageHeader
            title={"Partner’s Statistics"}
            isSelectionPart
            isDatePicker
            onDateSelect={handleDateSelect}
            isTopPagination={false}
          />
          <DashboardWidgets data={tableData.partner?.[0]} isVendor={false} isTooltip={true} />
          <div className="section-heading d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
            <h2 className="mb-0 fw-semibold">Vendor’s Statistics</h2>
          </div>
          <DashboardWidgets data={tableData.vendor?.[0]} isVendor={true} isTooltip={true} />
          <div className="chart-section">
            <div className="row">
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">Partner’s No. of Calls</h2>
                  <StackedBarChart
                    id={1}
                    data={numberOfCalls}
                    labels={formatChartLabels(chartData?.numOfCalls?.labels)}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">
                    Partner’s No. of Calls by Type
                  </h2>
                  <StackedBarChart
                    id={2}
                    data={numberOfCallsByType}
                    labels={formatChartLabels(chartData?.numOfCallsByType?.labels)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Statistics
            statisticsFor={"partner"}
            name={"Partners Statistics"}
            columns={[
              "Partner Name",
              "No. of Calls",
              "Successful",
              "Failed",
              "Live",
              "Cached",
              "Lite",
              "Cost",
              "Revenue",
              "Net Revenue",
            ]}
            isPercentage
            statisticsData={statisticsData?.partner}
          />
          <Statistics
            statisticsFor={"vendor"}
            isPercentage
            name={"Vendors Statistics"}
            columns={[
              "Vendor Name",
              "No. of Calls",
              "Failed",
              "Total Successful",
              "Insufficient",
              "Successful",
              "Live",
              "Cached",
              "Lite",
              "Cost",
              "Revenue",
              "Net Revenue",
            ]}
            statisticsData={statisticsData?.vendor}
          />
          <div className="bars-section mb-4 pb-1">
            <div className="row">
              <div className="col-12">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">Revenue vs Cost</h2>
                  <BarChart data={revenueChartDataFormatted} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

export default Dashboard;
