import React, { useEffect, useState, useCallback } from "react";
import PartnerWidgets from "../partners/PartnerWidgets";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "../../assets/css/partners.css";
import StackedBarChart from "../../components/shared/chart/StackedBarChart";
import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PageHeader from "../../components/PageHeader";
import {
  fetchPartnerDashboardChart,
  fetchPartnerDashboardSource,
  fetchPartnerDashboardWidget,
} from "../../store/action/admin/partnerAction";
import Statistics from "../dashboard/Statistics";
import { formatChartLabels } from "../../components/shared/sharedMethod";
import logIcon from "../../assets/images/log-icon.svg";

const PartnersDashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partnerName = localStorage.getItem("partnerName");

  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);

  useEffect(() => {
    setSelectedPartner(localStorage.getItem("partnerId"));
    setSelectedPartnerName(localStorage.getItem("partnerName"));

    window.scrollTo(0, 0);
  }, []);

  const { partnerDashboardWidget, partnerDashboardSource, partnerDashboardChart } = useSelector(
    state => state.partners,
  );

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return startDate;
    })(),
    new Date(),
  ]);

  const handlePartnerSelect = useCallback(partner => {
    setSelectedPartner(partner.value);
    setSelectedPartnerName(partner.label);
  }, []);

  const handleDateSelect = useCallback(dateRange => {
    setSelectedDates(dateRange);
  }, []);

  const fetchData = useCallback(() => {
    if (!selectedPartner || !selectedDates) return;
    const filter = {
      start_date: selectedDates[0] || "",
      end_date: selectedDates[1] || "",
    };
    const partnerId = selectedPartner || id;
    dispatch(fetchPartnerDashboardSource(filter, partnerId));
    dispatch(fetchPartnerDashboardChart(filter, partnerId));
    dispatch(fetchPartnerDashboardWidget(filter, partnerId));
  }, [dispatch, id, selectedDates, selectedPartner]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const goToButtonHandle = () => {
    localStorage.removeItem("partnerName");
    localStorage.removeItem("partnerId");
    localStorage.setItem("partnerName", selectedPartnerName);
    localStorage.setItem("partnerId", selectedPartner || id);
    navigate(`/admin/partners/${selectedPartner || id}/logs`);
  };

  const numberOfCalls = [
    {
      label: "Successful",
      backgroundColor: "#08A975",
      data: partnerDashboardChart?.partnerNumOfCalls?.datasets?.[0]?.data || [],
    },
    {
      label: "Failed",
      backgroundColor: "#EC6969",
      data: partnerDashboardChart?.partnerNumOfCalls?.datasets?.[2]?.data || [],
    },
  ];

  const numberOfCallsByType = [
    {
      label: "Live",
      backgroundColor: "#08A975",
      data: partnerDashboardChart?.partnerNumOfCallsByType?.datasets?.[0]?.data || [],
    },
    {
      label: "Cached",
      backgroundColor: "#FF9900",
      data: partnerDashboardChart?.partnerNumOfCallsByType?.datasets?.[1]?.data || [],
    },
    {
      label: "Lite",
      backgroundColor: "#1F77B4",
      data: partnerDashboardChart?.partnerNumOfCallsByType?.datasets?.[2]?.data || [],
    },
  ];

  return (
    <MasterLayout>
      <TabTitle title={`${partnerName}'s Statistics`} />
      <div className="dashboard-page py-30px">
        <div className="container">
          <PageHeader
            title={`${selectedPartnerName ? selectedPartnerName : partnerName}'s Statistics`}
            isDatePicker
            onDateSelect={handleDateSelect}
            isSelectionPart
            isBackButtonLast
            backLink={"/admin/partners"}
            isTopPagination={false}
            isTitleSelectable={true}
            isPartnerOptions
            handleTitleChange={handlePartnerSelect}
            navigationButtonText={<img src={logIcon} alt="log" />}
            navigationHandle={goToButtonHandle}
            isNavigationButton
            selectedPartnerOption={{
              value: selectedPartner,
              label: selectedPartnerName,
            }}
            isDashboard
          />
          <PartnerWidgets data={partnerDashboardWidget?.[0]} isTooltip={true} isAdmin />
          <div className="chart-section">
            <div className="row">
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls</h2>
                  <StackedBarChart
                    id={1}
                    data={numberOfCalls}
                    labels={formatChartLabels(partnerDashboardChart?.partnerNumOfCalls?.labels)}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4 pb-1">
                <div className="bg-white chart-wrapper p-4 position-relative">
                  <h2 className="fs-18px fw-semibold mb-0 chart-heading">No. of Calls by Type</h2>
                  <StackedBarChart
                    id={2}
                    data={numberOfCallsByType}
                    labels={formatChartLabels(
                      partnerDashboardChart?.partnerNumOfCallsByType?.labels,
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="source-data-section mb-4 pb-1">
            <Statistics
              customClass="partner-dashboard-statastics"
              style={{ maxHeight: "1260px", overflowY: "auto" }}
              statisticsFor={"other"}
              name={"Source Data"}
              isPercentage
              columns={[
                "No.",
                "Date",
                "No. of Calls",
                "Failed",
                "Successful",
                "Live",
                "Cached",
                "Lite",
                "Cost",
                "Revenue",
                "Net Revenue",
              ]}
              statisticsData={partnerDashboardSource}
              isStatisticsForPartner={true}
              isLink
              navigationPath={`/admin/partners/${id}/logs`}
            />
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

export default PartnersDashboard;
