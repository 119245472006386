import apiConfig from "../../../config/apiConfig";
import { adminBaseURL, adminActionType } from "../../../constants";
import requestParam from "../../../components/shared/requestParam";
import { setTotalRecord } from "../totalRecordAction";
import _ from "lodash";
import toast from "react-hot-toast";

export const fetchPartnerLogs =
  (filter = {}) =>
  async dispatch => {
    let url = adminBaseURL.PARTNER_LOGS;

    if (!_.isEmpty(filter)) {
      url += requestParam(filter, url);
    }
    apiConfig
      .get(url)
      .then(response => {
        dispatch({
          type: adminActionType.FETCH_PARTNER_LOGS,
          payload: response.data.data,
        });
        dispatch(
          setTotalRecord(
            response.data.total !== undefined && response.data.total >= 0 ? response.data.total : 0,
          ),
        );
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
      });
  };

export const fetchPartnerLogDetails = (id, navigate) => async dispatch => {
  await apiConfig
    .get(`${adminBaseURL.PARTNER_LOGS}/${id}`)
    .then(response => {
      dispatch({
        type: adminActionType.FETCH_PARTNER_LOGS_DETAILS,
        payload: response.data.data,
      });
    })
    .catch(({ response }) => {
      navigate("/admin/partners-logs");
      toast.error(response.data.message);
    });
};
