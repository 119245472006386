import { useRef, useState } from "react";
import ReactMultiDatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const DatePicker = ({ onDateSelect }) => {
  const ref = useRef();
  const today = new Date();
  const currentYear = today.getFullYear();

  const financialYearStart = new Date(currentYear, 3, 1);
  const financialYearEnd = new Date(currentYear + 1, 2, 31);

  const [selectedDates, setSelectedDates] = useState([financialYearStart, financialYearEnd]);

  const handleDateChange = dates => {
    if (dates.length === 2) {
      const [startDateString, endDateString] = dates;
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      const adjustedEndDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
      setSelectedDates([startDate, adjustedEndDate]);
      onDateSelect([startDate, adjustedEndDate]);
      ref.current.closeCalendar();
    }
  };

  return (
    <div className="position-relative ms-auto rangepicker">
      <div className="date-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M18 5.25V6H0V5.25C0 3.17925 1.67925 1.5 3.75 1.5H4.5V0.75C4.5 0.336 4.836 0 5.25 0C5.664 0 6 0.336 6 0.75V1.5H12V0.75C12 0.336 12.336 0 12.75 0C13.164 0 13.5 0.336 13.5 0.75V1.5H14.25C16.3207 1.5 18 3.17925 18 5.25ZM18 12.75C18 15.645 15.6442 18 12.75 18C9.85575 18 7.5 15.645 7.5 12.75C7.5 9.855 9.85575 7.5 12.75 7.5C15.6442 7.5 18 9.855 18 12.75ZM14.25 13.1895L13.5 12.4395V11.25C13.5 10.836 13.164 10.5 12.75 10.5C12.336 10.5 12 10.836 12 11.25V12.75C12 12.9487 12.0787 13.14 12.2197 13.2803L13.1895 14.25C13.482 14.5425 13.9575 14.5425 14.25 14.25C14.5425 13.9575 14.5425 13.482 14.25 13.1895ZM6 12.75C6 10.6283 6.981 8.7375 8.51175 7.5H0V14.25C0 16.3207 1.67925 18 3.75 18H8.51175C6.981 16.7625 6 14.8717 6 12.75Z"
            fill="#456DFF"
          />
        </svg>
      </div>
      <ReactMultiDatePicker
        ref={ref}
        onChange={handleDateChange}
        format="MMM YYYY"
        dateSeparator=" to "
        value={selectedDates}
        onlyMonthPicker
        range
        plugins={[<DatePanel />]}
        editable={false}
        highlightToday={false}
        onOpenPickNewDate={false}
      />
    </div>
  );
};

export default DatePicker;
