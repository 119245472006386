import moment from "moment";
import { Filters } from "../../constants";

const requestParam = (filters = Filters.OBJ, baseUrl = "?") => {
  let url = baseUrl?.indexOf("?") > 0 ? "" : "?";

  if (filters.order_By !== "") {
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    if (filters.direction === "asc") {
      url = `${url}sort=${filters.order_By}`;
    } else if (filters.direction === "desc") {
      url = `${url}sort=-${filters.order_By}`;
    }
  }

  if (filters.limit > 0) {
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url = `${url}limit=${filters.limit}`;
  }
  if (filters.page > 0) {
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url = `${url}page=${filters.page}`;
  }
  if (filters.search !== "" && filters.search !== undefined) {
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url += `search=${filters.search}`;
  }
  if (filters.start_date && filters.end_date) {
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    const fromDate = moment(filters.start_date).format("YYYY-MM-DD");
    const toDate = moment(filters.end_date).format("YYYY-MM-DD");

    url = `${url}from_date=${fromDate}&to_date=${toDate}`;
  }
  if (filters.status !== "" && filters.status !== undefined && filters.status !== null) {
    let status = "";
    if (filters.status === "all") {
      status = "";
    } else {
      status = filters.status;
    }
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    if (filters.isInvoice) {
      url += `type=${status}`;
    } else {
      url += `status=${status}`;
    }
  }

  if (filters.data_type !== "" && filters.data_type !== undefined && filters.data_type !== null) {
    let data_type = "";
    if (filters.data_type === "all") {
      data_type = "";
    } else {
      data_type = filters.data_type;
    }
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url += `data_type=${data_type}`;
  }

  if (
    filters.partner_id !== "" &&
    filters.partner_id !== undefined &&
    filters.partner_id !== null
  ) {
    let partner_id = "";
    if (filters.partner_id === "all") {
      partner_id = "";
    } else {
      partner_id = filters.partner_id;
    }
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url += `partner_id=${partner_id}`;
  }

  if (
    filters.vendor_id !== "" &&
    filters.vendor_id !== undefined &&
    filters.vendor_id !== null &&
    filters.vendor_id !== "all"
  ) {
    let vendor_id = "";
    if (filters.vendor_id === "all") {
      vendor_id = "";
    } else {
      vendor_id = filters.vendor_id;
    }
    if (baseUrl.indexOf("?") > 0 || (url.includes("?") && url.length > 1)) {
      url += "&";
    }
    url += `vendor_id=${vendor_id}`;
  }

  return url;
};

export default requestParam;
