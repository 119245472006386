import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const BottomTab = ({
  headerDetails,
  requestDetails,
  responseDetails,
  notIsHeader = false,
  className,
}) => {
  const [key, setKey] = useState(notIsHeader ? "request" : "headers");
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      })
      .catch(error => console.error("Failed to copy:", error));
  };

  const renderKeyValuePairs = details => {
    if (!details) return null;

    const parsedDetails = JSON.parse(details);

    if (typeof parsedDetails !== "object" || Array.isArray(parsedDetails)) {
      return (
        <div>
          <span className="text-success">{details}</span>
        </div>
      );
    }

    const renderValue = value => {
      if (typeof value === "object") {
        return JSON.stringify(value, null, 4);
      } else {
        return `"${value}"`;
      }
    };

    const keyValuePairs = Object.entries(parsedDetails).map(([key, value], index) => (
      <React.Fragment key={index}>
        <span className="text-green">
          {'"'}
          {key}
          {'"'}
        </span>
        : <span className="text-success">{renderValue(value)}</span>
        {index < Object.keys(parsedDetails).length - 1 && ","}
        {"\n"}
      </React.Fragment>
    ));

    return <div>{keyValuePairs}</div>;
  };

  return (
    <div className="mb-4 pb-1">
      <div className="wrapper bg-white">
        <div className={`editor-tabs ${className}`}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={k => setKey(k)}
            className="px-4 py-3 gap-2"
          >
            {!notIsHeader && (
              <Tab eventKey="headers" title="Headers" className="position-relative">
                <pre className="mb-0">
                  {"{"}
                  {"\n"}
                  {renderKeyValuePairs(headerDetails)}
                  {"}"}
                </pre>
                <div className="position-absolute top-0 right-0">
                  <button
                    className="bg-transparent border-0 p-0 text-end"
                    onClick={() => copyCodeToClipboard(headerDetails)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="25"
                      viewBox="0 0 22 25"
                      fill="none"
                    >
                      <path
                        d="M10.2143 0H16.3085C16.9321 0 17.5312 0.249023 17.9732 0.688477L21.3076 4.00391C21.7496 4.44336 22 5.03906 22 5.65918V16.4062C22 17.7002 20.9442 18.75 19.6429 18.75H10.2143C8.91295 18.75 7.85714 17.7002 7.85714 16.4062V2.34375C7.85714 1.0498 8.91295 0 10.2143 0ZM2.35714 6.25H6.28571V9.375H3.14286V21.875H12.5714V20.3125H15.7143V22.6562C15.7143 23.9502 14.6585 25 13.3571 25H2.35714C1.0558 25 0 23.9502 0 22.6562V8.59375C0 7.2998 1.0558 6.25 2.35714 6.25Z"
                        fill="#FF9900"
                      />
                    </svg>
                  </button>
                  {copied && (
                    <p
                      className="text-success mb-0 position-absolute fs-14px fw-medium"
                      style={{ top: "30px", right: "0" }}
                    >
                      Copied!
                    </p>
                  )}
                </div>
              </Tab>
            )}
            <Tab eventKey="request" title="Request" className="position-relative">
              <pre className="mb-0">
                {"{"}
                {"\n"}
                {renderKeyValuePairs(requestDetails)}
                {"}"}
              </pre>
              <div className="position-absolute top-0 right-0">
                <button
                  className="bg-transparent border-0 p-0 text-end"
                  onClick={() => copyCodeToClipboard(requestDetails)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="25"
                    viewBox="0 0 22 25"
                    fill="none"
                  >
                    <path
                      d="M10.2143 0H16.3085C16.9321 0 17.5312 0.249023 17.9732 0.688477L21.3076 4.00391C21.7496 4.44336 22 5.03906 22 5.65918V16.4062C22 17.7002 20.9442 18.75 19.6429 18.75H10.2143C8.91295 18.75 7.85714 17.7002 7.85714 16.4062V2.34375C7.85714 1.0498 8.91295 0 10.2143 0ZM2.35714 6.25H6.28571V9.375H3.14286V21.875H12.5714V20.3125H15.7143V22.6562C15.7143 23.9502 14.6585 25 13.3571 25H2.35714C1.0558 25 0 23.9502 0 22.6562V8.59375C0 7.2998 1.0558 6.25 2.35714 6.25Z"
                      fill="#FF9900"
                    />
                  </svg>
                </button>
                {copied && (
                  <p
                    className="text-success mb-0 position-absolute fs-14px fw-medium"
                    style={{ top: "30px", right: "0" }}
                  >
                    Copied!
                  </p>
                )}
              </div>
            </Tab>
            <Tab eventKey="response" title="Response" className="position-relative">
              <pre className="mb-0">
                {"{"}
                {"\n"}
                {renderKeyValuePairs(responseDetails)}
                {"}"}
              </pre>
              <div className="position-absolute top-0 right-0">
                <button
                  className="bg-transparent border-0 p-0 text-end"
                  onClick={() => copyCodeToClipboard(responseDetails)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="25"
                    viewBox="0 0 22 25"
                    fill="none"
                  >
                    <path
                      d="M10.2143 0H16.3085C16.9321 0 17.5312 0.249023 17.9732 0.688477L21.3076 4.00391C21.7496 4.44336 22 5.03906 22 5.65918V16.4062C22 17.7002 20.9442 18.75 19.6429 18.75H10.2143C8.91295 18.75 7.85714 17.7002 7.85714 16.4062V2.34375C7.85714 1.0498 8.91295 0 10.2143 0ZM2.35714 6.25H6.28571V9.375H3.14286V21.875H12.5714V20.3125H15.7143V22.6562C15.7143 23.9502 14.6585 25 13.3571 25H2.35714C1.0558 25 0 23.9502 0 22.6562V8.59375C0 7.2998 1.0558 6.25 2.35714 6.25Z"
                      fill="#FF9900"
                    />
                  </svg>
                </button>
                {copied && (
                  <p
                    className="text-success mb-0 position-absolute fs-14px fw-medium"
                    style={{ top: "30px", right: "0" }}
                  >
                    Copied!
                  </p>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default BottomTab;
