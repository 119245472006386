import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import PageHeader from "../../components/PageHeader";
import ListTable from "../../components/tables/ListTable";
import { fetchLogs } from "../../store/action/partner/logsAction";
import { classNameAndStatus, getDataType, timeAgo } from "../../components/shared/sharedMethod";
import { dataTypeWithMixedOptions, statusWithLiteOptions } from "../../constants";
import { exportLogs } from "../../store/action/excelExportAction";
import TopProgressBar from "../../components/shared/TopProgressBar";
import moment from "moment/moment";

const columns = [
  {
    id: 1,
    label: "Status Code",
    name: "status_code",
    customData: item => (
      <span className={item.status_code === 200 ? "successful-badge badge" : "failed-badge badge"}>
        {item.status_code}
      </span>
    ),
  },
  {
    id: 3,
    className: "text-center",
    containerClass: "text-center",
    label: "RC No.",
    name: "reg_no",
  },
  {
    id: 4,
    className: "text-center",
    containerClass: "text-center",
    label: "Status",
    name: "status",
    customData: item => {
      if (item.status) {
        const { className, text } = classNameAndStatus(item);
        return <span className={className}>{text}</span>;
      }
    },
  },
  {
    id: 5,
    label: "Req. Type",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      const dataTypeText = getDataType(item?.data_type);
      return dataTypeText;
    },
  },
  {
    id: 11,
    label: "Res. Type",
    className: "text-center",
    containerClass: "text-center",
    customData: item => {
      const dataTypeText = getDataType(item?.response_type);
      return dataTypeText;
    },
  },
  {
    id: 6,
    label: "Cost",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>₹{item.cost}</span>,
  },
  {
    id: 8,
    label: "Duration",
    className: "text-center",
    containerClass: "text-center",
    customData: item => <span>{item.duration}ms</span>,
  },
  {
    id: 9,
    label: "Happened",
    className: "text-center",
    containerClass: "text-center",
    customData: item => (
      <span>
        <div className="mb-1">
          {timeAgo(item.created_at).formattedDate}
          {timeAgo(item.created_at).time}
        </div>
      </span>
    ),
  },
  {
    id: 10,
    label: "",
    className: "text-end",
    customData: item => (
      <Link to={`/partner/logs/${item.public_id}`} className="link-arrow ms-auto" target="_blank">
        <BsChevronRight />
      </Link>
    ),
  },
];

const PartnerLogs = () => {
  const dispatch = useDispatch();
  const { partnerLog, totalRecord } = useSelector(state => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const totalPages = Math.ceil(totalRecord / perPage);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDataTypeStatus, setSelectedDataTypeStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedDate = queryParams.get("date");
  const parsedDate = moment(selectedDate, "DD-MM-YYYY");

  const [selectedDates, setSelectedDates] = useState([
    (() => {
      const startDate = new Date();
      startDate.setDate(1);
      return parsedDate.isValid() ? parsedDate : startDate;
    })(),
    parsedDate.isValid() ? parsedDate : new Date(),
  ]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleDateSelect = dateRange => {
    setSelectedDates(dateRange);
    setCurrentPage(1);
  };

  const handleStatusSelect = selectedOption => {
    setSelectedStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const filter = {
    page: currentPage,
    limit: perPage,
    start_date: selectedDates && selectedDates[0] ? selectedDates[0] : "",
    end_date: selectedDates && selectedDates[1] ? selectedDates[1] : "",
    status: selectedStatus,
    data_type: selectedDataTypeStatus,
    search: searchTerm.trim(),
  };

  const fetchPartnerLogs = () => {
    dispatch(fetchLogs(filter));
  };

  useEffect(() => {
    fetchPartnerLogs();
  }, [
    currentPage,
    perPage,
    selectedDates,
    selectedStatus,
    selectedDataTypeStatus,
    searchTerm,
    dispatch,
  ]);

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(currentPage > 1 ? currentPage : 1);
  };

  const handleNextPage = i => {
    setCurrentPage(i);
  };

  const handlePrevPage = i => {
    setCurrentPage(i);
  };

  const onDataTypeSelect = selectedOption => {
    setSelectedDataTypeStatus(selectedOption.value);
    setCurrentPage(1);
  };

  const handleLimitChange = selectedOption => {
    setPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const onClickExport = e => {
    e.preventDefault();
    const jsonPayload = { fields: ["reg_no", "request_type", "response_type"] };
    dispatch(exportLogs("", "partner", filter, setLoading, false, jsonPayload));
  };

  return (
    <>
      <MasterLayout>
        <TopProgressBar isLoading={loading} />
        <TabTitle title={"Logs"} />
        <div className="log-page py-30px">
          <div className="container">
            <PageHeader
              title={"Logs"}
              isSelect
              isDatePicker
              isSelectionPart
              onStatusSelect={handleStatusSelect}
              onDateSelect={handleDateSelect}
              isDataTypeSelect
              onDataTypeSelect={onDataTypeSelect}
              options={statusWithLiteOptions}
              dataTypeCustomOptions={dataTypeWithMixedOptions}
              onLimitChange={handleLimitChange}
              paginationLimit={perPage}
              selectedDates={selectedDates}
            />
            <ListTable
              onSearch={handleSearch}
              currentPage={currentPage}
              totalRecord={totalRecord}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              setPerPage={setPerPage}
              perPage={perPage}
              isSearch
              isPagination
              columns={columns}
              data={partnerLog}
              tableClass={"logs-table"}
              placeholder="Search by RC No."
              isExport
              onClickExport={onClickExport}
              isClickableRow
              clickableRowPath="/partner/logs"
            />
          </div>
        </div>
      </MasterLayout>
    </>
  );
};

export default PartnerLogs;
