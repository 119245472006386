import React, { useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PageHeader from "../../components/PageHeader";
import "../../assets/css/partners.css";
import Checked from "../../assets/images/checked.svg";
import Trash from "../../assets/images/trash-icon.svg";
import { createVendor, editVendor } from "../../store/action/admin/vendorAction";
import MasterLayout from "../../components/MasterLayout";
import "react-multi-email/dist/style.css";
import { Tooltip } from "react-tooltip";
import { renderToolTipTag } from "../../components/shared/Widget";

const VendorForm = ({ id, isEdit = false, singleVendor, supportedDataTypes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(true);
  const [changeConfirmPassword, setChangeConfirmPassword] = useState(true);

  const changeIcon = changePassword === true ? false : true;
  const changeConfirmIcon = changeConfirmPassword === true ? false : true;

  const [vendorValue, setVendorValue] = useState({
    full_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    failed_threshold: "",
    accepted_data_type: [],
    store_cached_data: false,
    vendors_slabs: [],
    phone_number: "",
    address: "",
    alternate_emails: [],
    insufficient_threshold: "",
    api_identity: "",
  });

  const [emails, setEmails] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const [slabs, setSlabs] = useState([
    {
      calls: "",
      live_price: "",
      cached_price: "",
      ...(singleVendor?.user_name === "rto" && { lite_price: "" }),
      start_value: 0,
      end_value: 0,
    },
  ]);

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    failed_threshold: "",
    accepted_data_type: "",
  });

  useEffect(() => {
    if (singleVendor !== undefined && singleVendor !== null) {
      setVendorValue({
        full_name: singleVendor.full_name,
        email: singleVendor.email,
        accepted_data_type: singleVendor.accepted_data_type,
        failed_threshold: singleVendor.failed_threshold,
        insufficient_threshold: singleVendor.insufficient_threshold,
        store_cached_data: singleVendor.store_cached_data,
        phone_number: singleVendor.phone_number,
        address: singleVendor.address,
        alternate_emails: singleVendor.alternate_emails || [],
      });
      setEmails(singleVendor.alternate_emails || []);
      const mappedSlabs =
        singleVendor &&
        singleVendor.vendors_slabs &&
        singleVendor.vendors_slabs.map((slab, index) => {
          return {
            ...slab,
            lite_price: slab.lite_price ?? 0,
            calls: slab.end_value.toString(),
          };
        });

      setSlabs(mappedSlabs);
    }
  }, [singleVendor]);

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (vendorValue["full_name"].trim() === "") {
      errorss["full_name"] = "Please enter vendor name";
    } else if (!EmailValidator.validate(vendorValue["email"])) {
      if (!vendorValue["email"]) {
        errorss["email"] = "Please enter email address";
      } else {
        errorss["email"] = "Please enter valid email address";
      }
    } else if (!isEdit && !vendorValue["password"]) {
      errors["password"] = "Please enter a password";
    } else if (!isEdit && !vendorValue["password_confirmation"]) {
      errors["password_confirmation"] = "Please enter a confirm password";
    } else if (!isEdit && vendorValue["password"] !== vendorValue["password_confirmation"]) {
      errors["password_confirmation"] = "Password and confirm password must be the same";
    } else if (String(vendorValue["failed_threshold"]).trim() === "") {
      errorss["failed_threshold"] = "Please enter a failed threshold";
    } else if (vendorValue["failed_threshold"] > 100) {
      errorss["failed_threshold"] = "Failed threshold must be less than 100";
    } else if (String(vendorValue["insufficient_threshold"]).trim() === "") {
      errorss["insufficient_threshold"] = "Please enter a insufficient threshold";
    } else if (vendorValue["insufficient_threshold"] > 100) {
      errorss["insufficient_threshold"] = "Insufficient threshold must be less than 100";
    } else if (String(vendorValue["phone_number"]).trim() === "") {
      errorss["phone_number"] = "Please enter a phone number";
    } else if (!/^\d+$/.test(vendorValue["phone_number"])) {
      errorss["phone_number"] = "Phone number must contain only numbers";
    } else if (vendorValue["phone_number"].length > 15) {
      errorss["phone_number"] = "Phone number should be less than 15 numbers";
    } else if (!vendorValue["address"] || vendorValue["address"].trim() === "") {
      errorss["address"] = "Please enter an address";
    } else if (
      !isEdit &&
      (!vendorValue["api_identity"] || vendorValue["api_identity"].trim() === "")
    ) {
      errorss["api_identity"] = "Please enter an api identity";
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const onChangeInput = e => {
    const { name, value, checked } = e.target;

    if (name === "accepted_data_type") {
      let updatedDataTypes = [...vendorValue.accepted_data_type];
      if (isEdit && supportedDataTypes.length !== 4) {
        updatedDataTypes = [value];
      } else {
        if (value === "3" && checked) {
          updatedDataTypes = ["3"];
        } else if (checked) {
          updatedDataTypes = updatedDataTypes.filter(item => item !== "3");
          updatedDataTypes.push(value);
        } else {
          updatedDataTypes = updatedDataTypes.filter(item => item !== value);
        }
      }
      setVendorValue(prevState => ({
        ...prevState,
        [name]: updatedDataTypes,
      }));
    } else {
      setVendorValue(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const addSlab = () => {
    setSlabs([...slabs, { calls: "", live_price: "", cached_price: "" }]);
  };

  const handleSlabChange = (index, field, value) => {
    const updatedSlabs = [...slabs];

    while (updatedSlabs.length <= index) {
      const previousSlab = updatedSlabs[index - 1] || {};
      updatedSlabs.push({
        calls: "",
        live_price: "",
        cached_price: "",
        ...(singleVendor?.user_name === "rto" && { lite_price: "" }),
        start_value: index === 0 ? 0 : (previousSlab.end_value || 0) + 1,
        end_value: "",
      });
    }

    updatedSlabs[index][field] = value ? value : 0;

    if (field === "calls") {
      updatedSlabs[index].end_value = parseInt(value);

      if (index > 0 && parseInt(value) <= parseInt(updatedSlabs[index - 1].calls)) {
        setValidationErrors([
          "Calls value must be strictly greater than the previous slab's calls value",
        ]);
      } else {
        setValidationErrors([]);
      }

      for (let i = index + 1; i < updatedSlabs.length; i++) {
        updatedSlabs[i].start_value = parseInt(updatedSlabs[i - 1].end_value) + 1;
      }
    }

    for (let i = 0; i < updatedSlabs.length; i++) {
      if (i === 0) {
        updatedSlabs[i].start_value = 0;
      } else {
        updatedSlabs[i].start_value = parseInt(updatedSlabs[i - 1].end_value) + 1;
      }
    }

    updatedSlabs.forEach(slab => {
      slab.live_price = slab.live_price || "";
      slab.cached_price = slab.cached_price || "";
      if (singleVendor?.user_name === "rto") {
        slab.lite_price = slab.lite_price || "";
      } else {
        slab.lite_price = 0;
      }
    });

    setSlabs(updatedSlabs);

    const vendorsSlabData = updatedSlabs.map(slab => ({
      start_value: slab.start_value,
      end_value: slab.end_value,
      live_price: slab.live_price,
      cached_price: slab.cached_price,
      ...(singleVendor?.user_name === "rto" && {
        lite_price: slab.lite_price ? slab.lite_price : 0,
      }),
    }));

    setVendorValue({ ...vendorValue, vendors_slabs: vendorsSlabData });
  };

  const deleteSlab = index => {
    const updatedSlabs = [...slabs];
    updatedSlabs.splice(index, 1);
    setSlabs(updatedSlabs);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const valid = handleValidation();
    let alternate_emails = vendorValue?.alternate_emails?.join(",");
    const acceptedDataString = vendorValue?.accepted_data_type.join(",");
    if (valid) {
      const vendorData = {
        full_name: vendorValue.full_name,
        accepted_data_type: acceptedDataString,
        failed_threshold: parseInt(vendorValue.failed_threshold),
        insufficient_threshold: parseInt(vendorValue.insufficient_threshold),
        store_cached_data: vendorValue.store_cached_data,
        email: vendorValue.email,
        vendors_slabs: slabs,
        alternative_email: alternate_emails,
        phone_number: vendorValue.phone_number,
        address: vendorValue.address,
      };
      setLoading(true);
      if (isEdit) {
        dispatch(editVendor(id, vendorData, navigate, setLoading));
      } else {
        dispatch(
          createVendor(
            {
              ...vendorValue,
              alternative_email: alternate_emails,
              accepted_data_type: acceptedDataString,
            },
            navigate,
            setLoading,
          ),
        );
      }
    }
  };

  return (
    <MasterLayout>
      <div className="new-partners-page py-30px">
        <div className="container">
          <PageHeader
            title={`${isEdit ? "Edit" : "Create"} Vendor`}
            isBackButton
            backLink={"/admin/vendors"}
          />
          <div className="wrapper">
            <div className="p-4">
              <div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-1 fs-16px">Vendor’s Name:</label>
                    <span className="text-danger fs-16px">*</span>
                    <input
                      type="text"
                      onChange={e => onChangeInput(e)}
                      name="full_name"
                      className="form-control fw-medium fs-16px"
                      placeholder="Vendor’s Name"
                      value={vendorValue.full_name}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["full_name"] ? errors["full_name"] : null}
                    </span>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-1 fs-16px">Email:</label>
                    <span className="text-danger fs-16px">*</span>
                    <input
                      type="email"
                      onChange={e => onChangeInput(e)}
                      name="email"
                      className="form-control fw-medium fs-16px"
                      placeholder="Email"
                      value={vendorValue.email}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["email"] ? errors["email"] : null}
                    </span>
                  </div>
                  {!singleVendor && (
                    <div className="col-md-6 mb-4">
                      <div>
                        <label className="form-label fw-medium mb-1 fs-16px">Password:</label>
                        <span className="text-danger fs-16px">*</span>
                        <div className="mb-2 position-relative">
                          <input
                            onChange={e => onChangeInput(e)}
                            name="password"
                            type={changePassword ? "password" : "text"}
                            className="form-control fw-medium fs-16px"
                          />
                          <span
                            onClick={() => {
                              setChangePassword(changeIcon);
                            }}
                            className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                          >
                            {changeIcon ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                      </div>
                      <span className="text-danger d-block fw-400 fs-small mt-2">
                        {errors["password"] ? errors["password"] : null}
                      </span>
                    </div>
                  )}
                  {!singleVendor && (
                    <div className="col-md-6 mb-4">
                      <div>
                        <label className="form-label fw-medium mb-1 fs-16px">
                          Confirm Password:
                        </label>
                        <span className="text-danger fs-16px">*</span>
                        <div className="mb-2 position-relative">
                          <input
                            onChange={e => onChangeInput(e)}
                            name="password_confirmation"
                            type={changeConfirmPassword ? "password" : "text"}
                            className="form-control fw-medium fs-16px"
                          />
                          <span
                            onClick={() => {
                              setChangeConfirmPassword(changeConfirmIcon);
                            }}
                            className="icon position-absolute d-flex align-items-center top-0 bottom-0 end-0  me-3 input-icon cursor-pointer text-gray-600 input-password-hide"
                          >
                            {changeConfirmIcon ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                      </div>
                      <span className="text-danger d-block fw-400 fs-small mt-2">
                        {errors["password_confirmation"] ? errors["password_confirmation"] : null}
                      </span>
                    </div>
                  )}
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-1 fs-16px">
                      Alternate Email (s):
                    </label>
                    <ReactMultiEmail
                      className="form-control"
                      placeholder="Alternate email (s)"
                      emails={emails}
                      onChange={updatedEmails => {
                        setEmails(updatedEmails);
                        setVendorValue(prevState => ({
                          ...prevState,
                          alternate_emails: updatedEmails,
                        }));
                      }}
                      validateEmail={isEmail}
                      getLabel={(email, index, removeEmail) => (
                        <div data-tag key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                          </span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label className="form-label fw-medium mb-1 fs-16px">Phone Number:</label>
                      <span className="text-danger fs-16px">*</span>
                      <input
                        type="number"
                        onChange={e => onChangeInput(e)}
                        name="phone_number"
                        className={`form-control fw-medium fs-16px`}
                        value={vendorValue.phone_number}
                        placeholder="Phone Number"
                      />
                    </div>
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["phone_number"] ? errors["phone_number"] : null}
                    </span>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-1 fs-16px">
                      Failed Threshold (In Percentage):
                    </label>
                    <span className="text-danger fs-16px">*</span>
                    <input
                      type="number"
                      className="form-control fw-medium fs-16px"
                      placeholder="0"
                      onChange={e => onChangeInput(e)}
                      name="failed_threshold"
                      value={vendorValue.failed_threshold}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["failed_threshold"] ? errors["failed_threshold"] : null}
                    </span>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-1 fs-16px">
                      Insufficient Threshold (In Percentage):
                    </label>
                    <span className="text-danger fs-16px">*</span>
                    <input
                      type="number"
                      className="form-control fw-medium fs-16px"
                      placeholder="0"
                      onChange={e => onChangeInput(e)}
                      name="insufficient_threshold"
                      value={vendorValue.insufficient_threshold}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["insufficient_threshold"] ? errors["insufficient_threshold"] : null}
                    </span>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div>
                      <label className="form-label fw-medium mb-1 fs-16px">Address:</label>
                      <span className="text-danger fs-16px">*</span>
                      <input
                        type="text"
                        onChange={e => onChangeInput(e)}
                        name="address"
                        className={`form-control fw-medium fs-16px`}
                        value={vendorValue.address}
                        placeholder="Address"
                      />
                    </div>
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["address"] ? errors["address"] : null}
                    </span>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label fw-medium mb-2 fs-16px">Use For:</label>
                    <div className="d-flex flex-wrap gap-2">
                      {!isEdit || supportedDataTypes?.includes(1) ? (
                        <div>
                          <input
                            type="checkbox"
                            id="radio1"
                            name="accepted_data_type"
                            className="select-radio"
                            checked={vendorValue?.accepted_data_type?.includes("1")}
                            onChange={e => onChangeInput(e)}
                            value={1}
                          />
                          <label htmlFor="radio1" className="data-type">
                            Live
                            <div className="checked-img">
                              <img src={Checked} alt="check" />
                            </div>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {!isEdit || supportedDataTypes?.includes(2) ? (
                        <div>
                          <input
                            type="checkbox"
                            id="radio2"
                            name="accepted_data_type"
                            className="select-radio"
                            value={2}
                            onChange={e => onChangeInput(e)}
                            checked={vendorValue?.accepted_data_type?.includes("2")}
                          />
                          <label htmlFor="radio2" className="data-type">
                            Cached
                            <div className="checked-img">
                              <img src={Checked} alt="check" />
                            </div>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {supportedDataTypes?.length !== 4 && supportedDataTypes?.includes(3) ? (
                        <div>
                          <input
                            type="checkbox"
                            id="radio3"
                            name="accepted_data_type"
                            className="select-radio"
                            checked={vendorValue?.accepted_data_type?.includes("3")}
                            onChange={e => onChangeInput(e)}
                            value={3}
                          />
                          <label htmlFor="radio3" className="data-type">
                            Both
                            <div className="checked-img">
                              <img src={Checked} alt="check" />
                            </div>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {!isEdit ||
                      (supportedDataTypes?.length === 4 && supportedDataTypes?.includes(4)) ? (
                        <div>
                          <input
                            type="checkbox"
                            id="radio3"
                            name="accepted_data_type"
                            className="select-radio"
                            checked={vendorValue?.accepted_data_type?.includes("4")}
                            onChange={e => onChangeInput(e)}
                            value={4}
                          />
                          <label htmlFor="radio3" className="data-type">
                            Lite
                            <div className="checked-img">
                              <img src={Checked} alt="check" />
                            </div>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["accepted_data_type"] ? errors["accepted_data_type"] : null}
                    </span>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="overflow-auto">
                      <table className="table table-borderless add-slab-table w-100 mb-0">
                        <thead>
                          <tr>
                            <th className="fw-medium mb-0 fs-16px ps-0">Slabs:</th>
                            <th className="text-gray-600 fw-medium mb-0 fs-16px">No. Of Calls</th>
                            <th className="text-gray-600 fw-medium mb-0 fs-16px">Live Price</th>
                            <th className="text-gray-600 fw-medium mb-0 fs-16px">Cache Price</th>
                            {singleVendor?.user_name === "rto" ? (
                              <th className="text-gray-600 fw-medium mb-0 fs-16px">Lite Price</th>
                            ) : (
                              ""
                            )}
                            <th className="text-gray-600 fw-medium mb-0 fs-16px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {slabs &&
                            slabs.map((slab, index) => (
                              <tr key={index}>
                                <td className="fw-medium mb-0 fs-16px ps-0 whitespace-nowrap">
                                  Less than
                                </td>
                                <td>
                                  <input
                                    min={1}
                                    type="number"
                                    className="form-control fw-medium fs-16px"
                                    placeholder="0"
                                    value={slab.calls}
                                    onChange={e => handleSlabChange(index, "calls", e.target.value)}
                                  />
                                </td>
                                <td>
                                  <input
                                    min={1}
                                    type="number"
                                    className="form-control fw-medium fs-16px"
                                    placeholder="0"
                                    value={slab.live_price}
                                    onChange={e =>
                                      handleSlabChange(index, "live_price", e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    min={1}
                                    type="number"
                                    className="form-control fw-medium fs-16px"
                                    placeholder="0"
                                    value={slab.cached_price}
                                    onChange={e =>
                                      handleSlabChange(index, "cached_price", e.target.value)
                                    }
                                  />
                                </td>
                                {singleVendor?.user_name === "rto" ? (
                                  <td>
                                    <input
                                      min={1}
                                      type="number"
                                      className="form-control fw-medium fs-16px"
                                      placeholder="0"
                                      value={slab.lite_price}
                                      onChange={e =>
                                        handleSlabChange(index, "lite_price", e.target.value)
                                      }
                                    />
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td className="pe-0">
                                  {index !== 0 && (
                                    <button
                                      className="bg-transparent"
                                      onClick={() => deleteSlab(index)}
                                    >
                                      <img src={Trash} alt="trash" />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      {/* Validation errors */}
                      {validationErrors.map((error, index) => (
                        <div key={index} className="text-danger">
                          {error}
                        </div>
                      ))}
                    </div>
                    {/* Add Slab button */}
                    <button className="btn btn-primary-light py-1 px-3 mt-2" onClick={addSlab}>
                      + Add Slab
                    </button>
                  </div>
                  {!isEdit ? (
                    <div className="col-md-6 mb-4">
                      <label className="form-label fw-medium mb-1 fs-16px">API Identity:</label>
                      <span className="text-danger fs-16px">*</span>{" "}
                      {renderToolTipTag(
                        "api-identity",
                        "This api identity will be used to identify the vendor.",
                      )}
                      <input
                        type="text"
                        onChange={e => onChangeInput(e)}
                        name="api_identity"
                        className="form-control fw-medium fs-16px"
                        placeholder="API Identity"
                        value={vendorValue.api_identity}
                      />
                      <span className="text-danger d-block fw-400 fs-small mt-2">
                        {errors["api_identity"] ? errors["api_identity"] : null}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <Tooltip anchorId="api-identity" place="top" effect="solid" />
                  <div className="col-12">
                    <div className="d-flex gap-2 pt-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={e => handleSubmit(e)}
                      >
                        {loading ? "Saving..." : "Save"}
                      </button>
                      <button
                        onClick={e => navigate("/admin/vendors")}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

export default VendorForm;
