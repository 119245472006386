import { adminActionType } from "../../../constants";

const initVal = {
  vendors: [],
  partners: [],
};

const listingReducer = (state = initVal, action) => {
  switch (action.type) {
    case adminActionType.FETCH_VENDORS_LISTING:
      return { ...state, vendors: action.payload };
    case adminActionType.FETCH_PARTNERS_LISTING:
      return { ...state, partners: action.payload };
    default:
      return state;
  }
};

export default listingReducer;
