import React from "react";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ExportFieldModal from "../modals/ExportModal";

const ListTable = ({
  data,
  columns,
  isPagination = false,
  isSearch = false,
  onSearch,
  currentPage,
  totalRecord,
  onNextPage,
  onPrevPage,
  tableClass,
  // setPerPage,
  perPage,
  placeholder = "Search here",
  isExport = false,
  onClickExport,
  loading,
  isClickableRow = false,
  clickableRowPath,
  showExportFieldModal,
  handleClose,
  handleExport,
  isVendor = false,
  isLoginVendor = false,
}) => {
  const totalPages = Math.ceil(totalRecord / perPage);

  const totalDisplayedRecords = data?.length;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtonsToShow = 3;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtonsToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtonsToShow - 1);

    if (endPage - startPage < maxPageButtonsToShow - 1) {
      startPage = Math.max(1, endPage - maxPageButtonsToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <li key="first-page" className="page-item">
          <Link to="#" className="page-link" onClick={() => onNextPage(1)}>
            1
          </Link>
        </li>,
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li key="first-ellipsis" className="page-item disabled">
            <span className="page-link">...</span>
          </li>,
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={`page-${i}`} className="page-item">
          <Link
            to="#"
            className={`page-link ${currentPage === i ? "active" : ""}`}
            onClick={() => onNextPage(i)}
          >
            {i}
          </Link>
        </li>,
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="last-ellipsis" className="page-item disabled">
            <span className="page-link">...</span>
          </li>,
        );
      }
      pageNumbers.push(
        <li key="last-page" className="page-item">
          <Link to="#" className="page-link" onClick={() => onNextPage(totalPages)}>
            {totalPages}
          </Link>
        </li>,
      );
    }

    return pageNumbers;
  };

  return (
    <div className="wrapper">
      {isSearch && (
        <div className="table-heading d-flex justify-content-between">
          <div className="position-relative search-box">
            <input
              type="search"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder={placeholder}
              onChange={e => onSearch(e)}
            />
            <div className="search-icon">
              <BsSearch />
            </div>
          </div>

          {isExport && (
            <Button
              disabled={loading || !data?.length}
              onClick={onClickExport}
              className="d-flex align-items-center"
              variant="primary"
            >
              Export Logs <FontAwesomeIcon className="mx-2" icon={faFileExcel} />
            </Button>
          )}
        </div>
      )}
      {data?.length > 0 ? (
        <div className="overflow-auto">
          <table
            className={`table table-striped table-borderless whitespace-nowrap mb-0 ${tableClass}`}
          >
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index + 1} className={column.className}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((d, i) => (
                <tr
                  key={d.id}
                  onClick={() =>
                    isClickableRow ? window.open(`${clickableRowPath}/${d?.public_id}`) : ""
                  }
                  style={{ cursor: isClickableRow ? "pointer" : "default" }}
                >
                  {columns?.map((column, i) => (
                    <td key={column.id || i + 1} className={` ${column.containerClass || ""}`}>
                      {column?.customData ? column.customData(d) : d[column.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center my-4 fw-medium fs-18px">No record available</div>
      )}
      {isPagination && totalDisplayedRecords > 0 && (
        <div className="pagination-section d-flex flex-md-row flex-column gap-3 align-items-center justify-content-between">
          <div className="d-flex flex-wrap align-items-center justify-content-md-start justify-content-center gap-2">
            {/* <span className=" fw-medium text-gray-600">Show</span> */}
            {/* <select
              className="form-select"
              aria-label="Default select example"
              value={perPage}
              onChange={(e) => setPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select> */}
            <div className="fw-medium text-gray-600">
              Showing <b>{(currentPage - 1) * perPage + 1}</b> to{" "}
              <b>{Math.min(currentPage * perPage, totalRecord)}</b> of <b>{totalRecord}</b> results
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination align-items-center justify-content-end mb-0">
              <li className={`page-item`}>
                <Button
                  className={`bg-transparent p-0 fw-medium me-3 ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={() => onPrevPage(currentPage - 1)}
                >
                  Previous
                </Button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <Button
                  className={`bg-transparent p-0 fw-medium ms-3 ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                  onClick={() => onNextPage(currentPage + 1)}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <ExportFieldModal
        isVendor={isVendor}
        show={showExportFieldModal}
        handleClose={handleClose}
        handleExport={handleExport}
        isLoginVendor={isLoginVendor}
      />
    </div>
  );
};

export default ListTable;
