import React from "react";

import PartnerForm from "./PartnerForm";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import MasterLayout from "../../components/MasterLayout";
import PageHeader from "../../components/PageHeader";

const CreatePartner = () => {
  return (
    <>
      <TabTitle title={"New Partner"} />
      <MasterLayout>
        <div className="new-partners-page py-30px">
          <div className="container">
            <PageHeader title={"Create Partner"} isBackButton backLink={"/admin/partners"} />
            <div className="wrapper">
              <div className="p-4">
                <PartnerForm />
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    </>
  );
};
export default CreatePartner;
