import React from "react";
import SimpleModal from "../../components/modals/SimpleModal";
import alertIcon from "../../assets/images/alert-icon.png";

const ReGenerateAPIModal = ({ show, handleClose, handleSubmit }) => {
  return (
    <SimpleModal show={show} isSubmit={false} handleClose={handleClose} isShowContent={true}>
      <div className="text-center">
        <div className="alert-icon mb-4 mt-3">
          <img src={alertIcon} alt="alert" className="h-100" />
        </div>
        <h5 className="fs-22px fw-semibold mb-2">Regenerate API Key</h5>
        <p className="fs-14px font-medium text-gray-600 mb-2">
          Are you sure you want to generate new API key?
        </p>
        <div className="d-flex gap-2 justify-content-center pt-3">
          <button onClick={handleSubmit} className="btn btn-primary m-0">
            Yes
          </button>
          <button className="btn btn-secondary m-0" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </SimpleModal>
  );
};

export default ReGenerateAPIModal;
