import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SimpleModal from "../components/modals/SimpleModal";
import { getBillingDataAction } from "../store/action/partner/dashboardAction";

const BillingModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { billing } = useSelector(state => state.partnerDashboard);

  useEffect(() => {
    dispatch(getBillingDataAction());
  }, [dispatch]);

  return (
    <>
      <SimpleModal
        title={"Billings"}
        show={show}
        handleClose={handleClose}
        isCancel={false}
        isSubmit={false}
        isFooter={false}
      >
        <div className="billing-modal">
          <table className="table w-100 mb-0">
            <thead>
              <tr>
                <th className="ps-0 fs-14px text-gray-600 fw-medium">No. of Calls</th>
                <th className="text-end fs-14px text-gray-600 fw-medium">Live Price</th>
                <th className="text-end pe-0 fs-14px text-gray-600 fw-medium">Cached Price</th>
                <th className="text-end pe-0 fs-14px text-gray-600 fw-medium">Lite Price</th>
              </tr>
            </thead>
            <tbody className="text-dark fw-medium">
              {billing &&
                billing.partners_slabs &&
                billing.partners_slabs.map(slab => (
                  <tr key={slab.id}>
                    <td className="ps-0">{`${slab.start_value} - ${slab.end_value}`}</td>
                    <td className="text-end">₹{slab.live_price}</td>
                    <td className="text-end pe-0">₹{slab.cached_price}</td>
                    <td className="text-end pe-0">₹{slab.lite_price}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </SimpleModal>
    </>
  );
};

export default BillingModal;
