import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import MasterLayout from "../../components/MasterLayout";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import Tabs from "../../components/tab/Tab";
import { fetchVendorLogDetails } from "../../store/action/admin/vendorLogsAction";
import PageHeader from "../../components/PageHeader";
import moment from "moment";
import { getDataType, getStatusClass, getStatusText } from "../../components/shared/sharedMethod";

const VendorLogsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorLogs = useSelector(state => state.vendorLogs);

  useEffect(() => {
    dispatch(fetchVendorLogDetails(id, navigate));
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    window.close();
  };

  return (
    <>
      {/* <MasterLayout> */}
      {/* <TabTitle title={"Vendor Logs Details"} /> */}
      <TabTitle title={`${vendorLogs.reg_no} | ${vendorLogs?.full_name}`} />
      <div className="log-page log-details-page py-30px">
        <div className="container">
          <PageHeader
            title={"Vendor Log Details"}
            isBackButton
            backLink={"/admin/vendors-logs"}
            handleBack={handleBack}
          />
          <div className="mb-4 pb-1">
            <div className="bg-white wrapper p-4">
              <div className="row">
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Time:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {moment(vendorLogs.created_at).format("YYYY-MM-DD H:mm:ss")}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Duration:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{vendorLogs.duration} ms</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Vendor Name:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{vendorLogs.full_name}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status Code:</p>
                  <span
                    className={`badge ${
                      vendorLogs.status_code === 200 ? "successful-badge" : "failed-badge"
                    }`}
                  >
                    {vendorLogs.status_code}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status:</p>
                  <span className={`badge ${getStatusClass(vendorLogs.status)}`}>
                    {getStatusText(vendorLogs.status, true)}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">RC No.:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{vendorLogs.reg_no}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Request Type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{getDataType(vendorLogs.data_type)}</h4>
                </div>
                <div className="col-lg-4 col-6">
                  <p className="mb-1 text-gray-600 fs-16px">Cost:</p>
                  <h4 className="mb-0 fw-medium fs-16px">₹{vendorLogs.cost}</h4>
                </div>
              </div>
            </div>
          </div>
          {vendorLogs.partial_flag_reason && (
            <div className="mb-4 pb-1">
              <div className="bg-white wrapper p-4">
                <h5 className="fw-semibold fs-18px mb-2">Partial Flag Reason:</h5>
                <p className="text-gray-600 mb-0 fw-medium">{vendorLogs.partial_flag_reason}</p>
              </div>
            </div>
          )}
          {vendorLogs.partner_name && (
            <div className="mb-4 pb-1">
              <div className="bg-white wrapper p-4">
                <h5 className="fw-semibold fs-18px mb-3">Partner Details</h5>
                <div className="row">
                  <div className="col-lg-4 col-6 mb-lg-0 mb-4">
                    <p className="mb-1 text-gray-600 fs-16px">Name:</p>
                    <h4 className="mb-0 fw-medium fs-16px">{vendorLogs.partner_name}</h4>
                  </div>
                  <div className="col-lg-4 col-6 mb-lg-0 mb-4">
                    <p className="mb-1 text-gray-600 fs-16px">Partner Log:</p>
                    <Link
                      to={`/admin/partners-logs/${vendorLogs.partner_log_id}`}
                      className="mb-0 fw-medium fs-16px text-primary"
                      target="_blank"
                    >
                      Go To Partner Log
                    </Link>
                  </div>
                  <div className="col-lg-4 col-6">
                    <p className="mb-1 text-gray-600 fs-16px">Revenue:</p>
                    <h4 className="mb-0 fw-medium fs-16px">₹{vendorLogs.revenue}</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Tabs
            notIsHeader={true}
            requestDetails={vendorLogs.request ? JSON.stringify(vendorLogs.request) : null}
            responseDetails={vendorLogs.response ? JSON.stringify(vendorLogs.response) : null}
          />
        </div>
      </div>
      {/* </MasterLayout> */}
    </>
  );
};
export default VendorLogsDetails;
