import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../components/tab/Tab";
import { fetchLogDetails } from "../../store/action/partner/logsAction";
import TabTitle from "../../components/shared/tabTitle/TabTitle";
import MasterLayout from "../../components/MasterLayout";
import PageHeader from "../../components/PageHeader";
import { getDataType, getStatusClass, getStatusText } from "../../components/shared/sharedMethod";
import moment from "moment";

const PartnerLogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const partnerLog = useSelector(state => state.partnerLog);
  const { userProfile } = useSelector(state => state);
  useEffect(() => {
    dispatch(fetchLogDetails(id, navigate));
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    window.close();
  };

  return (
    <>
      {/* <MasterLayout> */}
      <TabTitle title={`${partnerLog?.reg_no} | ${userProfile?.full_name}`} />
      <div className="log-page log-details-page py-30px">
        <div className="container">
          <PageHeader
            isBackButton
            backLink={"/partner/logs"}
            title={"Log Details"}
            handleBack={handleBack}
          />
          <div className="mb-4 pb-1">
            <div className="bg-white wrapper p-4">
              <div className="row">
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Time:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {moment(partnerLog.created_at).format("YYYY-MM-DD H:mm:ss")}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Duration:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLog.duration}ms</h4>
                </div>
                {/* <div className="col-lg-4 col-6 mb-4">
                    <p className="mb-1 text-gray-600 fs-16px">Partner Name:</p>
                    <h4 className="mb-0 fw-medium fs-16px">
                      {partnerLog.full_name}
                    </h4>
                  </div> */}
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status Code:</p>
                  <span
                    className={`badge ${
                      partnerLog.status_code === 200 ? "successful-badge" : "failed-badge"
                    }`}
                  >
                    {partnerLog.status_code}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Status:</p>
                  <span className={`badge ${getStatusClass(partnerLog.status)}`}>
                    {getStatusText(partnerLog.status)}
                  </span>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Request Type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{getDataType(partnerLog?.data_type)}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Response Type:</p>
                  <h4 className="mb-0 fw-medium fs-16px">
                    {getDataType(partnerLog?.response_type)}
                  </h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">Source IP Address:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLog.source_ip}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-4">
                  <p className="mb-1 text-gray-600 fs-16px">RC No.:</p>
                  <h4 className="mb-0 fw-medium fs-16px">{partnerLog.reg_no}</h4>
                </div>
                <div className="col-lg-4 col-6 mb-lg-4 ">
                  <p className="mb-1 text-gray-600 fs-16px">Cost:</p>
                  <h4 className="mb-0 fw-medium fs-16px">₹{partnerLog.cost}</h4>
                </div>
              </div>
            </div>
          </div>

          <Tabs
            className="partner-tabs"
            headerDetails={partnerLog.headers ? JSON.stringify(partnerLog.headers) : null}
            requestDetails={partnerLog.request ? JSON.stringify(partnerLog.request) : null}
            responseDetails={partnerLog.response ? JSON.stringify(partnerLog.response) : null}
          />
        </div>
      </div>
      {/* </MasterLayout> */}
    </>
  );
};
export default PartnerLogDetails;
