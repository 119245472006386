import moment from "moment";
import apiConfig from "../../config/apiConfig";
import toast from "react-hot-toast";
import { partnerAPIURL, vendorAPIURL } from "../../constants";

export const exportLogs =
  (id, userType, filter, setLoading, isVendor = false, payload) =>
  async dispatch => {
    try {
      setLoading(true);
      let url;
      if (!id && !isVendor) {
        url = `${partnerAPIURL.PARTNER}/export`;
      } else if (!id && isVendor) {
        url = `${vendorAPIURL.VENDOR}/export`;
      } else {
        url = `/export/${userType}/${id}`;
      }

      const params = new URLSearchParams();

      if (filter.start_date && filter.end_date) {
        const fromDate = moment(filter.start_date).format("YYYY-MM-DD");
        const toDate = moment(filter.end_date).format("YYYY-MM-DD");
        params.append("from_date", fromDate);
        params.append("to_date", toDate);
      }

      if (filter.data_type && filter.data_type !== "all") {
        params.append("data_type", filter.data_type);
      }

      if (filter.status && filter.status !== "all") {
        params.append("status", filter.status);
      }

      const queryString = params.toString();
      if (queryString) {
        url = `${url}?${queryString}`;
      }

      const response = await apiConfig.post(url, payload);

      // // Extracting filename from Content-Disposition header
      // const contentDisposition = response.headers["content-disposition"];
      // let fileName = `export_${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
      // if (contentDisposition) {
      //   const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      //   if (fileNameMatch.length > 1) {
      //     fileName = fileNameMatch[1];
      //   }
      // }

      // const blob = new Blob([response.data], {
      //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // });

      // const downloadLink = document.createElement("a");
      // const urlObject = window.URL.createObjectURL(blob);
      // downloadLink.href = urlObject;
      // downloadLink.setAttribute("download", fileName);
      // downloadLink.setAttribute("target", "_blank");
      // downloadLink.setAttribute("rel", "noopener noreferrer");

      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);

      // window.URL.revokeObjectURL(urlObject);
      setLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "An error occurred while exporting logs");
    }
  };
