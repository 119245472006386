import React from "react";
import { Helmet } from "react-helmet";

const TabTitle = props => {
  const { title } = props;

  return (
    <Helmet>
      <title>{`${title} | Vehicle Info`}</title>
    </Helmet>
  );
};

export default TabTitle;
