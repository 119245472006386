import React from "react";
import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <Toaster position="top-right">
      {/* {(t) => (
        <>
          <ToastIcon toast={t} />
          <div key={t.id} {...toast.ariaProps}>
            {t.message}
          </div>
        </>
      )} */}
    </Toaster>
  );
};

export default Toast;
